import { ScrollArea } from "@/components/ui/scroll-area";
import { copyToClipboard } from "@/components/copyToClipboard.tsx";
import { useEffect, useState } from "react";
import { FigmaLogo } from "@/components/AdsView.tsx";
import { trpc } from "@/utils/trpc.ts";
import { DotsLoader } from "@/components/custom-components/Loader";

type props = {
  Cards: GenericCardProps[];
  HasAccess: boolean;
  type: "email" | "landing-page";
};

export type GenericCardProps = {
  Screenshot: string;
  ID: string;
  type: "email" | "landing-page";
};

export const GenericGridView = (props: props) => {
  return (
    <div className={"grid grid-cols-1 lg:grid-cols-3 ml-10"}>
      {props?.Cards?.map((card) => <CardView {...card} />)}
    </div>
  );
};

const CardView = (card: GenericCardProps) => {
  const [copied, setCopied] = useState(false);

  const { isLoading, refetch } = trpc.getFigmaCopyPasteCode.useQuery(
    {
      id: card.ID.toString(),
      type: card.type,
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleCopyClick = async () => {
    // Attempt to copy immediately after fetching
    const success = await copyToClipboard(refetch);
    if (success) {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <div
      className={
        "bg-cardgray shadow-lg h-fit w-fit shadow-neutral-600/10 rounded-lg p-4 mb-5 mr-6"
      }
      key={card.ID}
    >
      <ScrollArea className={"md:w-[500px] md:h-[700px]"}>
        <img
          alt={"screenshot of a landing page"}
          loading={"lazy"}
          className={"w-full rounded-md"}
          src={card.Screenshot}
        />
      </ScrollArea>
      <div className={"flex items-center"}>
        <div onClick={handleCopyClick} className={"mx-auto mt-6"}>
          {isLoading ? (
            <div>
              <DotsLoader />
            </div>
          ) : copied ? (
            <div className="flex items-center bg-white rounded-full px-3 py-3">
              <p className="text-black text-sm leading-none font-semibold">
                CMD+V in Figma
              </p>
            </div>
          ) : (
            <button
              className={
                "flex items-center bg-black rounded-full space-x-4 px-5 py-2"
              }
            >
              <p
                className={
                  "text-white text-sm leading-none font-semibold -mt-1"
                }
              >
                Copy
              </p>
              <FigmaLogo />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
