import { Button, Input, Text } from "@/components/custom-components";
import { Box, Stack } from "@/components/custom-components/Layout";
import { LockClosedIcon } from "@radix-ui/react-icons";
import { Link, useNavigate } from "@tanstack/react-router";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { useState } from "react";
import { trpc } from "@/utils/trpc.ts";
import { PlanTag } from "@/components/custom-components/PlanTag";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loading } from "@/components/loading.tsx";
import { CheckCircle2, ChevronsLeftIcon } from "lucide-react";
import { ManageAccount } from "@/components/templates/AppHeader";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import FullAccessPromptDialog from "@/components/FullAccessPromptDialog.tsx";
import { toast } from "sonner";
import SidebarBoardList from "@/components/sidebar/SidebarBoardList.tsx";

type SidebarProps = {
  isPublicView: boolean;
  sidebarCollapsed: boolean | undefined;
};

export const Sidebar = (props: SidebarProps) => {
  const [publicDialogOpen, setPublicDialogOpen] = useState<boolean>(false);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data, isLoading } = trpc.permissions.useQuery(null, {});

  return (
    <>
      <Dialog open={publicDialogOpen} onOpenChange={setPublicDialogOpen}>
        <DialogContent className="sm:max-w-[500px] py-6">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Want the copy-and-paste templates for high-performing ads?
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Sign up for a free trial and get access
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Get Free Access</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {upgradeDialogOpen && (
        <FullAccessPromptDialog
          upgradeDialogOpen={upgradeDialogOpen}
          setUpgradeDialogOpen={() => setUpgradeDialogOpen(false)}
        />
      )}

      <Box
        className={`w-full ${props.sidebarCollapsed ? "lg:w-[5rem]" : "lg:w-[15.563rem]"} flex-1 h-[94svh] lg:h-screen flex flex-col bg-white mt-18 lg:mt-0`}
      >
        <div
          id={"new-sidebar"}
          className={
            "overflow-y-auto px-[0.625rem] pt-5 lg:pt-5 pb-[1.25rem] bg-[#F5F5F580] border-r border-t border-[#B4B4B4] flex flex-col justify-between flex-1"
          }
        >
          {!props.sidebarCollapsed ? (
            <div className={`h-full flex flex-col justify-between gap-5`}>
              <div className={"px-[0.625rem]"}>
                <Stack>
                  <div className={"flex justify-between items-center gap-2"}>
                    <div className={""}>
                      <Link
                        search={(old) => ({
                          ...old,
                          sideBarOpen: false,
                        })}
                        params={(old) => ({
                          ...old,
                        })}
                      >
                        <img
                          alt={"creativeos.io"}
                          className={"w-14 h-14 hidden lg:flex"}
                          src={"/logo.svg"}
                        />
                      </Link>
                    </div>
                    <Link
                      search={(old) => ({
                        ...old,
                        sideBarOpen: false,
                        sidebarCollapsed: true,
                      })}
                      replace={true}
                      params={(old) => ({
                        ...old,
                      })}
                      className={"hidden lg:flex"}
                    >
                      <ChevronsLeftIcon />
                    </Link>
                  </div>
                  <div className={"flex flex-col"}>
                    {[
                      {
                        name: "Templates",
                        link: "/feeds/templates",
                        icon: "/dashboard/templates.svg",
                      },
                      {
                        name: "Inspiration",
                        link: "/feeds/inspiration",
                        icon: "/dashboard/inspiration.svg",
                      },
                      {
                        name: "Collections",
                        link: "/feeds/collections",
                        icon: "/dashboard/collections.svg",
                      },
                      {
                        name: "Brands",
                        link: "/feeds/brands",
                        icon: "/dashboard/brands.svg",
                      },
                    ].map((item) => (
                      <Link
                        to={item.link}
                        search={(d) => ({
                          ...d,
                          cacheBuster: Math.random(),
                          orderFilter:
                            item.name === "Inspiration" ? "Random" : "Recent",
                          sideBarOpen: false,
                        })}
                        className={`${location.pathname.startsWith(item.link) ? "bg-gradient-to-b from-[#A259FF] to-[#613599]" : "bg-transparent"} p-[2px] rounded-lg`}
                        activeProps={{
                          className:
                            "bg-gradient-to-b from-[#A259FF] to-[#613599] rounded-lg p-[2px]",
                        }}
                        activeOptions={{
                          includeSearch: false,
                        }}
                        key={item.name}
                      >
                        <span
                          className={
                            "flex gap-2.5 justify-start items-center px-5 py-4 rounded-md bg-[#FAFAFA]"
                          }
                        >
                          <span>
                            <img alt={item.name} src={item.icon} />
                          </span>
                          <Text
                            className={"w-auto"}
                            weight={"medium"}
                            data-testid={item.name}
                          >
                            {item.name}
                          </Text>
                        </span>
                      </Link>
                    ))}
                  </div>

                  <div className={"pl-2.5"}>
                    <hr className={"border-[#B4B4B4] my-2.5"} />
                    <div className={"my-5"}>
                      <p
                        className={"text-[#7F7F7F] font-bold text-[0.6875rem]"}
                      >
                        FEATURED
                      </p>
                      <Stack className={"gap-0 mt-[0.875rem]"}>
                        {[
                          { name: "Hidden Gems", link: "/feeds/hidden-gems" },
                          { name: "Evergreen Ads", link: "/feeds/evergreen" },
                          { name: "Testing Labs", link: "/feeds/testing-labs" },
                        ].map((item) => (
                          <Link
                            onClick={(e) => {
                              if (props.isPublicView) {
                                setPublicDialogOpen(true);
                                e.preventDefault();
                              }
                              // If a user navigates before loading is complete, choose to just navigate
                              // This optimizes for users who ARE allowed access and doesn't force them to
                              // wait until we have the data back from the server if they have access

                              if (
                                // item.name === "Evergreen ads" &&
                                !isLoading &&
                                !data?.userCanAccessEverything
                              ) {
                                setUpgradeDialogOpen(true);
                                e.preventDefault();
                              }
                            }}
                            to={item.link}
                            search={() => ({
                              sideBarOpen: false,
                            })}
                            className="bg-[#FAFAFA] rounded-lg p-[2px]"
                            activeProps={{
                              className:
                                "bg-gradient-to-b from-[#A259FF] to-[#613599] font-bold",
                            }}
                            activeOptions={{
                              includeSearch: false,
                            }}
                            key={item.name}
                          >
                            <span
                              className={
                                "px-5 py-2 rounded-md flex gap-[0.4375rem] justify-between items-center bg-[#FAFAFA]"
                              }
                            >
                              <Text
                                className={"w-auto text-nowrap"}
                                weight={"medium"}
                                data-testid={item.name}
                              >
                                {item.name}
                              </Text>
                              <>
                                {!isLoading &&
                                  !data?.userCanAccessEverything && (
                                    <PlanTag label={<LockClosedIcon />} />
                                  )}
                              </>
                            </span>
                          </Link>
                        ))}
                      </Stack>
                    </div>
                    <hr className={"border-[#B4B4B4] my-2.5"} />

                    <Stack className={"pl-4 mt-5"}>
                      <p
                        className={"text-[#7F7F7F] font-bold text-[0.6875rem]"}
                      >
                        SAVED
                      </p>
                      <SidebarBoardList />
                    </Stack>
                  </div>
                </Stack>
              </div>
              <div className={"px-[0.625rem]"}>
                <hr className={"border-[#B4B4B4] my-5"} />
                <Stack className={"gap-2.5 mt-[0.875rem]"}>
                  <a
                    href="https://join.slack.com/t/creativeos/shared_invite/zt-24n0h6fh0-JslQdJaA6HNVdeyG~0lJHw"
                    target="_blank"
                  >
                    <Text weight={"medium"}>Join the Creative OS Slack</Text>
                  </a>
                  <a href="https://www.creativeos.io/#faq">
                    <Text weight={"medium"}>Help & Feedback</Text>
                  </a>
                </Stack>
                <hr className={"border-[#B4B4B4] my-5"} />
                <ManageAccount />
              </div>
            </div>
          ) : (
            <div className={"h-full flex flex-col justify-between gap-5"}>
              <div>
                <div className={"flex justify-center"}>
                  <Link
                    search={(old) => ({
                      ...old,
                      sideBarOpen: false,
                      sidebarCollapsed: false,
                    })}
                    replace={true}
                    params={(old) => ({
                      ...old,
                    })}
                    className={"hidden lg:flex"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-menu"
                    >
                      <line x1="4" x2="20" y1="12" y2="12" />
                      <line x1="4" x2="20" y1="6" y2="6" />
                      <line x1="4" x2="20" y1="18" y2="18" />
                    </svg>
                  </Link>
                </div>
                <div className={"flex flex-col mt-10"}>
                  {[
                    {
                      name: "Templates",
                      link: "/feeds/templates",
                      icon: "/dashboard/templates.svg",
                    },
                    {
                      name: "Inspiration",
                      link: "/feeds/inspiration",
                      icon: "/dashboard/inspiration.svg",
                    },
                    {
                      name: "Collections",
                      link: "/feeds/collections",
                      icon: "/dashboard/collections.svg",
                    },
                    {
                      name: "Brands",
                      link: "/feeds/brands",
                      icon: "/dashboard/brands.svg",
                    },
                  ].map((item) => (
                    <Link
                      to={item.link}
                      search={(d) => ({
                        ...d,
                        cacheBuster: Math.random(),
                        orderFilter:
                          item.name === "Inspiration" ? "Random" : "Recent",
                        sideBarOpen: false,
                      })}
                      className={`${location.pathname.startsWith(item.link) ? "border-[2px] border-[#A259FF] rounded-lg" : ""} flex gap-2.5 justify-center items-center h-[3.125rem]`}
                      activeProps={{
                        className: "border-[1px] border-[#A259FF] rounded-lg",
                      }}
                      activeOptions={{
                        includeSearch: false,
                      }}
                      key={item.name}
                    >
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <span>
                              <img alt={item.name} src={item.icon} />
                            </span>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{item.name}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      {/*<Text*/}
                      {/*  className={"w-auto"}*/}
                      {/*  weight={"medium"}*/}
                      {/*  data-testid={item.name}*/}
                      {/*>*/}
                      {/*  {item.name}*/}
                      {/*</Text>*/}
                    </Link>
                  ))}
                </div>
              </div>
              <div className={"flex justify-center"}>
                <ManageAccount sidebarCollapsed={true} />
              </div>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export type BoardProp = {
  description?: string | null;
  name: string;
  id: string;
  isInBoard?: boolean;
};

export const BoardDialog = ({
  data,
  open,
  onOpenChange,
  adId,
  templateId,
}: {
  data?: BoardProp;
  open: boolean;
  onOpenChange: () => void;
  adId?: number;
  templateId?: string;
}) => {
  const utils = trpc.useUtils();
  const navigate = useNavigate();

  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { mutateAsync: addAdToBoard } = trpc.addAdToBoard.useMutation();
  const { mutateAsync: addTemplateToBoard } =
    trpc.addTemplateToBoard.useMutation();

  const { mutate: createBoard, isPending: createIsPending } =
    trpc.createBoard.useMutation({
      onSuccess: async (data) => {
        setMessage("Board created successfully");
        await utils.getBoards
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            if (adId) {
              // we directly add the ad to the board if created from the inspiration card
              setMessage(null);
              setLoading(true);
              addAdToBoard({
                boardId: data.id,
                adId,
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} board!`);
                utils.getBoards.invalidate();
                toast(`Added to ${data.name} board!`);
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else if (templateId) {
              // we directly add the template to the board if created from the ad template card
              setMessage(null);
              setLoading(true);
              addTemplateToBoard({
                boardId: data.id,
                templateId,
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} board!`);
                utils.getBoards.invalidate();
                toast(`Added to ${data.name} board!`);
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else {
              // we navigate to the board's page
              setTimeout(() => {
                onOpenChange();
                navigate({
                  to: `/feeds/boards/$boardID/ads`,
                  params: {
                    boardID: data.id,
                  },
                });
              }, 1000);
            }
          });
      },
    });

  const { mutate: editBoard, isPending: editIsPending } =
    trpc.editBoard.useMutation({
      onSuccess: async () => {
        setMessage("Board updated successfully");
        await utils.getBoards
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            setTimeout(() => {
              onOpenChange();
            }, 2000);
          });
      },
    });

  const formSchema = z.object({
    name: z.string().min(1, "Board name is required"),
    description: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: data?.name || "",
      description: data?.description || "",
    },
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (data) {
        editBoard({ ...values, boardId: data.id });
      } else createBoard(values);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      open={open || editIsPending || createIsPending || loading}
      onOpenChange={onOpenChange}
    >
      <DialogContent
        className={""}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle className={"text-center"}>
            <Text weight={"semibold"} size={"lg"}>
              {data
                ? "Edit Board"
                : loading
                  ? "Adding ad to board"
                  : "Create Board"}
            </Text>
          </DialogTitle>
        </DialogHeader>
        {message ? (
          <div
            className={
              "flex flex-col items-center text-center gap-2 w-full h-full m-auto"
            }
          >
            <CheckCircle2 size={"3rem"} strokeWidth={"1.2"} color={"green"} />
            <p>{message}</p>
          </div>
        ) : editIsPending || createIsPending || loading ? (
          <div className={"flex flex-col gap-3 w-full h-full m-auto"}>
            <Loading />
          </div>
        ) : (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className={""}>
              <div className={"flex flex-col gap-4 my-[1.25rem]"}>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Board name</FormLabel>
                      <FormControl>
                        <Input placeholder={"Board Name"} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Board description</FormLabel>
                      <FormControl>
                        <Input placeholder={"Board Description"} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {/*<Input placeholder={"Collection Name"} />*/}
              </div>
              <div className={"flex justify-center"}>
                <Button
                  type={"submit"}
                  className={"w-[70%] py-[0.625rem]"}
                  variant={"secondary"}
                >
                  {createIsPending || editIsPending ? "Submitting..." : "Save"}
                </Button>
              </div>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export const DeleteBoardDialog = ({
  data,
  open,
  onOpenChange,
}: {
  data?: BoardProp;
  open: boolean;
  onOpenChange: () => void;
}) => {
  const utils = trpc.useUtils();

  const [message, setMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const { mutateAsync: deleteBoard, isPending: deleteIsPending } =
    trpc.deleteBoard.useMutation({
      onSuccess: async () => {
        setMessage("Board deleted successfully");
        await utils.getBoards
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            setTimeout(() => {
              onOpenChange();
              navigate({
                to: `/feeds/boards`,
              });
            }, 2000);
          });
      },
    });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={""}>
        <DialogHeader>
          <DialogTitle className={"text-center"}>
            <Text weight={"semibold"} size={"lg"}>
              Delete Board
            </Text>
          </DialogTitle>
        </DialogHeader>
        {message ? (
          <div
            className={
              "flex flex-col items-center text-center gap-2 w-full h-full m-auto"
            }
          >
            <CheckCircle2 size={"3rem"} strokeWidth={"1.2"} color={"green"} />
            <p>{message}</p>
          </div>
        ) : deleteIsPending ? (
          <div className={"flex w-full h-full m-auto"}>
            <Loading />
          </div>
        ) : (
          data && (
            <div>
              <Stack className={"gap-2 my-[1.25rem]"}>
                <Text align={"center"}>
                  Are you sure you want to delete {data?.name}?
                </Text>
              </Stack>
              <div className={"flex justify-center gap-[0.625rem]"}>
                <Button
                  disabled={deleteIsPending}
                  className={"w-auto py-[0.625rem]"}
                  variant={"primary"}
                  onClick={onOpenChange}
                >
                  No
                </Button>
                <Button
                  disabled={deleteIsPending}
                  onClick={() => deleteBoard({ boardId: data.id })}
                  className={"w-auto py-[0.625rem]"}
                  variant={"secondary"}
                >
                  Yes
                </Button>
              </div>
            </div>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

export const SidebarButton = (props: { sideBarOpen?: boolean }) => {
  return (
    <Link
      search={(old) => ({
        ...old,
        sideBarOpen: !props.sideBarOpen,
      })}
      params={(old) => ({
        ...old,
      })}
      className={
        "group flex lg:hidden items-center justify-center w-12 h-12 bg-white active:bg-slate-100 rounded-full"
      }
    >
      {props.sideBarOpen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-x"
        >
          <path d="M18 6 6 18" />
          <path d="m6 6 12 12" />
        </svg>
      ) : (
        <svg
          className={"fill-slate-700"}
          xmlns="http://www.w3.org/2000/svg"
          height="36"
          viewBox="0 -960 960 960"
          width="36"
        >
          <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
        </svg>
      )}
    </Link>
  );
};
