import { createFileRoute } from "@tanstack/react-router";

import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { BoardCollection } from "@/components/templates/BoardCollection";
import { Loader } from "@/components/custom-components/Loader";
import { Text } from "@/components/custom-components";
import { Divider } from "@/components/custom-components/Divider";
import { SidebarButton } from "@/components/templates/Sidebar";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/boards/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const { sideBarOpen } = Route.useSearch();

  const { data, isLoading, error } = trpc.getBoards.useQuery(
    {},
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={"px-5 md:px-5"}>
        <div className={"sticky top-0 z-10"}>
          <div className={"bg-white pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Boards
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
          </div>
        </div>
        {data && <BoardCollectionGridView boards={data} />}
      </div>
    </>
  );
}

type BoardCollectionDataProps = {
  boards: BoardDataProps[];
};

type BoardDataProps = {
  name: string;
  id: string;
  description: string | null;
  isAdInBoard?: boolean;
};

const BoardCollectionGridView = (props: BoardCollectionDataProps) => {
  return (
    <div className={"grid lg:grid-cols-2 gap-5 px-0"}>
      {props.boards && props.boards.length > 0
        ? props.boards.map((item) => {
            return (
              <div
                key={item.id}
                className={
                  "h-56 lg:h-[46.25rem] overflow-y-hidden cursor-pointer"
                }
              >
                <BoardCollection board={item} />
              </div>
            );
          })
        : props.boards.length === 0 && (
            <div
              className={
                "h-full w-full lg:col-span-2 flex justify-center items-center"
              }
            >
              <p>You do not have any board</p>
            </div>
          )}
    </div>
  );
};
