import { ReactNode, FC, useEffect, useState } from "react";
import { MemberstackProtected } from "@memberstack/react";
import { getCookie } from "typescript-cookie";

export const ProtectedArea: FC<{ children: ReactNode }> = ({ children }) => {
  const [cookieSet, setCookieSet] = useState(false);
  // memberstack sets the cookie to app.creativeos.io, but we need it to be set to creativeos.io
  useEffect(() => {
    const authToken = getCookie("_ms-mid") ?? "";
    localStorage.setItem("authToken", authToken);
    setCookieSet(true);
  }, []);

  if (!cookieSet) return <div />;
  return (
    <MemberstackProtected
      onUnauthorized={() => {
        window.location.href = "/login";
        console.log("NO AUTH");
      }}
    >
      <>{children}</>
    </MemberstackProtected>
  );
};
