import { useState } from "react";
import { ProfileModal } from "@memberstack/react";
import { Button } from "@/components/ui/button.tsx";
import { Link } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";

type PageTitleProps = {
  Title: string;
};

export const TopBar = (props: PageTitleProps) => {
  return (
    <div
      className={
        "flex items-around justify-between border-b border-b-bordergray pb-4 mb-2"
      }
    >
      <div className={"flex items-center shrink-0"}>
        {/* Needs onClick to open sidebar */}
        <Link
          search={(old) => ({
            ...old,
            sideBarOpen: true,
          })}
          params={(old) => ({
            ...old,
          })}
          className={
            "group flex md:hidden items-center justify-center w-12 h-12 bg-white active:bg-slate-100 rounded-full -ml-3.5 mr-2"
          }
        >
          <svg
            className={"fill-slate-700"}
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
          </svg>
        </Link>

        <h1 className={"text-2xl my-auto truncate  md:w-full"}>
          {props.Title}
        </h1>
      </div>
      <ManageAccount />
    </div>
  );
};

const ManageAccount = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const { mutate: openPortalSessionUrl } = trpc.getPortalSessionUrl.useMutation(
    {
      onSuccess: async (data) => {
        if (data) {
          console.log(data);
          window.location.href = data;
        }
      },
    },
  );

  const { data: chargebeeSyncStatus } = trpc.getIsUserSynced.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  );

  // useEffect(() => {
  //   if (
  //     data &&
  //     chargebeeSyncStatus &&
  //     !isLoading &&
  //     !isLoadingChargebeeSynced
  //   ) {
  //     window.location.href = data;
  //   }
  // }, [data, chargebeeSyncStatus, isLoading, isLoadingChargebeeSynced]);

  return (
    <div className={"flex items-center pl-2 lg:pl-6"}>
      <img
        onClick={() => setModalOpen(true)}
        className={
          "hidden lg:flex cursor-pointer shrink-0 rounded-full w-9 h-9"
        }
        src={"/bg_gradient.jpg"}
        alt={""}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <img
            onClick={() => setModalOpen(true)}
            className={
              "flex lg:hidden cursor-pointer shrink-0 rounded-full w-9 h-9"
            }
            src={"/bg_gradient.jpg"}
            alt={""}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>My Account</DropdownMenuLabel>

          <DropdownMenuItem onClick={() => setModalOpen(true)}>
            Manage Account
          </DropdownMenuItem>
          {chargebeeSyncStatus && (
            <DropdownMenuItem onClick={() => openPortalSessionUrl()}>
              Manage Subscription
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      <div className={"hidden md:block md:ml-2.5"}>
        <p className={"text-zinc-700 text-rd font-semibold mb-1"}>Hey there!</p>
        {/*<Link to={"/library/manage-account/profile"}>Manage Account</Link>*/}
        <Button
          className={"p-0 h-auto text-zinc-400 dark:text-zinc-400 text-xs"}
          variant={"link"}
          onClick={() => setModalOpen(true)}
        >
          Manage Account
        </Button>
        {chargebeeSyncStatus && (
          <div>
            <span
              className={
                "text-nowrap text-xs cursor-pointer bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
              }
              onClick={() => openPortalSessionUrl()}
            >
              Manage Subscription
            </span>
          </div>
        )}
        {modalOpen && <ProfileModal onClose={() => setModalOpen(false)} />}
      </div>
    </div>
  );
};
