export const Loader = () => {
  return (
    <>
      <img
        src={"/cos-loading.gif"}
        alt={"loading..."}
        className={"w-10 h-10"}
      />
    </>
  );
};

export const DotsLoader = () => {
  return (
    <>
      <img src={"/dots-loader.gif"} alt={"loading..."} className={"h-10"} />
    </>
  );
};
