import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { AdsView } from "../components/AdsView.tsx";
import { z } from "zod";
import { TopBar } from "@/components/topBar.tsx";
import { trpc } from "../utils/trpc.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { TagFilters } from "@/components/tagFilters.tsx";
import { CheckNewPostsResponse } from "../../../server/posts.ts";
import {
  Tags as TagTypes,
  orderFilter as OrderFilterTypes,
} from "../../../shared/airtableGet.ts";

type SearchParams = {
  Tags?: z.infer<typeof TagTypes>;
  loadAdsCreatedAfter?: number;
};

export const Route = createFileRoute("/library/static/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as z.infer<typeof TagTypes> | undefined;
    const loadAdsCreatedAfter = search?.loadAdsCreatedAfter as number;
    return {
      Tags: tag,
      loadAdsCreatedAfter,
    };
  },
});

function All() {
  const { Tags, orderFilter, loadAdsCreatedAfter } = Route.useSearch();
  const navigate = useNavigate();

  // Here, we are making a request for new the ad posts since users last request
  const { data: lastActive, isLoading } =
    trpc.shouldShowNewPostsSinceActive.useQuery({ loadAdsCreatedAfter });

  return (
    <>
      <div className={"sticky top-0 z-10 px-2"}>
        <div className={"bg-white px-8 pt-6 pb-2"}>
          <TopBar
            Title={
              loadAdsCreatedAfter ? "Recent Added Posts" : "Static Ad Library"
            }
          />
          <div
            className={
              "flex flex-col md:flex-row justify-between items-end md:items-start -ml-4 py-2"
            }
          >
            <div className={"pt-1"}>
              <TagFilters DestinationURL={"/library/static"} Tags={Tags} />
            </div>
            <div className={"md:pl-8"}>
              {!loadAdsCreatedAfter && (
                <Select
                  onValueChange={(value) => {
                    navigate({
                      search: (old) => {
                        return {
                          ...old,
                          cacheBuster:
                            value === "Random" ? Math.random() : undefined,
                          orderFilter: value as z.infer<
                            typeof OrderFilterTypes
                          >,
                        };
                      },
                      params: (old) => {
                        return {
                          ...old,
                        };
                      },
                    });
                  }}
                >
                  <SelectTrigger className="w-32">
                    <SelectValue placeholder="Random" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Random">Random</SelectItem>
                    <SelectItem value="Popular">Popular</SelectItem>
                    <SelectItem value="Recent"> Recent</SelectItem>
                    <SelectItem value="Oldest">Oldest</SelectItem>
                  </SelectContent>
                </Select>
              )}
            </div>
          </div>
        </div>
        <NewAdPostsCheck
          isLoading={isLoading}
          lastActive={lastActive as CheckNewPostsResponse}
          loadAdsCreatedAfter={loadAdsCreatedAfter}
        />
      </div>

      {/*control loadAdsCreatedAfter by a url query param or undefined*/}
      <AdsView
        Filter={{
          Expert: undefined,
          Tags: Tags ? [Tags] : undefined,
          loadAdsCreatedAfter: loadAdsCreatedAfter
            ? lastActive?.lastTime
            : undefined,
          Ready: true,
          cursor: undefined,
          limit: 20,
          sortingOptions: orderFilter as z.infer<typeof OrderFilterTypes>,
        }}
      />
    </>
  );
}

function RecentAdPostsAlert({ lastActiveTime }: { lastActiveTime: number }) {
  return (
    <div className={"rounded border-gray-300 bg-gray-100 p-5"}>
      <p>
        There are new posts since your last login.{" "}
        <Link
          className={"underline text-[#A259FF] cursor-pointer"}
          to={`/library/static`}
          search={{
            Tags: undefined,
            loadAdsCreatedAfter: lastActiveTime,
          }}
        >
          Click here
        </Link>{" "}
        to see them
      </p>
    </div>
  );
}

export function NewAdPostsCheck({
  isLoading,
  lastActive,
  loadAdsCreatedAfter,
}: {
  isLoading: boolean;
  lastActive: CheckNewPostsResponse;
  loadAdsCreatedAfter: number | undefined;
}) {
  return (
    <div className={"px-5 mt-2 mb-5"}>
      {isLoading ? (
        <div></div>
      ) : lastActive?.newerPost && !loadAdsCreatedAfter ? (
        <RecentAdPostsAlert lastActiveTime={lastActive.lastTime as number} />
      ) : (
        loadAdsCreatedAfter &&
        lastActive !== undefined &&
        !lastActive.newerPost && (
          <div className={"flex justify-center"}>
            <div
              className={
                "px-8 py-5 bg-red-100 w-1/2 rounded-md border border-red-300"
              }
            >
              <p className={"text-center"}>
                {`There have been no new ad posts since ${new Date(loadAdsCreatedAfter).toString().split(" ").slice(0, 5).join(" ")}`}
              </p>
            </div>
          </div>
        )
      )}
    </div>
  );
}
