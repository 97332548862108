import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "@/components/ui/form.tsx";
import { Button } from "@/components/custom-components";
import { z } from "zod";
import { useNavigate } from "@tanstack/react-router";
import {
  leads,
  LeadType,
  OnboardingFormSchema,
  SectionProps,
} from "@/routes/get-started.tsx";
import { trpc } from "@/utils/trpc.ts";
import {
  RadioGroup,
  RadioGroupItem,
} from "@/components/custom-components/Radio";

export default function ClientLeadSource(props: SectionProps) {
  const navigate = useNavigate();

  const { mutateAsync, isPending } = trpc.completeOnboardingStep.useMutation();

  async function onSubmit(data: z.infer<typeof OnboardingFormSchema>) {
    const selected = data.leads as LeadType;

    try {
      await mutateAsync({
        lead: selected ? selected : undefined,
      });
      await navigate({
        to: "/get-started",
        search: { step: "ready" },
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={""}>
      <div
        className={"mb-10 text-center lg:text-left text-white lg:text-black"}
      >
        <h2 className={"text-4xl font-semibold"}>How did we meet?</h2>
        <p className={"text-xl"}>Where did you first discover Creative OS?</p>
      </div>
      <hr className={"flex lg:hidden mb-10 border-[#FFFFFF40] mx-2"} />
      <div>
        <Form {...props.form}>
          <form
            onSubmit={props.form.handleSubmit(onSubmit)}
            className="space-y-6"
          >
            <div className={"flex flex-col gap-5 lg:w-[22.938rem]"}>
              <FormField
                control={props.form.control}
                name="leads"
                render={({ field }) => (
                  <RadioGroup
                    onValueChange={field.onChange}
                    value={field.value}
                    className="flex flex-col space-y-1"
                  >
                    {leads &&
                      leads.map((item) => (
                        <FormControl key={item.id}>
                          <FormItem>
                            <RadioGroupItem
                              checked={field.value === item.value}
                              value={item.value}
                              onChange={() => field.onChange(item.value)}
                              id={item.label}
                            >
                              {item.label}
                            </RadioGroupItem>
                          </FormItem>
                        </FormControl>
                      ))}
                  </RadioGroup>
                )}
              />
            </div>
            <div className={"mt-10 flex items-center gap-5"}>
              <Button
                type={"submit"}
                className={"min-w-[13.125rem] text-xl"}
                variant={"secondary"}
              >
                {isPending ? "Submitting..." : "Next"}
              </Button>
              <button
                onClick={() =>
                  navigate({
                    to: "/get-started",
                    search: { step: "ready" },
                  })
                }
                className={"text-white lg:text-[#AEAEAE] text-sm"}
              >
                Skip question
              </button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
