export const otherFilterOptions = [
  {
    title: "Platform",
    counter: 0,
    optionItems: [
      { label: "facebook", value: false },
      { label: "instagram", value: false },
      { label: "messenger", value: false },
      { label: "audience_network", value: false },
    ],
  },
  {
    title: "Time Live",
    counter: 0,
    optionItems: [
      { label: "under_7_days", value: false },
      { label: "over_7_days", value: false },
      { label: "over_30_days", value: false },
      { label: "over_3_months", value: false },
      { label: "over_6_months", value: false },
      { label: "over_12_months", value: false },
    ],
  },
  {
    title: "Score",
    counter: 0,
    optionItems: [
      { label: "winners", value: false },
      { label: "great", value: false },
      { label: "good", value: false },
      { label: "average", value: false },
      { label: "unknown", value: false },
    ],
  },
  {
    title: "Status",
    counter: 0,
    optionItems: [
      { label: "live", value: false },
      { label: "not_live", value: false },
    ],
  },
  {
    title: "Format",
    counter: 0,
    optionItems: [
      { label: "video", value: false },
      { label: "image", value: false },
    ],
  },
];
