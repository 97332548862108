import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Button } from "@/components/ui/button.tsx";
import { useAuth } from "@memberstack/react";
import { TopBar } from "@/components/topBar.tsx";

export const Route = createFileRoute("/library/manage-account/billing")({
  component: ManageAccount,
});

function ManageAccount() {
  const { signOut } = useAuth();

  const navigate = useNavigate();
  return (
    <div>
      <div className={"mx-4"}>
        <TopBar Title={"Static Ad Library"} />
        <hr />
      </div>
      <div className={"max-w-[1400px] mx-4"}>
        <div
          className={
            "bg-gray-100 rounded-lg py-4 px-2 flex justify-between mt-6"
          }
        >
          <div>
            <Button variant={"outline"}>
              <Link
                to={"/library/manage-account/profile"}
                activeProps={{
                  className: "text-blue-500",
                }}
              >
                Profile
              </Link>
            </Button>
            <Button className={"ml-4"} variant={"outline"}>
              <Link
                to={"/library/manage-account/billing"}
                activeProps={{
                  className: "text-blue-500",
                }}
              >
                Billing
              </Link>
            </Button>
          </div>
          <Button
            variant={"outline"}
            onClick={async () => {
              await signOut();
              await navigate({
                from: "/",
                to: "/login",
              });
            }}
            className="[&.active]:font-bold"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="currentColor"
            >
              <g>
                <path d="M0,0h24v24H0V0z" fill="none"></path>
              </g>
              <g>
                <g>
                  <path d="M5,5h6c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h6c0.55,0,1-0.45,1-1v0 c0-0.55-0.45-1-1-1H5V5z"></path>
                  <path d="M20.65,11.65l-2.79-2.79C17.54,8.54,17,8.76,17,9.21V11h-7c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h7v1.79 c0,0.45,0.54,0.67,0.85,0.35l2.79-2.79C20.84,12.16,20.84,11.84,20.65,11.65z"></path>
                </g>
              </g>
            </svg>
            Log Out
          </Button>
        </div>
        <div className={"flex justify-between mt-4"}></div>
      </div>
    </div>
  );
}
