import { AirtableGetArgs } from "../../../shared/airtableGet.ts";
import { z } from "zod";
import { trpc } from "../utils/trpc.ts";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { ErrorDisplay } from "@/components/error.tsx";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useElementSize } from "@/hooks/useElementSize.tsx";
import { copyToClipboard as htmlCopyToClipboard } from "@/components/copyToClipboard.tsx";
import { CheckCircle, ShareIcon } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@memberstack/react";
import { useNavigate } from "@tanstack/react-router";
import { toast } from "sonner";
import { DotsLoader, Loader } from "@/components/custom-components/Loader";
import {
  AirTableAdRecord,
  AirtablePublicGetArgs,
} from "../../../shared/airtableGet.ts";

type props = {
  Filter: z.infer<typeof AirtableGetArgs>;
};

export const AdsView = (props: props) => {
  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined,
  );
  const [desiredCardWidth, setDesiredCardWidth] = useState<number>(0);

  // Columns needs to be controlled by the width of the container.
  // So we set the width of each masonry column to a fraction of the container width
  const [columns, setColumns] = useState<number | undefined>(undefined);
  const [squareRef, { width = 0 }] = useElementSize();
  const breakpoints = {
    600: 1,
    750: 2,
    900: 3,
    1200: 4,
  };
  const gutterWidth = 18;
  useEffect(() => {
    if (!width) {
      return;
    }
    if (width > 1200) {
      // width = 1400
      // desired = (1400 / 4) - (18 * 3)

      const newWidth =
        width / breakpoints[1200] - gutterWidth * (breakpoints[1200] - 1);
      setColumns(() => 4);
      setDesiredCardWidth(() => newWidth);
      return;
    }
    if (width > 900) {
      const newWidth =
        width / breakpoints[900] - gutterWidth * (breakpoints[900] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 3);
      return;
    }
    if (width > 750) {
      const newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 2);
      return;
    }
    if (width > 0) {
      const newWidth =
        width / breakpoints[600] - gutterWidth * (breakpoints[600] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 1);
      return;
    }
  }, [width]);

  // There's some shenanigans going on with the query here.
  // We need to invalidate this query when the posts get liked, but if we do that when the filter is random,
  // The page will refresh every time a post is liked.
  //
  // So the solution is to have a different rpc for liked posts vs regular filters
  const queryToCall = props.Filter.OnlyLiked ? trpc.likedPosts : trpc.posts;
  const { data, fetchNextPage, isError, isLoading, isRefetching } =
    queryToCall.useInfiniteQuery(props.Filter, {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false, // Having these false means random queries don't reset on window focus
      refetchOnWindowFocus: false,
    });

  const dateString =
    props.Filter.loadAdsCreatedAfter &&
    new Date(props.Filter.loadAdsCreatedAfter).toLocaleDateString();

  // Ref for trigger to fetch next page
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(
    function fetchNextPageWhenElemInView() {
      if (!inView || !data) {
        return;
      }
      if (!allData) {
        return;
      }
      if (!allData.length) {
        return;
      }
      // We've fetched all the data if these are equal
      if (data.pages[0].TotalRecords <= allData?.length) {
        return;
      }
      fetchNextPage();
    },

    [inView, data, fetchNextPage],
  );

  // Syncs react state with trpc state
  useEffect(
    function addFetchedAtaToReactState() {
      if (!data) return;
      setAllData(() => undefined);
      const records = [] as AirTableAdRecord[];
      for (const page of data.pages) {
        records.push(...page.ATRecords);
      }
      setAllData(() => records);
    },
    [data],
  );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!allData || isLoading || isRefetching) {
    return (
      <div className={"flex justify-center items-center w-full h-full m-auto"}>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      {data && data.pages.length > 0 && data.pages[0].hasAccess === false && (
        <div className={"px-10 mb-4"}>
          <div
            className={"bg-gray-100 py-4 rounded-lg font-bold max-w-fit px-4"}
          >
            <p>
              These are just 25 of the 1,000+ creatives available.{" "}
              <a
                className={"text-blue-500 underline"}
                href={"https://www.creativeos.io/#sign"}
              >
                Upgrade your account to see them all.
              </a>
            </p>
          </div>
        </div>
      )}
      {allData && props.Filter.loadAdsCreatedAfter && allData.length === 0 && (
        <div className={"w-full justify-center items-center text-center"}>
          <p>No recent ads added since {dateString}</p>
        </div>
      )}
      <div ref={squareRef} className={"relative w-full px-10 pb-10"}>
        {allData && (
          <div>
            <ResponsiveMasonry
              columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
            >
              <Masonry gutter={gutterWidth + "px"}>
                {allData.map((ad) => {
                  return (
                    <AdDisplay
                      IsPublic={data?.pages[0].hasAccess === false}
                      IsBlurred={false}
                      DesiredWidth={desiredCardWidth}
                      Ad={ad}
                      key={ad.atID}
                      shouldInvalidateCache={!props.Filter.OnlyLiked}
                    />
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        )}
        <div className={"relative"}>
          <div
            className={
              "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          ></div>
        </div>{" "}
        <div className={"relative"}>
          <div
            className={
              "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          ></div>
        </div>
      </div>
    </div>
  );
};

type publicProps = z.infer<typeof AirtablePublicGetArgs>;

export const AdsViewPublic = (props: publicProps) => {
  // If a user is logged in, they don't need to see this screen
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  if (isLoggedIn) {
    navigate({
      to: "/library/static/$adID",
      params: {
        adID: props.adID,
      },
    });
  }
  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined,
  );

  const [desiredCardWidth, setDesiredCardWidth] = useState<number>(0);

  // Columns needs to be controlled by the width of the container.
  // So we set the width of each masonry column to a fraction of the container width
  const [columns, setColumns] = useState<number | undefined>(undefined);
  const [squareRef, { width = 0 }] = useElementSize();
  const breakpoints = {
    600: 1,
    750: 2,
    900: 3,
    1200: 4,
  };
  const gutterWidth = 18;
  useEffect(() => {
    if (!width) {
      return;
    }
    if (width > 1200) {
      // width = 1400
      // desired = (1400 / 4) - (18 * 3)

      const newWidth =
        width / breakpoints[1200] - gutterWidth * (breakpoints[1200] - 1);
      setColumns(() => 4);
      setDesiredCardWidth(() => newWidth);
      return;
    }
    if (width > 900) {
      const newWidth =
        width / breakpoints[900] - gutterWidth * (breakpoints[900] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 3);
      return;
    }
    if (width > 750) {
      const newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 2);
      return;
    }
    if (width > 0) {
      const newWidth =
        width / breakpoints[600] - gutterWidth * (breakpoints[600] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 1);
      return;
    }
  }, [width]);

  // There's some shenanigans going on with the query here.
  // We need to invalidate this query when the posts get liked, but if we do that when the filter is random,
  // The page will refresh every time a post is liked.
  //
  // So the solution is to have a different rpc for liked posts vs regular filters
  const { data, isError, isLoading, isRefetching } =
    trpc.publicPostByID.useQuery({
      adID: props.adID,
      cacheBuster: props.cacheBuster,
    });

  // Syncs react state with trpc state
  useEffect(
    function addFetchedAtaToReactState() {
      if (!data) return;
      setAllData(() => undefined);
      const records = [] as AirTableAdRecord[];
      for (const page of data) {
        records.push(page);
      }
      setAllData(() => records);
    },
    [data],
  );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!allData || isLoading || isRefetching) {
    return (
      <div className={"flex justify-center items-center w-full h-full m-auto"}>
        <Loader />
      </div>
    );
  }

  return (
    <div ref={squareRef} className={"relative w-full px-10 pb-10"}>
      {allData && (
        <div>
          <ResponsiveMasonry
            columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
          >
            <Masonry gutter={gutterWidth + "px"}>
              {allData.map((ad, index) => {
                return (
                  <AdDisplay
                    IsPublic={true}
                    IsBlurred={index !== 0}
                    DesiredWidth={desiredCardWidth}
                    Ad={ad}
                    key={ad.atID}
                    shouldInvalidateCache={false}
                  />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      )}
    </div>
  );
};

type AdDisplayProps = {
  Ad: AirTableAdRecord;
  IsPublic: boolean; // If the ad is being viewed from a public user
  IsBlurred: boolean; // If the ad is public, all but the requested one should be blurred
  DesiredWidth: number;
  shouldInvalidateCache: boolean; // We don't want to perform a refetch when we're on the saved page
};

const AdDisplay = (props: AdDisplayProps) => {
  const [copied, setCopied] = useState(false);
  const { mutateAsync: removeLike } = trpc.removeLike.useMutation();
  const { mutateAsync: addLike } = trpc.likePost.useMutation();
  const [manuallySetLike, setManuallySetLike] = useState<undefined | boolean>(
    undefined,
  );
  const [height, setHeight] = useState<number | undefined>(undefined);
  const utils = trpc.useContext();
  const [, copyToClipboard] = useCopyToClipboard();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { isLoading, refetch } = trpc.getFigmaCopyPasteCode.useQuery(
    {
      id: props.Ad.CreativeID.toString(),
      type: "record",
    },
    {
      enabled: false,
    },
  );

  const handleCopyClick = async () => {
    if (props.IsPublic) {
      setDialogOpen(true);
      return;
    }

    // Attempt to copy immediately after fetching
    const success = await htmlCopyToClipboard(refetch);
    if (success) {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  // We know the desired width of the column but not the height, so we calculate that here
  useEffect(() => {
    if (!props.Ad["Example Image"][0].height || !props.DesiredWidth) {
      return;
    }
    const multiplier =
      props.DesiredWidth / props.Ad["Example Image"][0].width -
      props.DesiredWidth;
    const newHeight = props.Ad["Example Image"][0].height * multiplier;
    setHeight(() => newHeight);
  }, [props.DesiredWidth]);

  // Basic optimistic update of the like button
  useEffect(() => {
    if (props.Ad.Liked) {
      setManuallySetLike(true);
    } else {
      setManuallySetLike(false);
    }
  }, [props.Ad.Liked]);
  if (!height) {
    return <></>;
  }

  return (
    <>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Want the copy-and-paste template for this ad?
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Sign up for a free trial and get access to this ad and hundreds
              more
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Get Free Access</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div
        className={`break-inside-avoid-column bg-cardgray shadow-lg shadow-neutral-600/10 rounded-lg p-4 mb-5
        ${props.IsBlurred && "filter blur-md pointer-events-none"}
        `}
      >
        <img
          alt={"ad display"}
          data-original-width={props.Ad["Example Image"][0].width}
          data-original-height={props.Ad["Example Image"][0].height}
          style={{
            width: props.DesiredWidth,
            height: height,
          }}
          loading={"eager"}
          src={props.Ad["Example Image"][0].url}
          className={"mb-4"}
        />
        <div className={"flex items-center justify-center space-x-4"}>
          <div onClick={handleCopyClick}>
            {isLoading ? (
              <div>
                <DotsLoader />
              </div>
            ) : copied ? (
              <button
                className={
                  "h-[40px] flex items-center bg-white rounded-full space-x-4 px-5 py-0"
                }
              >
                <p className={"text-black text-sm leading-none font-semibold"}>
                  CMD+V in Figma
                </p>
              </button>
            ) : (
              <button
                className={
                  "flex items-center bg-black rounded-full space-x-4 px-5 py-2"
                }
              >
                <p
                  className={
                    "text-white text-sm leading-none font-semibold -mt-1"
                  }
                >
                  Copy
                </p>
                <FigmaLogo />
              </button>
            )}
          </div>

          <div
            onClick={(e) => {
              if (props.IsPublic) {
                e.preventDefault();
                setDialogOpen(true);
                return;
              }
            }}
          >
            <CanvaButton
              url={props.IsPublic ? "" : props.Ad["Canva Template LInk"]}
            />
          </div>

          {!props.IsPublic && (
            <>
              {manuallySetLike !== undefined && (
                <>
                  {manuallySetLike ? (
                    <button
                      onClick={() => {
                        setManuallySetLike(false);
                        removeLike({ postID: props.Ad.CreativeID.toString() });
                        if (props.shouldInvalidateCache)
                          utils.likedPosts.invalidate();
                      }}
                    >
                      <FullHeart />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setManuallySetLike(true);
                        addLike({ postID: props.Ad.CreativeID.toString() });
                        if (props.shouldInvalidateCache)
                          utils.likedPosts.invalidate();
                      }}
                    >
                      <EmptyHeart />
                    </button>
                  )}
                </>
              )}
            </>
          )}

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span
                  className={
                    "hover:text-blue-900 rounded-full transition-colors"
                  }
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/share/static/${props.Ad.atID}`,
                    );
                    toast(
                      <div className={"flex items-center"}>
                        <CheckCircle className={"w-[24px] h-[24px] mr-2"} />{" "}
                        <p className={"text-xl"}>Copied!</p>
                      </div>,
                      {
                        position: "top-center",
                      },
                    );
                  }}
                >
                  <ShareIcon className={"w-[30px] h-[30px]"} />
                </span>
              </TooltipTrigger>
              <TooltipContent>
                <p>Share a public link</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </>
  );
};

type canvaButtonProps = {
  url: string;
};

const CanvaButton = (props: canvaButtonProps) => {
  return (
    <div>
      <a target={"_blank"} href={props.url} className={""}>
        <CanvaLogo />
      </a>
    </div>
  );
};

const EmptyHeart = () => {
  return (
    <svg
      className={"w-[30px] h-[30px]"}
      xmlns="http://www.w3.org/2000/svg"
      width="562"
      height="507"
      viewBox="0 0 562 507"
      fill="none"
    >
      <path
        d="M153.565 26C83.1238 26 26 82.537 26 152.29C26 208.597 48.324 342.235 268.068 477.327C272.004 479.722 276.523 480.988 281.131 480.988C285.738 480.988 290.257 479.722 294.194 477.327C513.938 342.235 536.262 208.597 536.262 152.29C536.262 82.537 479.138 26 408.696 26C338.255 26 281.131 102.539 281.131 102.539C281.131 102.539 224.007 26 153.565 26Z"
        stroke="url(#paint0_linear_466_1438)"
        strokeWidth="51.0262"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_466_1438"
          x1="724.438"
          y1="655.486"
          x2="-312.625"
          y2="254.256"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0740287" />
          <stop offset="0.167038" stopColor="#0066A1" />
          <stop offset="0.224234" stopColor="#9D85FF" />
          <stop offset="0.590698" stopColor="#E13656" />
          <stop offset="0.721223" stopColor="#F68B0D" />
          <stop offset="0.757042" stopColor="#800BAA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const FullHeart = () => {
  return (
    <>
      <svg
        className={"w-[30px] h-[30px]"}
        xmlns="http://www.w3.org/2000/svg"
        width="562"
        height="507"
        viewBox="0 0 562 507"
        fill="none"
      >
        <path
          d="M153.565 26C83.1238 26 26 82.537 26 152.29C26 208.597 48.324 342.235 268.068 477.327C272.004 479.722 276.523 480.988 281.131 480.988C285.738 480.988 290.257 479.722 294.194 477.327C513.938 342.235 536.262 208.597 536.262 152.29C536.262 82.537 479.138 26 408.696 26C338.255 26 281.131 102.539 281.131 102.539C281.131 102.539 224.007 26 153.565 26Z"
          fill="url(#paint0_linear_466_1439)"
          stroke="url(#paint1_linear_466_1439)"
          strokeWidth="51.0262"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_466_1439"
            x1="724.438"
            y1="655.486"
            x2="-312.625"
            y2="254.256"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0740287" />
            <stop offset="0.167038" stopColor="#0066A1" />
            <stop offset="0.224234" stopColor="#9D85FF" />
            <stop offset="0.590698" stopColor="#E13656" />
            <stop offset="0.721223" stopColor="#F68B0D" />
            <stop offset="0.757042" stopColor="#800BAA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_466_1439"
            x1="724.438"
            y1="655.486"
            x2="-312.625"
            y2="254.256"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0740287" />
            <stop offset="0.167038" stopColor="#0066A1" />
            <stop offset="0.224234" stopColor="#9D85FF" />
            <stop offset="0.590698" stopColor="#E13656" />
            <stop offset="0.721223" stopColor="#F68B0D" />
            <stop offset="0.757042" stopColor="#800BAA" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export const FigmaLogo = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 300"
        width="16"
        height="24"
      >
        <path
          fill="#0acf83"
          d="M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z"
        />
        <path
          fill="#a259ff"
          d="M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z"
        />
        <path
          fill="#f24e1e"
          d="M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z"
        />
        <path
          fill="#ff7262"
          d="M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z"
        />
        <path
          fill="#1abcfe"
          d="M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z"
        />
      </svg>
    </>
  );
};

const CanvaLogo = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1900 1900"
        width="30"
        height="30"
        fill="none"
      >
        <circle cx="950" cy="950" r="950" fill="#7D2AE7" />
        <circle cx="950" cy="950" r="950" fill="url(#a)" />
        <circle cx="950" cy="950" r="950" fill="url(#b)" />
        <circle cx="950" cy="950" r="950" fill="url(#c)" />
        <circle cx="950" cy="950" r="950" fill="url(#d)" />
        <path
          fill="#fff"
          d="M1360.14 1144.88c-7.84 0-14.74 6.62-21.92 21.08-81.08 164.41-221.12 280.74-383.174 280.74-187.38 0-303.419-169.15-303.419-402.83 0-395.834 220.548-624.697 414.263-624.697 90.52 0 145.8 56.885 145.8 147.41 0 107.439-61.04 164.324-61.04 202.216 0 17.009 10.58 27.308 31.56 27.308 84.29 0 183.22-96.855 183.22-233.682 0-132.669-115.47-230.186-309.18-230.186-320.145 0-604.665 296.804-604.665 707.471 0 317.88 181.522 527.94 461.601 527.94 297.274 0 469.164-295.77 469.164-391.77 0-21.26-10.87-31-22.21-31Z"
        />
        <defs>
          <radialGradient
            id="a"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="rotate(-49.416 2013.48303023 443.1854085) scale(1469.49)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6420FF" />
            <stop offset="1" stopColor="#6420FF" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="b"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="rotate(54.703 42.71673666 594.1943586) scale(1657.12)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C4CC" />
            <stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="c"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="matrix(1023.00018 -1030.00171 473.71139 470.4913 367 1684)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6420FF" />
            <stop offset="1" stopColor="#6420FF" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="d"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="matrix(595.99869 1371.99953 -2298.40601 998.43108 777 256)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C4CC" stopOpacity=".725916" />
            <stop offset=".0001" stopColor="#00C4CC" />
            <stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </>
  );
};

// const AdDisplay2 = ({ index, data: AirtableRecords, width }) => (
//   <div
//     style={{
//       width: AirtableRecords["Example Image"][0].width / 3,
//       height: AirtableRecords["Example Image"][0].height / 3,
//     }}
//   >
//     <img loading={"lazy"} src={AirtableRecords["Example Image"][0].url} />
//   </div>
// );
