import { createFileRoute } from "@tanstack/react-router";
import { Tags } from "../../../shared/airtableGet.ts";
import { z } from "zod";
import { TopBar } from "@/components/topBar.tsx";

import {
  GenericCardProps,
  GenericGridView,
} from "@/components/GenericGridView.tsx";
import { trpc } from "@/utils/trpc.ts";
import { useMemo } from "react";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";

type SearchParams = {
  Tags?: z.infer<typeof Tags>;
  cacheBuster: number;
  loadAdsCreatedAfter?: number;
};

export const Route = createFileRoute("/library/landing-pages/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const cacheBuster = (search?.cacheBuster as number) ?? Math.random();
    return {
      cacheBuster: cacheBuster,
    };
  },
});

function All() {
  const { cacheBuster } = Route.useSearch();
  const { data, isLoading, error } = trpc.landingPages.useQuery(
    {
      cacheBuster: cacheBuster,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const formatData = useMemo((): GenericCardProps[] => {
    if (!data) return [];
    const returnData = [] as GenericCardProps[];
    data.LandingPages?.map((datum) => {
      returnData.push({
        Screenshot: datum["Landing Page Screenshot"],
        ID: datum["LP ID"],
        type: "landing-page",
      });
    });
    return returnData;
  }, [data]);

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={"sticky top-0 z-10 px-2"}>
        <div className={"bg-white px-5 lg:px-8 pt-6 pb-2"}>
          <TopBar Title={"Landing Page Library"} />
          <div
            className={
              "flex flex-col md:flex-row justify-between items-end md:items-start -ml-4 py-2"
            }
          ></div>
        </div>
        <div className={"h-5 bg-gradient-to-b from-white/100 to-white/0"}></div>
      </div>
      <GenericGridView
        type={"landing-page"}
        Cards={formatData}
        HasAccess={data ? data.HasAccess : false}
      />
    </>
  );
}
