export const PLAN_COOKIE_NAME = "_cb-pay-plan";

export const STANDARD_PLAN = {
  name: "Creative Plan",
  price: "$49.00",
  plan_id: "pln_creative-os-standard-hoq20qmr",
  description: "Perfect for brands/agencies running ads",
  features: [
    "1000+ Proven Ad Templates",
    "Ad Inspiration Feed",
    "Curated Brand List",
  ],
};

export const PRO_PLAN = {
  name: "Pro Plan",
  price: "$99.00",
  plan_id: "pln_creative-os-pro-hdpx0qjo",
  description: "Perfect for brands/agencies looking for full funnel creative",
  features: [
    "Everything in Creative +",
    "Proven Email Templates",
    "Proven Landing Page Templates",
    "Expert Curated Ad Templates",
  ],
};
