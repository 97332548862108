import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import memberstackDOM from "@memberstack/dom";
import { SignInModal } from "@memberstack/react";
import { useEffect } from "react";

export const Route = createFileRoute("/login")({
  component: Login,
});

function Login() {
  useEffect(() => {
    localStorage.clear(); // necessary to clear auth token
  }, []);
  return (
    <div className="">
      <SignInModal
        onSuccess={async () => {
          window.location.href =
            "/feeds/templates?orderFilter=Recent&cacheBuster=0.33150716487459353"; // Can't use navigate hook because it doesn't close the modal
        }}
      />
    </div>
  );
}

const formSchema = z.object({
  username: z.string().min(2).max(50),
  password: z.string().min(6),
});

// @ts-expect-error might need this later
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function LoginWithReactHookForm() {
  const navigate = useNavigate({ from: "/login" });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    const memberstack = memberstackDOM.init({
      publicKey: "pk_971c0de956b797603818",
    });

    const result = await memberstack.loginMemberEmailPassword(
      {
        password: values.password,
        email: values.username,
      },
      {},
    );

    console.log(result);
    await navigate({
      from: "/login",
      to: "/feeds/templates",
    });
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <>
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    autoComplete={"email"}
                    type={"email"}
                    placeholder=""
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            </>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <>
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    type={"password"}
                    autoComplete={"current-password"}
                    placeholder=""
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            </>
          )}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Form>
  );
}
