import { ComponentProps, forwardRef, ReactNode } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils.ts";

const tagStyles = cva(
  "px-[10px] py-[6px] bg-brandgradmin bg-center rounded-xl text-[0.75rem] text-white font-medium",
);

type TagProps = ComponentProps<"div"> &
  VariantProps<typeof tagStyles> & {
    label: string | ReactNode;
  };

export const PlanTag = forwardRef<HTMLSpanElement, TagProps>(
  ({ className, label, ...props }, ref) => {
    return (
      <span ref={ref} className={cn(tagStyles({ className }))} {...props}>
        {label}
      </span>
    );
  },
);
