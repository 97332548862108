import { useRef } from "react";
import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router";
import { Sidebar } from "@/components/Sidebar.tsx";
import { trpc } from "@/utils/trpc.ts";
import { ProtectedArea } from "@/components/protectedArea.tsx";
import { useOnClickOutside } from "@/hooks/useClickOutside.tsx";
import { z } from "zod";
import { orderFilter } from "../../../shared/airtableGet.ts";

type LibraryProps = {
  sideBarOpen?: boolean | undefined;
  orderFilter?: z.infer<typeof orderFilter> | undefined;
  cacheBuster?: number | undefined;
};

export const Route = createFileRoute("/library")({
  component: Library,
  validateSearch: (search: Record<string, unknown>): LibraryProps => {
    return {
      sideBarOpen: search.sideBarOpen as boolean | undefined,
      orderFilter: search.orderFilter as
        | z.infer<typeof orderFilter>
        | undefined,
      cacheBuster: search.cacheBuster as number | undefined,
    };
  },
});

function Library() {
  const { sideBarOpen } = Route.useSearch();
  const navigate = useNavigate();
  trpc.me.useQuery(null); // Runs because this is a great time to fetch User's data
  const ref = useRef(null);
  const handleClickOutside = () => {
    if (sideBarOpen) {
      navigate({
        search: (old) => {
          return {
            ...old,
            sideBarOpen: false,
          };
        },
        replace: true,
        params: (old) => {
          return {
            ...old,
          };
        },
      });
    }
  };
  useOnClickOutside(ref, handleClickOutside);

  return (
    <ProtectedArea>
      <>
        <div className={"relative flex h-screen overflow-y-hidden"}>
          <div
            className={`-ml-sidebar absolute md:ml-0 md:static w-sidebar flex-sidebar shrink-0 z-30 transition-all duration-500 ${sideBarOpen ? "ml-0" : "-ml-sidebar"}`}
          >
            <div ref={ref}>
              <Sidebar isPublicView={false} />
            </div>
          </div>
          <div className={"w-full flex-auto overflow-x-hidden overflow-y-auto"}>
            <div className={"max-w-pagemax"}>
              {/*<button*/}
              {/*  className={`md:hidden w-auto px-16 py-2 mx-10 mt-2 text-sm font-semibold text-white rounded-full ${sidebarState ? "bg-red-500" : "bg-blue-500"}`}*/}
              {/*  onClick={toggleSidebar}*/}
              {/*>*/}
              {/*  Debug Toggle Box*/}
              {/*</button>*/}

              <Outlet />
            </div>
          </div>
        </div>
      </>
    </ProtectedArea>
  );
}
