import { ComponentProps, forwardRef } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils.ts";

const adPublishStatusStyles = cva("flex gap-[0.313rem] mt-[0.063rem]");

type AdPublishStatusProps = ComponentProps<"div"> &
  VariantProps<typeof adPublishStatusStyles> & {
    date: string;
    status: string;
    showDate?: boolean;
    adRunningDays?: number;
  };

export const AdPublishStatus = forwardRef<HTMLDivElement, AdPublishStatusProps>(
  (
    { className, date, showDate = false, adRunningDays, status, ...props },
    ref,
  ) => {
    const isPublishedToday =
      date && new Date(date).toDateString() === new Date().toDateString();
    const publishedDaysAgo = adRunningDays;
    const adStatus = status && status.toLowerCase();
    const bgColor = isPublishedToday
      ? "bg-[#A259FF]"
      : adStatus.toLowerCase() !== "active"
        ? "bg-[#F68B0D]"
        : "bg-[#0ACF41]";
    const publishedText = isPublishedToday
      ? "PUBLISHED TODAY"
      : `${adStatus.toLowerCase() === "active" ? "ACTIVE" : "INACTIVE"} FOR ${publishedDaysAgo} DAYS`;

    return (
      <div
        ref={ref}
        className={cn(adPublishStatusStyles({ className }))}
        {...props}
      >
        <span
          className={`rounded-full ${bgColor} border-[0.063rem] border-white h-[0.625rem] w-[0.625rem]`}
        ></span>
        <span className="leading-[0.756rem] text-[0.625rem] font-[500] text-[#7F7F7F]">
          {showDate
            ? `PUBLISHED ON ${new Date(date).toLocaleDateString()}`
            : publishedText}
        </span>
      </div>
    );
  },
);
