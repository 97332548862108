import { forwardRef, useState, FormEvent, useEffect } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils.ts";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/custom-components/Accordion";
import { Text } from "@/components/custom-components";
import { Checkbox } from "@/components/custom-components/Checkbox";
import FilterIcon from "@/assets/FilterIcon.tsx";
import { XCircleIcon } from "lucide-react";
import { SelectedFilters } from "@/routes/feeds.inspiration.index.tsx";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/custom-components/Popover";
import { SelectedTemplateFilters } from "@/routes/feeds.templates.index.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";

const adFilterStyles = cva(
  "flex justify-between items-center gap-[0.625rem] w-full lg:w-[20.375rem] py-[0.7813rem] px-2.5 rounded-[6px] border-[#B4B4B4] border-[0.5px] bg-[#F5F5F5]",
);

type OptionItem = {
  label: string;
  value: boolean;
};

export type AccordionData = {
  title: string;
  counter: number;
  optionItems: OptionItem[];
};

type AdFilterProps = {
  className?: string;
  initialOptions: AccordionData[];
  onOptionsChange?: (options: AccordionData[]) => void;
  placeholder: string;
} & VariantProps<typeof adFilterStyles>;

export const AdFilter = forwardRef<HTMLButtonElement, AdFilterProps>(
  (
    { className, initialOptions, onOptionsChange, placeholder, ...props },
    ref,
  ) => {
    const [options, setOptions] = useState<AccordionData[]>(initialOptions);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);

    useEffect(() => {
      if (onOptionsChange) onOptionsChange(options);
    }, [options, onOptionsChange]);

    const handleCheckboxChange = (
      accordionIndex: number,
      optionIndex: number,
      checked: boolean | FormEvent<HTMLButtonElement>,
    ) => {
      let isChecked: boolean;

      if (typeof checked === "boolean") {
        isChecked = checked;
      } else {
        isChecked =
          checked.currentTarget.getAttribute("aria-checked") === "true";
        isChecked = !isChecked;
      }

      setOptions((prevOptions) =>
        prevOptions.map((group, index) => {
          if (index === accordionIndex) {
            const newOptionItems = group.optionItems.map((item, idx) => {
              if (idx === optionIndex) {
                return { ...item, value: isChecked };
              }
              return item;
            });
            return { ...group, optionItems: newOptionItems };
          }
          return group;
        }),
      );
    };

    const handleRemoveFilter = (filterType: string, filterValue: string) => {
      setOptions((prevOptions) =>
        prevOptions.map((group) => {
          if (group.title.toLowerCase() === filterType) {
            const newOptionItems = group.optionItems.map((item) => {
              if (item.label === filterValue) {
                return { ...item, value: false };
              }
              return item;
            });
            return { ...group, optionItems: newOptionItems };
          }
          return group;
        }),
      );
    };

    const handleClearFilters = () => {
      setOptions((prevOptions) =>
        prevOptions.map((group) => ({
          ...group,
          optionItems: group.optionItems.map((item) => ({
            ...item,
            value: false,
          })),
        })),
      );
    };

    const selectedFilters = options.reduce(
      (acc, group) => {
        const selectedItems = group.optionItems.filter((item) => item.value);
        if (selectedItems.length > 0) {
          acc[group.title.toLowerCase()] = selectedItems.map(
            (item) => item.label,
          );
        }
        return acc;
      },
      {} as Record<string, string[]>,
    );

    return (
      <Popover open={filterOpen} onOpenChange={setFilterOpen} {...props}>
        <div
          className={
            "w-[100%] flex flex-col gap-2 lg:gap-0 lg:items-start lg:flex-row lg:mb-5"
          }
        >
          <PopoverTrigger>
            <button ref={ref} className={cn(adFilterStyles({ className }))}>
              <div className={"flex gap-2 items-center"}>
                <FilterIcon />
                <span className={"pl-2 text-sm text-nowrap"}>
                  {placeholder}
                </span>
              </div>
              <div>
                <ChevronDownIcon />
              </div>
            </button>
          </PopoverTrigger>
          <PopoverContent
            align={"start"}
            sideOffset={-50}
            className={
              "border-[#B4B4B4] border-[0.5px] bg-[#F5F5F5] w-[90vw] md:w-[20.375rem]"
            }
          >
            <div
              onClick={() => setFilterOpen(false)}
              className={"flex justify-between"}
            >
              <div className={"flex gap-2 items-center"}>
                <FilterIcon />
                <span className={"pl-2 text-sm text-nowrap"}>
                  {placeholder}
                </span>
              </div>
              <div>
                <ChevronUpIcon />
              </div>
            </div>
            <hr className={"border-[#B4B4B4] my-2.5"} />
            <ScrollArea
              className={
                "max-h-[25.625rem] lg:max-h-[25.75rem] overflow-y-auto z-50"
              }
            >
              {options && options.length > 0 && (
                <Accordion type="single" collapsible>
                  {options.map((item, index) => (
                    <AccordionItem key={item.title} value={`item-${index}`}>
                      <AccordionTrigger
                        className={index < options.length - 1 ? "border-b" : ""}
                      >
                        <div className={"flex items-center gap-[0.625rem]"}>
                          <Text>{item.title}</Text>
                          {/*<Counter>{checkedCounts[index]}</Counter>*/}
                        </div>
                      </AccordionTrigger>
                      <AccordionContent
                        className={`pl-5 mt-2 ${
                          item.optionItems.length > 5
                            ? "max-h-36 overflow-y-auto h-auto"
                            : ""
                        }`}
                      >
                        <ScrollArea className={"pr-3"}>
                          {item.optionItems.map((option, idx) => (
                            <div className={"mb-2"} key={idx}>
                              <label
                                className={"flex gap-2 justify-between"}
                                htmlFor={option.label}
                              >
                                {option.label
                                  .split("_")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1),
                                  )
                                  .join(" ")}
                                <Checkbox
                                  id={option.label}
                                  checked={option.value}
                                  onClick={(checked) =>
                                    handleCheckboxChange(index, idx, checked)
                                  }
                                />
                              </label>
                            </div>
                          ))}
                        </ScrollArea>
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              )}
            </ScrollArea>
          </PopoverContent>
          {Object.entries(selectedFilters).length > 0 && (
            <div
              className={"hidden lg:block border-r border-[#B4B4B4] h-12 mx-5"}
            />
          )}
          <SelectedFiltersBadges
            selectedFilters={selectedFilters}
            onRemoveFilter={handleRemoveFilter}
            onClearFilter={handleClearFilters}
          />
        </div>
      </Popover>
    );
  },
);

export default AdFilter;

type SelectedFiltersBadgesProps = {
  selectedFilters: SelectedFilters | SelectedTemplateFilters;
  onRemoveFilter: (filterType: string, filterValue: string) => void;
  onClearFilter: () => void;
};

const SelectedFiltersBadges: React.FC<SelectedFiltersBadgesProps> = ({
  selectedFilters,
  onRemoveFilter,
  onClearFilter,
}) => {
  const renderBadges = (filters: string[], filterType: string) => {
    return filters.map((filter) => (
      <span
        key={filter}
        className={`flex items-center gap-2 bg-white border-[0.5px] border-[#A259FF] hover:text-black transition-colors duration-200 px-4 py-3 rounded-md`}
      >
        <span
          className={
            "text-sm text-nowrap bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
          }
        >
          {filter
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </span>
        <button onClick={() => onRemoveFilter(filterType, filter)}>
          <XCircleIcon size={"0.8rem"} color={"#A259FF"} />
        </button>
      </span>
    ));
  };

  return (
    <div className="flex flex-1 lg:w-auto items-center lg:items-start gap-2.5 lg:gap-5 flex-wrap">
      {Object.entries(selectedFilters).map(([filterType, filters]) =>
        renderBadges(filters, filterType),
      )}
      {Object.entries(selectedFilters).length > 0 && (
        <div className={"flex flex-col justify-center h-12 items-center"}>
          <button
            type={"button"}
            onClick={onClearFilter}
            className={"text-[#000B4B4B]"}
          >
            Clear
          </button>
        </div>
      )}
    </div>
  );
};
