import { AppHeader } from "@/components/templates/AppHeader";
import { Button, Text } from "@/components/custom-components";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { useNavigate } from "@tanstack/react-router";

export default function OnboardingComplete() {
  const navigate = useNavigate();

  const { mutateAsync: completeOnboarding, isPending } =
    trpc.completeOnboarding.useMutation();

  if (isPending) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div className={"min-h-screen"}>
      <div className={"bg-white px-5 lg:px-8 lg:pb-2"}>
        <AppHeader Title={""} IsPublic={true} IsDashboard={false} />
      </div>
      <div
        className={
          "min-h-screen bg-brandgrad bg-no-repeat bg-cover h-full flex-1 flex flex-col justify-center items-center px-5 "
        }
      >
        <div
          className={
            "flex flex-col justify-center items-center text-white text-center"
          }
        >
          <Text
            weight={"semibold"}
            className={"text-4xl mb-5"}
            align={"center"}
            as={"h2"}
          >
            Get a head start
          </Text>
          <Text weight={"medium"} size={"lg"} align={"center"} as={"p"}>
            Get up to speed on how to use our most impactful features
          </Text>
          <Button
            onClick={async () => {
              await completeOnboarding();
              await navigate({ to: "/feeds/templates" });
            }}
            className={
              "min-w-[13.125rem] text-xl self-center mt-10 border-2 border-white"
            }
            variant={"secondary"}
          >
            <span className={"hidden lg:flex"}>Start Creating</span>
            <span className={"flex lg:hidden"}>I'm ready</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
