import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { trpc } from "../utils/trpc.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/custom-components/Select";
import { AdGridView } from "@/components/templates/AdGridView";
import {
  AccordionData,
  AdFilter,
  PlanTag,
  Text,
} from "@/components/custom-components";
import { Divider } from "@/components/custom-components/Divider";
import { cloneElement, useCallback, useEffect, useState } from "react";
import AdIcon from "@/assets/AdIcon.tsx";
import EmailIcon from "@/assets/EmailIcon.tsx";
import LanderIcon from "@/assets/LanderIcon.tsx";
import {
  AirTableAdRecord,
  orderFilter as OrderFilterTypes,
} from "../../../shared/airtableGet.ts";
import { Loader } from "@/components/custom-components/Loader";
import { ErrorDisplay } from "@/components/error.tsx";
import { useInView } from "react-intersection-observer";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { AdCard } from "@/components/templates/AdCard";
import { useElementSize } from "@/hooks/useElementSize.tsx";
import FullAccessPromptDialog from "@/components/FullAccessPromptDialog.tsx";
import { LockClosedIcon } from "@radix-ui/react-icons";
import { SidebarButton } from "@/components/templates/Sidebar";
import { z } from "zod";

export type SelectedTemplateFilters = {
  collections?: string[];
};

type SearchParams = {
  collections?: string;
  sideBarOpen?: boolean;
};

type FilterOption = {
  title: string;
  counter: number;
  optionItems: { label: string; value: boolean }[];
};

export const Route = createFileRoute("/feeds/templates/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const collections = search?.collections as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      collections,
      sideBarOpen,
    };
  },
});

function All() {
  const {
    collections: queryCollections,
    orderFilter,
    sideBarOpen,
  } = Route.useSearch();
  const navigate = useNavigate();

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined,
  );
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [cursor, setCursor] = useState(1);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<string[]>(
    [],
  );

  const [selectedFilters, setSelectedFilters] =
    useState<SelectedTemplateFilters>({
      collections: queryCollections ? queryCollections.split(",") : undefined,
    });

  // Get the template collections to be passed to the filter
  const { data: templateCollections } = trpc.getAllCollections.useQuery(
    {},
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  // Update the filters after fetching the templates
  useEffect(() => {
    if (templateCollections) {
      setFilterOptions([
        {
          title: "Collections",
          counter: 0,
          optionItems: templateCollections.map((i) => ({
            label: i.Title,
            value: false,
          })),
        },
      ]);
    }
  }, [templateCollections]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => {
      const updatedOptionItems = option.optionItems.map((item) => ({
        ...item,
        value:
          option.title === "Collections" &&
          selectedFilters.collections?.includes(item.label),
      }));

      return {
        ...option,
        optionItems: updatedOptionItems,
      };
    });

    setFilterOptions(updatedOptions as FilterOption[]);
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: { collections?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.collections)
        searchParams.set("collections", params.collections);

      navigate({
        to: "/feeds/templates",
        replace: true,
        search: (old) => {
          return { ...old, ...params };
        },
      });
    },
    [navigate],
  );

  const handleOptionsChange = useCallback(
    (options: AccordionData[]) => {
      const selectedCollections: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Collections")
              selectedCollections.push(item.label);
          }
        });
      });

      setSelectedFilters({
        collections:
          selectedCollections.length > 0 ? selectedCollections : undefined,
      });

      updateQueryString({
        collections:
          selectedCollections.length > 0
            ? selectedCollections.join(",")
            : undefined,
      });
    },
    [updateQueryString],
  );

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.collections)
      params.collections = selectedFilters.collections.join(",");
  }, [selectedFilters]);

  // check for if there is at least one option selected
  const isAnyFilterSelected = (filters: SelectedTemplateFilters) => {
    return filters.collections && filters.collections.length > 0;
  };

  // update cursor whenever selectedFilters change
  useEffect(() => {
    setCursor(1); // Reset cursor to 1 whenever filters change

    // Get the atID of the collection when selected in the filter
    if (
      selectedFilters.collections &&
      selectedFilters.collections.length > 0 &&
      templateCollections
    ) {
      const collectionIds: string[] = selectedFilters.collections
        .map((selectedTitle) => {
          const selectedCollection = templateCollections.find(
            (collection) => collection.Title === selectedTitle,
          );
          return selectedCollection?.atID?.toString() ?? null;
        })
        .filter((id): id is string => id !== null); // Filter out null values

      setSelectedCollectionIds(collectionIds);
    } else {
      setSelectedCollectionIds([]); // Clear if no collections are selected
    }
  }, [selectedFilters, templateCollections]);

  const { data, fetchNextPage, isLoading, isError, isRefetching } =
    trpc.filterTemplatesByCollections.useInfiniteQuery(
      {
        collectionIds: selectedCollectionIds,
        Tags: undefined,
        limit: 20,
        Ready: true,
      },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled:
          Boolean(isAnyFilterSelected(selectedFilters)) &&
          selectedCollectionIds.length > 0,
        initialCursor: cursor,
      },
    );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (!inView || !data || !allData || !allData.length) return;
    if (data.pages[0].TotalRecords <= allData?.length) {
      return;
    }
    fetchNextPage();
  }, [inView, data, allData, fetchNextPage]);

  useEffect(() => {
    if (!data) return;

    setAllData(() => undefined);
    const records = [] as AirTableAdRecord[];
    for (const page of data.pages) {
      records.push(...page.ATRecords);
    }
    setAllData(() => records);
  }, [data]);

  const [desiredCardWidth, setDesiredCardWidth] = useState<number>(0);

  // Columns needs to be controlled by the width of the container.
  // So we set the width of each masonry column to a fraction of the container width
  const [columns, setColumns] = useState<number | undefined>(undefined);
  const [squareRef, { width = 0 }] = useElementSize();

  const breakpoints = {
    600: 1,
    750: 2,
    900: 3,
    1200: 4,
  };
  const gutterWidth = 18;
  useEffect(() => {
    if (!width) {
      return;
    }
    if (width > 1200) {
      // width = 1400
      // desired = (1400 / 4) - (18 * 3)

      const newWidth =
        width / breakpoints[1200] - gutterWidth * (breakpoints[1200] - 1);
      setColumns(() => 4);
      setDesiredCardWidth(() => newWidth);
      return;
    }
    if (width > 900) {
      const newWidth =
        width / breakpoints[900] - gutterWidth * (breakpoints[900] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 3);
      return;
    }
    if (width > 750) {
      const newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 2);
      return;
    }
    if (width > 0) {
      const newWidth =
        width / breakpoints[600] - gutterWidth * (breakpoints[600] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 1);
      return;
    }
  }, [width]);

  return (
    <>
      {upgradeDialogOpen && (
        <FullAccessPromptDialog
          upgradeDialogOpen={upgradeDialogOpen}
          setUpgradeDialogOpen={() => setUpgradeDialogOpen(false)}
        />
      )}
      <div className={""}>
        <div className={"bg-white px-5 md:px-5 lg:pb-2 sticky top-0 z-10"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Ad Templates
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
          </div>
          <div className={"flex gap-5 items-center justify-between"}>
            <div className={"flex gap-3 lg:gap-5 items-center"}>
              <span className={"hidden lg:flex"}>Show:</span>
              <div
                className={
                  "flex gap-2 lg:gap-5 items-center overflow-x-auto w-[90vw] lg:w-auto"
                }
              >
                {[
                  {
                    name: "Ad Templates",
                    link: "/feeds/templates",
                    icon: <AdIcon />,
                  },
                  {
                    name: "Emails",
                    link: "/feeds/templates/emails",
                    icon: <EmailIcon />,
                  },
                  {
                    name: "Landers",
                    link: "/feeds/templates/landing-pages",
                    icon: <LanderIcon />,
                  },
                ].map((item) => {
                  const isActive = location.pathname === item.link;
                  const iconColor = isActive ? "white" : "black";
                  return (
                    <Link
                      onClick={(e) => {
                        // If a user navigates before loading is complete, choose to just navigate
                        // This optimizes for users who ARE allowed access and doesn't force them to
                        // wait until we have the data back from the server if they have access

                        if (
                          (item.name === "Emails" || item.name === "Landers") &&
                          !isLoadingPermission &&
                          !permissionData?.userCanAccessEverything
                        ) {
                          setUpgradeDialogOpen(true);
                          e.preventDefault();
                        }
                      }}
                      to={item.link}
                      search={(d) => ({
                        ...d,
                        cacheBuster: Math.random(),
                        orderFilter: "Random",
                        sideBarOpen: false,
                      })}
                      className="flex gap-2.5 justify-start items-center h-12 lg:h-10 px-3 lg:px-5 py-4 border border-black rounded-md text-[0.875rem] font-normal"
                      activeProps={{
                        className:
                          "border-0 border-transparent rounded-lg bg-gradient-to-b from-[#A259FF] to-[#613599] text-white",
                      }}
                      activeOptions={{
                        exact: true,
                        includeSearch: false,
                      }}
                      key={item.name}
                    >
                      <span>
                        <span>
                          {cloneElement(item.icon, { color: iconColor })}
                        </span>
                      </span>
                      <Text
                        className={"w-auto text-nowrap text-sm md:text-base"}
                        weight={"medium"}
                        data-testid={item.name}
                      >
                        {item.name}
                      </Text>
                      {(item.name === "Emails" || item.name === "Landers") && (
                        <>
                          {!isLoadingPermission &&
                            !permissionData?.userCanAccessEverything && (
                              <PlanTag label={<LockClosedIcon />} />
                            )}
                        </>
                      )}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className={"hidden lg:flex"}>
              <Select
                onValueChange={(value) => {
                  navigate({
                    search: (old) => {
                      return {
                        ...old,
                        cacheBuster:
                          value === "Random" ? Math.random() : undefined,
                        orderFilter: value as z.infer<typeof OrderFilterTypes>,
                      };
                    },
                    params: (old) => {
                      return {
                        ...old,
                      };
                    },
                  });
                }}
              >
                <SelectTrigger className="w-32 h-10">
                  <SelectValue placeholder={orderFilter || "Recent"} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Recent">Most Recent</SelectItem>
                  <SelectItem value="Popular">Popular</SelectItem>
                  <SelectItem value="Oldest">Oldest</SelectItem>
                  <SelectItem value="Random">Random</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className={"flex justify-between gap-3 mt-2.5"}>
            {filterOptions && filterOptions.length > 0 && (
              <div className="">
                <AdFilter
                  initialOptions={filterOptions}
                  onOptionsChange={handleOptionsChange}
                  placeholder={"Filter Ad Templates"}
                />
              </div>
            )}
            <div className={"flex lg:hidden"}>
              <Select
                onValueChange={(value) => {
                  navigate({
                    search: (old) => {
                      return {
                        ...old,
                        cacheBuster:
                          value === "Random" ? Math.random() : undefined,
                        orderFilter: value as z.infer<typeof OrderFilterTypes>,
                      };
                    },
                    params: (old) => {
                      return {
                        ...old,
                      };
                    },
                  });
                }}
              >
                <SelectTrigger className="w-32 h-12">
                  <SelectValue placeholder={orderFilter || "Recent"} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Recent">Most Recent</SelectItem>
                  <SelectItem value="Popular">Popular</SelectItem>
                  <SelectItem value="Oldest">Oldest</SelectItem>
                  <SelectItem value="Random">Random</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        {isLoading || isRefetching ? (
          <div className="flex justify-center items-center w-full h-[70vh]">
            <Loader />
          </div>
        ) : isError ? (
          <ErrorDisplay />
        ) : data ? (
          <div className={"px-5"}>
            {allData && allData.length === 0 ? (
              <div className="flex flex-col justify-center items-center">
                <p className="text-center w-4/5 lg:w-1/2 mb-5">
                  Looks like you've gone down a path with no inspiration...this
                  is your fault! All we do is win...but really, maybe try a
                  different configuration of filters - we got you!
                </p>
                <img src="/giphy.webp" width="480" height="270" alt="" />
              </div>
            ) : (
              <div>
                <div
                  ref={squareRef}
                  className={"relative w-full lg:px-0 pb-10"}
                >
                  {data && (
                    <>
                      {data.pages[0].TotalRecords === 0 ? (
                        <div
                          className={"lg:h-96 flex justify-center items-center"}
                        >
                          <p>No templates added to this collection</p>
                        </div>
                      ) : (
                        allData && (
                          <div>
                            <ResponsiveMasonry
                              columnsCountBreakPoints={
                                columns ? { 0: columns } : {}
                              } // Columns is determined by the width of the container
                            >
                              <Masonry gutter={gutterWidth + "px"}>
                                {allData.map((ad) => {
                                  return (
                                    <AdCard
                                      key={ad.atID}
                                      adData={{
                                        Ad: ad,
                                        IsBlurred: false,
                                        IsPublic: false,
                                        brandName: undefined,
                                        shouldInvalidateCache: false,
                                        DesiredWidth: desiredCardWidth,
                                      }}
                                      onClickLearnMore={() => undefined}
                                    />
                                  );
                                })}
                              </Masonry>
                            </ResponsiveMasonry>
                          </div>
                        )
                      )}
                    </>
                  )}
                  <div className={"relative"}>
                    <div
                      className={
                        "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                      }
                      ref={scrollRef}
                    ></div>
                  </div>{" "}
                  <div className={"relative"}>
                    <div
                      className={
                        "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                      }
                      ref={scrollRef}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          !isAnyFilterSelected(selectedFilters) && (
            <AdGridView
              Filter={{
                Expert: undefined,
                Tags: undefined,
                loadAdsCreatedAfter: undefined,
                Ready: true,
                cursor: undefined,
                limit: 20,
                sortingOptions: orderFilter as z.infer<typeof OrderFilterTypes>,
              }}
            />
          )
        )}
      </div>
    </>
  );
}
