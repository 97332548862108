import { Text } from "@/components/custom-components";
import { Button as ShadButton } from "@/components/ui/button.tsx";
import { Box } from "@/components/custom-components/Layout";
import { useState } from "react";
import { ProfileModal } from "@memberstack/react";
import { Link, useRouterState } from "@tanstack/react-router";
import { Divider } from "@/components/custom-components/Divider";
import CursorIcon from "@/assets/CursorIcon.tsx";
import { trpc } from "@/utils/trpc.ts";
import FullAccessPromptDialog from "@/components/FullAccessPromptDialog.tsx";

type AppHeaderProps = {
  Title: string;
  IsPublic?: boolean;
  IsDashboard?: boolean;
};

export const AppHeader = ({
  Title,
  IsPublic = false,
  IsDashboard = true,
}: AppHeaderProps) => {
  const router = useRouterState();
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
  const { data, isLoading } = trpc.permissions.useQuery(null, {});

  return (
    <Box>
      {upgradeDialogOpen && (
        <FullAccessPromptDialog
          upgradeDialogOpen={upgradeDialogOpen}
          setUpgradeDialogOpen={() => setUpgradeDialogOpen(false)}
        />
      )}
      <Box
        className={`lg:w-full flex justify-between lg:justify-between items-center bg-white`}
      >
        {IsPublic ? (
          <div className={""}>
            <Link
              search={(old) => ({
                ...old,
                sideBarOpen: false,
              })}
              params={(old) => ({
                ...old,
              })}
            >
              <img
                alt={"creativeos.io"}
                className={"w-14 h-14"}
                src={"/logo.svg"}
              />
            </Link>
          </div>
        ) : (
          <div className={"flex gap-5 items-center shrink-0"}>
            <Link
              search={(old) => ({
                ...old,
                sideBarOpen: true,
              })}
              params={(old) => ({
                ...old,
              })}
              className={
                "group flex md:hidden items-center justify-center w-12 h-12 bg-white active:bg-slate-100 rounded-full"
              }
            >
              <svg
                className={"fill-slate-700"}
                xmlns="http://www.w3.org/2000/svg"
                height="36"
                viewBox="0 -960 960 960"
                width="36"
              >
                <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
              </svg>
            </Link>
            <Text
              size={"xl"}
              weight={"medium"}
              className={"w-[70%] lg:w-auto truncate"}
            >
              {Title}
            </Text>
          </div>
        )}
        {!IsPublic && <ManageAccount />}
      </Box>
      {!IsPublic && (
        <div
          className={
            "lg:hidden grid grid-cols-3 gap-5 mt-[1.0625rem] mb-[1.375rem]"
          }
        >
          {[
            { title: "Ad Feed", link: "/feeds/static" },
            { title: "LPs", link: "/feeds/landing-pages" },
            { title: "Emails", link: "/feeds/emails" },
          ].map((item) => (
            <Link
              onClick={(e) => {
                // if (props.isPublicView) {
                //     setPublicDialogOpen(true);
                //     e.preventDefault();
                // }
                // If a user navigates before loading is complete, choose to just navigate
                // This optimizes for users who ARE allowed access and doesn't force them to
                // wait until we have the data back from the server if they have access

                if (
                  item.title !== "Ad Feed" &&
                  !isLoading &&
                  !data?.userCanAccessEverything
                ) {
                  setUpgradeDialogOpen(true);
                  e.preventDefault();
                }
              }}
              key={item.title}
              // onClick={(e) => {
              //   if (props.isPublicView) {
              //     setDialogOpen(true);
              //     e.preventDefault();
              //   }
              // }}
              to={item.link}
              search={(d) => ({
                ...d,
                cacheBuster: Math.random(),
                orderFilter: "Random",
                sideBarOpen: false,
              })}
              activeProps={{
                className: "font-bold text-white bg-upgradepromptbg ",
              }}
              activeOptions={{
                includeSearch: false,
              }}
              className={`w-full flex justify-center py-[1.0625rem] px-[0.5rem] gap-[0.6875rem] border-[0.063rem] ${router.location.pathname === item.link ? "border-[#A259FF]" : "border-black"} rounded-[0.469rem]`}
            >
              <span className={"flex justify-center gap-[0.6875rem]"}>
                <CursorIcon
                  color={
                    router.location.pathname === item.link ? "#A259FF" : "black"
                  }
                />
                <Text className={"text-nowrap"}>{item.title}</Text>
              </span>
            </Link>
          ))}
        </div>
      )}
      {IsDashboard && <Divider className={"my-[1.25rem] hidden md:flex"} />}
    </Box>
  );
};

export const ManageAccount = ({
  sidebarCollapsed = false,
}: {
  sidebarCollapsed?: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { mutate: openPortalSessionUrl } = trpc.getPortalSessionUrl.useMutation(
    {
      onSuccess: async (data) => {
        if (data) {
          console.log(data);
          window.location.href = data;
        }
      },
    },
  );

  const { data: chargebeeSyncStatus } = trpc.getIsUserSynced.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  );

  // useEffect(() => {
  //   if (chargebeeSyncStatus && !isLoadingChargebeeSynced) {
  //     openPortalSessionUrl();
  //     // window.location.href = openPortalSessionUrl;
  //   }
  // }, [chargebeeSyncStatus, isLoadingChargebeeSynced]);

  return (
    <div className={"flex justify-start items-center gap-[1rem]"}>
      <div className={"flex w-[2.5rem] h-[2.5rem]"}>
        <img
          alt={""}
          onClick={() => {
            setModalOpen(true);
          }}
          src={"/bg_gradient.jpg"}
          className={
            "rounded-full cursor-pointer shrink-0 bg-[#A259FF] w-[2.5rem] h-[2.5rem]"
          }
        />
      </div>
      {!sidebarCollapsed && (
        <div className={"hidden md:block md:ml-2"}>
          <ShadButton
            className={"p-0 h-auto text-black font-medium"}
            variant={"link"}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <span className={"text-black"}>My Account</span>
          </ShadButton>
          {chargebeeSyncStatus && (
            <div>
              <span
                className={
                  "text-nowrap text-xs cursor-pointer bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
                onClick={() => openPortalSessionUrl()}
              >
                Manage Subscription
              </span>
            </div>
          )}
          {modalOpen && <ProfileModal onClose={() => setModalOpen(false)} />}
        </div>
      )}
    </div>
  );
};
