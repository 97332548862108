import { createFileRoute, Link } from "@tanstack/react-router";
import { Button } from "@/components/ui/button.tsx";

export const Route = createFileRoute("/auth")({
  component: Auth,
});

function Auth() {
  return (
    <div>
      <div className={"mb-12"}>
        <Button variant={"default"}>
          <Link to={"/login"}>Log In</Link>
        </Button>
      </div>

      <Button variant={"default"}>
        <Link to={"/sign-up"}>Sign Up</Link>
      </Button>
    </div>
  );
}
