import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/")({
  component: Index,
});

function Index() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate({
      to: "/feeds/templates",
      params: {
        orderFilter: "Random",
        cacheBuster: Math.random(),
      },
    });
  }, [navigate]);
  return (
    <div className="text-2xl">
      <h3>Welcome Home!</h3>
    </div>
  );
}
