"use client";

import * as React from "react";
import { CheckIcon } from "@radix-ui/react-icons";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

import { cn } from "@/lib/utils";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-2", className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, checked, id, ...props }, ref) => {
  return (
    <label
      htmlFor={id}
      className={cn(
        "bg-white min-h-[5rem] flex flex-row items-center space-x-5 space-y-0 rounded-[0.938rem] border-2 px-5 py-5",
        checked ? "border-[#A259FF]" : "border-gray-300",
        className,
      )}
    >
      <span className="flex items-center space-x-3 flex-1">
        <RadioGroupPrimitive.Item
          ref={ref}
          id={id}
          className={cn(
            "peer h-[1.25rem] w-[1.25rem] shrink-0 rounded-full border border-[#7324A2] ring-offset-background focus-visible:outline-checkgrad focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-checkgrad",
            className,
          )}
          {...props}
        >
          <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
            <CheckIcon className="h-4 w-4" />
          </RadioGroupPrimitive.Indicator>
        </RadioGroupPrimitive.Item>
        <span className="text-gray-900">{id}</span>
      </span>
    </label>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

const PlanRadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, checked, id, ...props }, ref) => {
  return (
    <label
      htmlFor={id}
      className={cn(
        "bg-white min-h-[5rem] flex flex-row items-center space-x-5 space-y-0 rounded-[0.938rem] border-2 px-5 py-5",
        checked ? "border-[#A259FF]" : "border-gray-300",
        className,
      )}
    >
      <span className="flex items-center space-x-3 flex-1">
        <RadioGroupPrimitive.Item
          ref={ref}
          id={id}
          className={cn(
            "peer h-[1.25rem] w-[1.25rem] shrink-0 rounded-full border border-[#7324A2] ring-offset-background focus-visible:outline-checkgrad focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-checkgrad",
            className,
          )}
          {...props}
        >
          <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
            <CheckIcon className="h-4 w-4" />
          </RadioGroupPrimitive.Indicator>
        </RadioGroupPrimitive.Item>
        <span className="text-gray-900">{id}</span>
      </span>
    </label>
  );
});
PlanRadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
