import { Text } from "@/components/custom-components";
import { ArrowTopRightIcon } from "@radix-ui/react-icons";
import * as React from "react";
import { ComponentProps } from "react";
import { Ad, DiscoverGridView } from "@/components/templates/DiscoverGridView";
import { trpc } from "@/utils/trpc.ts";
import { Link } from "@tanstack/react-router";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";

type BoardProps = ComponentProps<"div"> & {
  board: {
    name: string;
    id: string;
    description: string | null;
    isAdInBoard?: boolean;
  };
};

export const BoardCollection = React.forwardRef<HTMLDivElement, BoardProps>(
  ({ board, ...props }, ref) => {
    const { data, isLoading, error } = trpc.getBoardDetails.useQuery({
      boardId: board.id,
    });

    if (error) {
      return <ErrorDisplay />;
    }

    if (isLoading) {
      return (
        <div
          className={"flex justify-center items-center w-full h-full m-auto"}
        >
          <Loader />
        </div>
      );
    }

    return (
      <div ref={ref} {...props} className={"h-full"}>
        <div className={"flex justify-between gap-5 mb-[1.25rem]"}>
          <Text
            size={"xl"}
            weight={"semibold"}
            className={
              "text-lg lg:text-2xl bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
            }
          >
            {board.name}
          </Text>
          <Link
            to={`/feeds/boards/$boardID/ads`}
            params={{ boardID: board.id }}
            className={"flex gap-3 text-[#7F7F7F]"}
          >
            <Text className={"w-auto text-nowrap hover:text-black"}>
              View collection
            </Text>
            <ArrowTopRightIcon className={"w-[1.5rem] h-[1.5rem]"} />
          </Link>
        </div>
        <div className={"overflow-y-hidden h-full"}>
          {data && (
            <>
              {data.ads.length === 0 ? (
                <div className={"h-full flex justify-center items-center"}>
                  <p>No ad added to this board</p>
                </div>
              ) : (
                data.ads.length > 0 && (
                  <DiscoverGridView data={data.ads.slice(0, 4) as Ad[]} />
                )
              )}
            </>
          )}
        </div>
      </div>
    );
  },
);
