export default function EmailIcon({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.0727 1.09091H9.92725C10.4672 1.09091 10.9091 1.64318 10.9091 2.31818V9.68182C10.9091 10.3568 10.4672 10.9091 9.92725 10.9091H2.0727C1.5327 10.9091 1.09088 10.3568 1.09088 9.68182V2.31818C1.09088 1.64318 1.5327 1.09091 2.0727 1.09091Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9091 2.31818L5.99997 6.61363L1.09088 2.31818"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
