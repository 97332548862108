import { createFileRoute, Link } from "@tanstack/react-router";

import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { Button, PlanTag, Text } from "@/components/custom-components";
import { Divider } from "@/components/custom-components/Divider";
import { ShareIcon } from "lucide-react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useElementSize } from "@/hooks/useElementSize.tsx";
import { toast } from "sonner";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { cloneElement, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { SidebarButton } from "@/components/templates/Sidebar";
import FullAccessPromptDialog from "@/components/FullAccessPromptDialog.tsx";
import AdIcon from "@/assets/AdIcon.tsx";
import { LockClosedIcon } from "@radix-ui/react-icons";
import ExpertIcon from "@/assets/ExpertIcon.tsx";

type BoardCollectionDataProps = {
  collections: CollectionDataProps[];
  scrollRef?: (node?: Element | null | undefined) => void;
};

type CollectionDataProps = {
  atID: string;
  Title: string;
  "Ad Templates": string[];
  adCount: number;
  images?: {
    id: string;
    width: number;
    height: number;
    url: string;
    filename: string;
    size: number;
    type: string;
    thumbnails: {
      small: {
        url: string;
        width: number;
        height: number;
      };
      large: {
        url: string;
        width: number;
        height: number;
      };
      full: {
        url: string;
        width: number;
        height: number;
      };
    };
  }[];
};

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/collections/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const { sideBarOpen } = Route.useSearch();

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const [allData, setAllData] = useState<CollectionDataProps[] | undefined>(
    undefined,
  );

  const { data, fetchNextPage, isLoading, isError, isRefetching } =
    trpc.getCollectionsWithImages.useInfiniteQuery(
      { limit: 10 },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (!inView || !data || !allData || !allData.length) return;
    if (data.pages[data.pages.length - 1].collections.length === 0) {
      return;
    }
    fetchNextPage();
  }, [inView, data, allData, fetchNextPage]);

  useEffect(() => {
    if (!data) return;

    setAllData(() => undefined);
    const records = [] as CollectionDataProps[];
    for (const page of data.pages) {
      records.push(...page.collections);
    }
    setAllData(() => records);
  }, [data]);

  if (isError) {
    return <ErrorDisplay />;
  }

  if (isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      {upgradeDialogOpen && (
        <FullAccessPromptDialog
          upgradeDialogOpen={upgradeDialogOpen}
          setUpgradeDialogOpen={() => setUpgradeDialogOpen(false)}
        />
      )}
      <div className={"px-5 md:px-5 md:py-0"}>
        <div className={"bg-white sticky top-0 z-10"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Collections
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
            <div>
              <div className={"flex gap-3 lg:gap-5 items-center mb-5"}>
                <span className={"hidden lg:flex"}>Show:</span>
                <div
                  className={
                    "flex gap-2 lg:gap-5 items-center overflow-x-auto w-[90vw]"
                  }
                >
                  {[
                    {
                      name: "Templates",
                      link: "/feeds/collections",
                      icon: <AdIcon />,
                    },
                    {
                      name: "Experts",
                      link: "/feeds/collections/experts",
                      icon: <ExpertIcon />,
                    },
                  ].map((item) => {
                    const isActive = location.pathname === item.link;
                    const iconColor = isActive ? "white" : "black";
                    return (
                      <Link
                        onClick={(e) => {
                          // If a user navigates before loading is complete, choose to just navigate
                          // This optimizes for users who ARE allowed access and doesn't force them to
                          // wait until we have the data back from the server if they have access

                          if (
                            item.name === "Experts" &&
                            !isLoadingPermission &&
                            !permissionData?.userCanAccessEverything
                          ) {
                            setUpgradeDialogOpen(true);
                            e.preventDefault();
                          }
                        }}
                        to={item.link}
                        search={(d) => ({
                          ...d,
                          cacheBuster: Math.random(),
                          orderFilter: "Random",
                          sideBarOpen: false,
                        })}
                        className="flex gap-2.5 justify-start items-center h-12 lg:h-10 px-3 lg:px-5 py-4 border border-black rounded-md text-[0.875rem] font-normal"
                        activeProps={{
                          className:
                            "border-0 border-transparent rounded-lg bg-gradient-to-b from-[#A259FF] to-[#613599] text-white",
                        }}
                        activeOptions={{
                          exact: true,
                          includeSearch: false,
                        }}
                        key={item.name}
                      >
                        <span>
                          <span>
                            {cloneElement(item.icon, { color: iconColor })}
                          </span>
                        </span>
                        <Text
                          className={"w-auto text-nowrap"}
                          weight={"medium"}
                          data-testid={item.name}
                        >
                          {item.name}
                        </Text>
                        {item.name === "Experts" && (
                          <>
                            {!isLoadingPermission &&
                              !permissionData?.userCanAccessEverything && (
                                <PlanTag label={<LockClosedIcon />} />
                              )}
                          </>
                        )}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
          </div>
        </div>

        {data && allData && allData.length > 0 && (
          <CollectionGridView collections={allData} scrollRef={scrollRef} />
        )}
      </div>
    </>
  );
}

const CollectionGridView = (props: BoardCollectionDataProps) => {
  return (
    <div className={"grid lg:grid-cols-2 gap-5 mb-10"}>
      {props.collections && props.collections.length > 0
        ? props.collections.map((item) => (
            <CollectionItem key={item.atID} data={item} />
          ))
        : props.collections.length === 0 && (
            <div
              className={
                "h-full w-full lg:col-span-2 flex justify-center items-center"
              }
            >
              <p>No collections created</p>
            </div>
          )}
      <div className={"relative"}>
        <div
          className={
            "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
          }
          ref={props.scrollRef}
        ></div>
      </div>{" "}
      <div className={"relative"}>
        <div
          className={
            "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
          }
          ref={props.scrollRef}
        ></div>
      </div>
    </div>
  );
};

const CollectionItem = ({ data }: { data: CollectionDataProps }) => {
  const [, copyToClipboard] = useCopyToClipboard();

  // Columns needs to be controlled by the width of the container.
  // So we set the width of each masonry column to a fraction of the container width
  const columns = 3;
  const [squareRef] = useElementSize();

  const gutterWidth = 18;

  return (
    <div
      className={
        "border-[0.031rem] rounded-[0.5rem] border-[#B4B4B4] bg-[#F5F5F5]"
      }
    >
      <div
        className={
          "py-[0.75rem] px-[0.563rem] flex justify-between items-center "
        }
      >
        <div className={`flex gap-[0.625rem] items-center`}>
          <div className={"flex items-center gap-2.5"}>
            <h4
              className={`truncate font-[500] text-[0.938rem] leading-[1.134rem] text-black`}
            >
              {data.Title}
            </h4>
            <span className={"text-[#7F7F7F]"}>{data.adCount} Ads</span>
          </div>
        </div>
        <div>
          <Link
            to={"/feeds/collections/$collectionID"}
            params={{ collectionID: data.atID }}
          >
            <Button className={"font-medium text-nowrap px-2.5"}>
              View Collection
            </Button>
          </Link>
        </div>
      </div>
      <div
        className={
          "p-5 bg-white border-y border-[#B4B4B4] h-[23.75rem] overflow-hidden"
        }
      >
        <div ref={squareRef} className={"relative w-full"}>
          {data.images && (
            <div>
              <ResponsiveMasonry
                columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
              >
                <Masonry gutter={gutterWidth + "px"}>
                  {data.images.map((ad) => (
                    <div key={ad.id} className={"bg-gray-200 rounded-lg"}>
                      <img
                        src={ad.thumbnails.large.url}
                        alt={""}
                        className={"w-full object-contain rounded-lg"}
                      />
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          )}
        </div>
      </div>
      <div className={"flex justify-end gap-[0.9375rem] px-2 py-[0.625rem]"}>
        <span
          onClick={() => {
            copyToClipboard(
              `${window.location.origin}/feeds/collections/${data.atID}`,
            );
            toast("Copied!");
          }}
          className={"border rounded-md border-black p-2.5 cursor-pointer"}
        >
          <ShareIcon className={"w-[24px] h-[24px]"} />
        </span>
      </div>
    </div>
  );
};
