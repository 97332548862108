export default function AdIcon({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.72889 1H2.09111C1.48851 1 1 1.48851 1 2.09111V9.72889C1 10.3315 1.48851 10.82 2.09111 10.82H9.72889C10.3315 10.82 10.82 10.3315 10.82 9.72889V2.09111C10.82 1.48851 10.3315 1 9.72889 1Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00058 4.81892C4.45254 4.81892 4.81892 4.45254 4.81892 4.00058C4.81892 3.54863 4.45254 3.18225 4.00058 3.18225C3.54863 3.18225 3.18225 3.54863 3.18225 4.00058C3.18225 4.45254 3.54863 4.81892 4.00058 4.81892Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.82 7.54669L8.09218 4.81891L2.09106 10.82"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
