import { createFileRoute } from "@tanstack/react-router";
import { SignUpModal } from "@memberstack/react";

export const Route = createFileRoute("/sign-up")({
  component: SignUp,
});

function SignUp() {
  return (
    <div className={"min-h-screen"}>
      <SignUpModal
        onSuccess={async ({ data }) => {
          if (data) {
            window.location.href = "/plans";
          }
        }}
      />
    </div>
  );
}
