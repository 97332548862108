import {
  LandingAdCard,
  LandingFeedAdCard,
} from "@/components/templates/LandingAdCard";
import { z } from "zod";
import { GetAdArgs } from "../../../../../server/rpc";
import { useAuth } from "@memberstack/react";
import { useNavigate } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";

type props = {
  Cards: GenericCardProps[];
  HasAccess: boolean;
  type: "email" | "landing-page";
};

export type GenericCardProps = {
  Screenshot: string;
  ID: string;
};

export const LandingAdGridView = (props: props) => {
  return (
    <div
      className={"grid grid-cols-1 lg:grid-cols-3 gap-[2.6875rem] px-5 pt-5"}
    >
      {props?.Cards?.map((card) => (
        <LandingAdCard
          key={card.ID}
          adData={{
            Screenshot: card.Screenshot,
            ID: card.ID,
          }}
          type={props.type}
        />
      ))}
    </div>
  );
};

type publicProps = z.infer<typeof GetAdArgs>;

export const LandingFeedGridViewPublic = (props: publicProps) => {
  // If a user is logged in, they don't need to see this screen
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  if (isLoggedIn) {
    navigate({
      to: "/feeds/inspiration/landing-pages/$adID",
      params: {
        adID: props.adId.toString(),
      },
    });
  }

  const { data, isError, isLoading, isRefetching } =
    trpc.previewLanderInFeed.useQuery(
      {
        landerId: props.adId,
      },
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!data || isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={
        "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[2.6875rem]"
      }
    >
      {data &&
        data.map((card, index) => (
          <LandingFeedAdCard
            key={card.landerId}
            adData={card}
            IsPublic={true}
            IsBlurred={index !== 0}
          />
        ))}
    </div>
  );
};
