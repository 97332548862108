export default function DotsExpandIcon({
  color = "black",
}: {
  color?: string;
}) {
  return (
    <svg
      width="14"
      height="4"
      viewBox="0 0 14 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 2.5C7.41421 2.5 7.75 2.16421 7.75 1.75C7.75 1.33579 7.41421 1 7 1C6.58579 1 6.25 1.33579 6.25 1.75C6.25 2.16421 6.58579 2.5 7 2.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 2.5C12.6642 2.5 13 2.16421 13 1.75C13 1.33579 12.6642 1 12.25 1C11.8358 1 11.5 1.33579 11.5 1.75C11.5 2.16421 11.8358 2.5 12.25 2.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
