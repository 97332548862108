import { createFileRoute, Link } from "@tanstack/react-router";
import { TopBar } from "@/components/topBar.tsx";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button.tsx";
import { memberstack } from "@/App.tsx";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";

export const Route = createFileRoute("/library/experts/")({
  component: Experts,
});

type expertProduct = {
  title: string;
  description: string;
  url: string;
  ctaButton: string;
  priceID: string;
  planID: string[];
  imageLink: string;
};

const ExpertProducts = [
  {
    title: "Austin Rabin",
    url: "/library/experts/Austin%20Rabin",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expoert-austin.png",
  },
  {
    title: "Rabah Rahil",
    url: "/library/experts/Rabah%20Rahil",
    description:
      "Spent millions of dollars at an agency, ran his own agency and most recently became CMO at Triple Whale.",
    ctaButton: "Get Rabah's Creatives",
    priceID: "prc_rabah-s-pack-mr2r0nth",
    planID: [
      "pln_rabah-s-pack-cp2q0nb6",
      "pln_rabah-s-creative-pack-obh70opq",
      "pln_rabah-legacy-pack-15fb0o8e",
    ],
    imageLink: "/expert-rabah-r.png",
  },
  {
    title: "Nick Shackelford",
    url: "/library/experts/Nick%20Shackelford",
    description:
      "A pioneer in Facebook Ads for DTC brands. Co-founder of: Structured, Konstant Kreative & Brez.",
    ctaButton: "Get Nick's Creatives",
    priceID: "prc_nick-s-pack-s31n0nzl",
    planID: [
      "pln_nick-s-pack-lu1m0npx",
      "pln_nick-s-creative-pack-s6vn0n68",
      "pln_nick-s-pack-legacy-8ztv0nhz",
    ],
    imageLink: "/expert-nick-s.png",
  },
  {
    title: "Ash Melwani",
    url: "/library/experts/Ash%20Melwani",
    description:
      "Co-Founder & CMO of Obvi, one of the world’s fastest growing health & nutrition brands.",
    ctaButton: "Get Ash's Creatives",
    priceID: "prc_ash-s-pack-sk2f0nrf",
    planID: [
      "pln_ash-s-pack-i41d01o8",
      "pln_ash-legacy-pack-gef30o1t",
      "pln_ash-s-creative-pack-gsh50oot",
      "",
    ],
    imageLink: "/expert-ash-m.png",
  },
  {
    title: "Barry Hott",
    url: "/library/experts/Barry%20Hott",
    description:
      "Barry is a Growth Marketing Consultant & Advisor know for his expertise in growth and creative strategies.",
    ctaButton: "Get Barry's Creatives",
    priceID: "prc_barry-s-creative-pack-lkx40153",
    planID: [
      "pln_barry-s-pack-rd2w0ni3",
      "pln_barry-hot-expert-pack-u26u0hn0",
      "pln_barry-legacy-pack-yyf20our",
    ],
    imageLink: "/expert-barry-h.png",
  },
  {
    title: "Sarah Levinger",
    url: "/library/experts/Sarah%20Levinger",
    description:
      "Sarah is a Forbes-featured consumer behavior analyst, creative strategist, and performance creative consultant.",
    ctaButton: "Get Sarah's Creatives",
    priceID: "prc_sara-s-creative-pack-hyvl0ns4",
    planID: [
      "pln_sarah-s-creative-pack-puvk0nz1",
      "pln_sarah-legacy-pack-ntu60nuz",
      "pln_sarah-s-pack-mv1601hd",
    ],
    imageLink: "/expert-sarah-l.png",
  },
  {
    title: "Dara Denney",
    url: "/library/experts/Dara%20Denney",
    description:
      "Dara is a performance creative consultant and creator. She’s led creative and growth teams for the last 5 years.",
    ctaButton: "Get Dara's Creatives",
    priceID: "prc_dara-s-pack-kp1l01dv",
    planID: [
      "pln_dara-s-pack-hd1k015b",
      "pln_dara-legacy-pack-8gf40oh3",
      "pln_dara-s-creative-pack-4wvp0ncz",
    ],
    imageLink: "/expert-dara-d.png",
  },
];

const ExpertPackPlanID = "pln_expert-pack-cvuf01gf";

function Experts() {
  const { data, error, isLoading } = trpc.me.useQuery(null);
  const [activePlans, setActivePlans] = useState<string[] | undefined>(
    undefined
  );

  useEffect(() => {
    const activePlans = [] as string[];
    if (!data) {
      return;
    }
    data.data.planConnections.map((x) => {
      if (typeof x === "string") {
        return;
      }
      if (x.active) {
        activePlans.push(x.planId);
      }
    });
    setActivePlans(() => activePlans);
  }, [data]);

  if (isLoading || !activePlans) {
    return (
      <div className={"flex justify-center items-center w-full h-full m-auto"}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <ErrorDisplay />;
  }

  return (
    <>
      <div className={"sticky top-0 z-10 px-2"}>
        <div className={"bg-white px-8 pt-6 pb-2"}>
          <TopBar Title={"One-time expert packs"} />
        </div>
        <div className={"h-5 bg-gradient-to-b from-white/100 to-white/0"}></div>
      </div>

      <div className={"px-10 pb-10"}>
        <div
          className={
            "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-5 gap-y-16"
          }
        >
          {ExpertProducts.map((x) => {
            return <ExpertPlan activePlans={activePlans} expert={x} />;
          })}
        </div>
      </div>
    </>
  );
}

type expertPlanProps = {
  expert: expertProduct;
  activePlans: string[];
};

const ExpertPlan = (props: expertPlanProps) => {
  return (
    <div className={"group bg-smoke border-b-2 border-b-lightgray rounded-xl"}>
      <div className={"relative rounded-b-xl overflow-hidden"}>
        <div
          className={
            "absolute bottom-0 group-hover:-bottom-12 left-0 right-0 h-12 rounded-b-xl bg-gradient-to-b from-black/0 to-black/90 transition-all duration-700 ease-in-out"
          }
        ></div>
        <img loading={"eager"} className={""} src={props.expert.imageLink} />
      </div>
      <div
        className={
          "flex flex-col items-center text-center px-8 pt-6 pb-8 group-hover:scale-110 transition-transform duration-700 ease-in-out"
        }
      >
        <div className={"text-2xl font-semibold mb-4"}>
          {props.expert.title}
        </div>
        <div className={"text-black/80 leading-tight mb-4"}>
          {props.expert.description}
        </div>

        {(props.expert.planID.some((planID) =>
          props.activePlans.includes(planID)
        ) ||
          props.activePlans.includes(ExpertPackPlanID)) && (
          <Link
            className={
              "h-auto bg-neutral-200 text-base text-black hover:text-white hover:bg-brandgrad bg-cover bg-center rounded-full font-semibold px-5 py-4"
            }
            to={props.expert.url}
          >
            View {props.expert.title}'s Content
          </Link>
        )}

        {props.expert.planID.every(
          (planID) => !props.activePlans.includes(planID)
        ) &&
          !props.activePlans.includes(ExpertPackPlanID) && (
            <Button
              className={
                "h-auto bg-neutral-200 text-base text-black hover:text-white hover:bg-brandgrad bg-cover bg-center rounded-full font-semibold px-5 py-4 transition-none"
              }
              onClick={async () => {
                await memberstack.purchasePlansWithCheckout({
                  priceId: props.expert.priceID,
                });
              }}
            >
              {props.expert.ctaButton}
            </Button>
          )}
      </div>
    </div>
  );
};
