import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AppHeader } from "@/components/templates/AppHeader";
import { Button, Text } from "@/components/custom-components";
import { z } from "zod";
import { useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";
import OnboardingComplete from "@/components/onboarding/OnboardingComplete.tsx";
import ClientUseCaseBrief from "@/components/onboarding/ClientUseCaseBrief.tsx";
import ClientCompanyType from "@/components/onboarding/ClientCompanyType.tsx";
import ClientLeadSource from "@/components/onboarding/ClientLeadSource.tsx";
import ClientUserRole from "@/components/onboarding/ClientUserRole.tsx";
import { useEffect } from "react";
import { ProtectedArea } from "@/components/protectedArea.tsx";
import { Divider } from "@/components/custom-components/Divider";

type GetStartedProps = {
  step?: string;
};

export type UseCaseType =
  | "find-ad-inspiration"
  | "find-ad-creative-templates"
  | "craft-high-performance-copy"
  | "create-new-assets";

export type RoleType =
  | "founder"
  | "c-suite"
  | "creative-strategist"
  | "specialized-consultant";

export type CompanyType = "agency" | "brand" | "freelance" | "other";

export type LeadType =
  | "social-media"
  | "word-of-mouth"
  | "email"
  | "paid-search"
  | "youtube"
  | "other";

export const useCases = [
  { id: 1, label: "Find ad inspiration", value: "find-ad-inspiration" },
  {
    id: 2,
    label: "Find ad creative templates",
    value: "find-ad-creative-templates",
  },
  {
    id: 3,
    label: "Craft high-performance copy",
    value: "craft-high-performance-copy",
  },
  { id: 4, label: "Create new assets", value: "create-new-assets" },
];

export const roles = [
  { id: 1, label: "Founder or Operator", value: "founder" },
  { id: 2, label: "C-suite or Director", value: "c-suite" },
  { id: 3, label: "Creative Strategist", value: "creative-strategist" },
  { id: 4, label: "Specialized Consultant", value: "specialized-consultant" },
];

export const companyTypes = [
  { id: 1, label: "Agency", value: "agency" },
  { id: 2, label: "Brand", value: "brand" },
  { id: 3, label: "Freelance or Consultant", value: "freelance" },
  { id: 4, label: "Other", value: "other" },
];

export const leads = [
  { id: 1, label: "Social Media", value: "social-media" },
  { id: 2, label: "Word of Mouth", value: "word-of-mouth" },
  { id: 3, label: "Email", value: "email" },
  { id: 4, label: "Paid Search", value: "paid-search" },
  { id: 5, label: "Youtube", value: "youtube" },
  { id: 6, label: "Other", value: "other" },
];

export const Route = createFileRoute("/get-started")({
  component: GetStarted,
  validateSearch: (search: Record<string, unknown>): GetStartedProps => {
    return {
      step: search.step as string | undefined,
    };
  },
});

export const OnboardingFormSchema = z.object({
  use_cases: z
    .enum([
      "find-ad-inspiration",
      "find-ad-creative-templates",
      "craft-high-performance-copy",
      "create-new-assets",
    ])
    .optional(),
  roles: z
    .enum([
      "founder",
      "c-suite",
      "creative-strategist",
      "specialized-consultant",
    ])
    .optional(),
  company_types: z.enum(["agency", "brand", "freelance", "other"]).optional(),
  leads: z
    .enum([
      "social-media",
      "word-of-mouth",
      "email",
      "paid-search",
      "youtube",
      "other",
    ])
    .optional(),
});

export type SectionProps = {
  form: UseFormReturn<z.infer<typeof OnboardingFormSchema>>;
};

function GetStarted() {
  const { step } = Route.useSearch();
  const navigate = useNavigate();

  const {
    data: onboardCompletedData,
    isLoading,
    error,
  } = trpc.hasCompletedOnboarding.useQuery();

  const {
    data: onboardingResponsesData,
    isLoading: isLoadingOnboardingResponses,
    error: errorOnboardingResponses,
  } = trpc.getOnboardingSteps.useQuery();

  const form = useForm<z.infer<typeof OnboardingFormSchema>>({
    resolver: zodResolver(OnboardingFormSchema),
    defaultValues: {
      use_cases: onboardingResponsesData?.brief || undefined,
      roles: onboardingResponsesData?.role || undefined,
      company_types: onboardingResponsesData?.company || undefined,
      leads: onboardingResponsesData?.lead || undefined,
    },
  });

  useEffect(() => {
    if (onboardingResponsesData) {
      form.setValue("use_cases", onboardingResponsesData.brief as UseCaseType);
      form.setValue("roles", onboardingResponsesData.role as RoleType);
      form.setValue(
        "company_types",
        onboardingResponsesData.company as CompanyType,
      );
      form.setValue("leads", onboardingResponsesData.lead as LeadType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingResponsesData]);

  // Users are redirected to login when not logged in
  if (error && error?.message === "UNAUTHORIZED") {
    return (window.location.href = "/login");
  }

  if (error || errorOnboardingResponses) {
    return <ErrorDisplay />;
  }

  if (isLoading || isLoadingOnboardingResponses) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  if (step) {
    if (step.toLowerCase() === "ready") {
      return <OnboardingComplete />;
    }
    return (
      <ProtectedArea>
        <div className={"min-h-screen"}>
          <div className={"bg-white px-5 lg:px-8 lg:pb-2"}>
            <AppHeader Title={""} IsPublic={true} IsDashboard={false} />
          </div>
          <Divider className={"my-0 hidden md:flex"} />
          <div className={"grid lg:grid-cols-2 h-full flex-1"}>
            <div
              className={"bg-onboardgrad bg-cover bg-no-repeat hidden lg:block"}
            ></div>
            <div
              className={
                "min-h-screen bg-brandgrad bg-center lg:bg-none bg-cover bg-no-repeat px-5 py-10 lg:py-20 lg:px-[7.5rem]"
              }
            >
              {step.toLowerCase() === "brief" ? (
                <ClientUseCaseBrief form={form} />
              ) : step.toLowerCase() === "company" ? (
                <ClientCompanyType form={form} />
              ) : step.toLowerCase() === "lead" ? (
                <ClientLeadSource form={form} />
              ) : (
                step.toLowerCase() === "role" && <ClientUserRole form={form} />
              )}
            </div>
          </div>
        </div>
      </ProtectedArea>
    );
  }

  if (onboardCompletedData && !(isLoading || isLoadingOnboardingResponses)) {
    window.location.href = "/feeds/templates";
  }

  return (
    <ProtectedArea>
      <div className={"min-h-screen"}>
        <div className={"bg-white px-5 lg:px-8 lg:pb-2"}>
          <AppHeader Title={""} IsPublic={true} IsDashboard={false} />
        </div>
        <div
          className={
            "bg-brandgrad bg-no-repeat bg-center bg-cover h-screen flex-1 flex flex-col justify-center items-center"
          }
        >
          <div
            className={
              "flex flex-col justify-center items-center text-white text-center"
            }
          >
            <Text
              className={"text-[2rem] lg:text-[3rem] mb-5"}
              align={"center"}
              as={"h2"}
            >
              Your creative <br className={"lg:hidden"} />
              process,
              <br />
              <span className={"italic font-[700]"}>reimagined</span>
            </Text>
            <p className={"font-medium lg:text-lg text-center"}>
              Let's optimise your experience (10s)
            </p>
            <Button
              onClick={() => {
                navigate({ to: "/get-started", search: { step: "brief" } });
              }}
              className={"min-w-[13.125rem] text-xl mt-10"}
              variant={"secondary"}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </ProtectedArea>
  );
}
