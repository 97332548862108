import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Tags } from "../../../shared/airtableGet.ts";
import { z } from "zod";
import { Button, Text } from "@/components/custom-components";
import { AdsViewPublic } from "@/components/AdsView.tsx";

type SearchParams = {
  Tags?: z.infer<typeof Tags>;
};

export const Route = createFileRoute("/share/static/$adID")({
  component: SharedAd,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as z.infer<typeof Tags> | undefined;
    return {
      Tags: tag,
    };
  },
});

function SharedAd() {
  const navigate = useNavigate({ from: "/share/static/$adID" });
  const { adID } = Route.useParams();
  const { cacheBuster } = Route.useSearch();
  if (!cacheBuster) {
    navigate({
      search: (old) => {
        return {
          ...old,
          cacheBuster: Math.random(),
        };
      },
      params: (old) => {
        return {
          ...old,
        };
      },
    });
  }
  return (
    <div className={"relative h-[97vh]"}>
      <div className={"flex flex-col md:flex-row md:items-start px-5 lg:px-10"}>
        <div className={"pt-10 lg:pt-5 pb-[1.875rem]"}>
          <Text weight={"semibold"} size={"xl"}>
            Advert Preview
          </Text>
        </div>
      </div>
      <div
        className={
          "fixed z-50 flex justify-center items-end left-0 right-0 bottom-[2.5rem]"
        }
      >
        <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
          <Button
            className={"font-medium py-4 px-[2.5rem]"}
            variant={"gradient"}
          >
            Sign up to see 250+ quality ads daily
          </Button>
        </a>
      </div>
      <AdsViewPublic adID={adID} />
    </div>
  );
}
