import { createFileRoute } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import { useEffect, useState } from "react";
import { Loader } from "@/components/custom-components/Loader";
import { AppHeader } from "@/components/templates/AppHeader";
import { Divider } from "@/components/custom-components/Divider";
import { Button } from "@/components/custom-components";
import { PRO_PLAN, STANDARD_PLAN } from "@/utils/data/plans.ts";
import { ProtectedArea } from "@/components/protectedArea.tsx";

export const Route = createFileRoute("/plans")({
  component: Plans,
});

function Plans() {
  const [selected, setSelected] = useState<string | null>(null);

  // get the checkout url based on the selected plan
  const { data, isLoading, isRefetching, refetch } =
    trpc.getNewCheckoutUrl.useQuery(
      { plans: selected ? [selected] : [] },
      { enabled: false, refetchOnWindowFocus: false },
    );

  useEffect(() => {
    if (data && !isLoading && !isRefetching) {
      window.location.href = data;
    }
  }, [data, isLoading, isRefetching]);

  const initiatePayment = () => {
    refetch();
  };

  useEffect(() => {
    if (data && !isLoading && !isRefetching) {
      window.location.href = data;
    }
  }, [data, isLoading, isRefetching]);

  if (isLoading || isRefetching) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <ProtectedArea>
      <div className={"min-h-screen"}>
        <div className={"bg-white px-5 lg:px-8 lg:pb-2"}>
          <AppHeader Title={""} IsPublic={true} IsDashboard={false} />
        </div>
        <Divider className={"my-0 hidden md:flex"} />
        <div className={"grid lg:grid-cols-2 h-full flex-1"}>
          <div
            className={"bg-signupcover bg-cover bg-no-repeat hidden lg:block"}
          ></div>
          <div
            className={
              "min-h-screen bg-white bg-center lg:bg-none bg-cover bg-no-repeat px-5 py-10 lg:py-20 lg:px-[8.125rem]"
            }
          >
            <div className={"flex justify-center"}>
              <h2
                className={
                  "text-[2rem] text-center font-semibold bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Select your plan
              </h2>
            </div>
            <div className={"mt-[6.875rem] grid gap-5"}>
              {STANDARD_PLAN && (
                <div
                  className={
                    "rounded-lg p-5 border border-black cursor-pointer"
                  }
                  onClick={() => setSelected(STANDARD_PLAN.plan_id)}
                >
                  <div className={"flex gap-5 justify-between"}>
                    <div
                      className={
                        "flex items-center gap-2 text-[0.875rem] font-medium"
                      }
                    >
                      <span
                        className={`peer h-[1.25rem] w-[1.25rem] shrink-0 rounded-full border-2 border-[#7324A2] ring-offset-background ${selected == STANDARD_PLAN.plan_id ? "bg-checkgrad" : "focus-visible:outline-checkgrad"}`}
                      ></span>
                      <span>{STANDARD_PLAN.name}</span>
                      <span
                        className={
                          "bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                        }
                      >
                        {STANDARD_PLAN.price}
                      </span>
                    </div>
                    <div>
                      <span
                        className={
                          "bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                        }
                      >
                        7-day trial
                      </span>
                    </div>
                  </div>
                  <div className={"mt-2.5 mb-3"}>
                    <p
                      className={"text-[#7F7F7F] text-[0.6875rem] font-medium"}
                    >
                      {STANDARD_PLAN.description}
                    </p>
                  </div>
                  <div className={"grid grid-cols-2 gap-2.5"}>
                    {STANDARD_PLAN.features.map((item) => (
                      <span key={item} className={"flex gap-2.5"}>
                        <img
                          src={"/icons/gradient-check.svg"}
                          alt={""}
                          className={""}
                        />
                        <span
                          className={"font-medium text-black text-[0.875rem]"}
                        >
                          {item}
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              )}
              {PRO_PLAN && (
                <div
                  className={
                    "rounded-lg p-5 border border-black cursor-pointer"
                  }
                  onClick={() => setSelected(PRO_PLAN.plan_id)}
                >
                  <div className={"flex gap-5 justify-between"}>
                    <div
                      className={
                        "flex items-center gap-2 text-[0.875rem] font-medium"
                      }
                    >
                      <span
                        className={`peer h-[1.25rem] w-[1.25rem] shrink-0 rounded-full border-2 border-[#7324A2] ring-offset-background ${selected == PRO_PLAN.plan_id ? "bg-checkgrad" : "focus-visible:outline-checkgrad"}`}
                      ></span>
                      <span>{PRO_PLAN.name}</span>
                      <span
                        className={
                          "bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                        }
                      >
                        {PRO_PLAN.price}
                      </span>
                    </div>
                    <div>
                      <span
                        className={
                          "bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                        }
                      >
                        7-day trial
                      </span>
                    </div>
                  </div>
                  <div className={"mt-2.5 mb-3"}>
                    <p className={"text-[#7F7F7F] text-[0.6875rem]"}>
                      {PRO_PLAN.description}
                    </p>
                  </div>
                  <div className={"grid grid-cols-2 gap-2.5"}>
                    {PRO_PLAN.features.map((item) => (
                      <span key={item} className={"flex gap-2.5 items-start"}>
                        <img
                          src={"/icons/gradient-check.svg"}
                          alt={""}
                          className={""}
                        />
                        <span
                          className={"font-medium text-black text-[0.875rem]"}
                        >
                          {item}
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className={"mt-10 flex items-center gap-5"}>
              <Button
                type={"button"}
                className={"min-w-[13.125rem] text-xl"}
                variant={"secondary"}
                onClick={() => selected && initiatePayment()}
                disabled={isLoading || isRefetching}
              >
                {isLoading || isRefetching ? "Submitting..." : "Next"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ProtectedArea>
  );
}
