import { Link } from "@tanstack/react-router";
import { Text } from "@/components/custom-components";
import { ArrowTopRightIcon } from "@radix-ui/react-icons";

type BrandCollectionDataProps = {
  data: BrandDataProps[];
};

type BrandDataProps = {
  brandImage: string | null;
  brandName: string;
  fbPageId: string;
};

export const BrandsGridView = ({ data }: BrandCollectionDataProps) => {
  return (
    <div className={"grid grid-cols-1 lg:grid-cols-2 gap-x-20 px-5 lg:px-0"}>
      {data &&
        data.map((item) => (
          <div
            key={item.brandName}
            className={
              "py-[0.625rem] md:py-[20px] flex justify-between gap-5 w-full border-b border-[#A259FF40]"
            }
          >
            <Link
              to={`/feeds/brands/$brandID`}
              params={{ brandID: item.fbPageId }}
            >
              <div className={"flex gap-5 items-center"}>
                <img
                  className={"rounded-full w-10 h-10 bg-[#A259FF]"}
                  src={item.brandImage ? item.brandImage : "/bg_gradient.jpg"}
                  alt={item.brandName}
                />
                <div>
                  <Text className={"text-base lg:text-xl"} weight={"semibold"}>
                    {item.brandName}
                  </Text>
                  <div
                    className={
                      "flex lg:hidden mt-[0.625rem] items-center gap-10"
                    }
                  >
                    <Link
                      to={`/feeds/brands/$brandID`}
                      params={{ brandID: item.fbPageId }}
                    >
                      <div className={"flex gap-3 items-start text-[#7F7F7F]"}>
                        <Text className={"w-auto text-nowrap hover:text-black"}>
                          view ads
                        </Text>
                        <ArrowTopRightIcon
                          className={"w-[1.2rem] h-[1.2rem]"}
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </Link>
            <div className={"hidden lg:flex items-center gap-10"}>
              <Link
                to={`/feeds/brands/$brandID`}
                params={{ brandID: item.fbPageId }}
              >
                <div className={"flex gap-3 items-center text-[#7F7F7F]"}>
                  <Text className={"w-auto text-nowrap hover:text-black"}>
                    view ads
                  </Text>
                  <ArrowTopRightIcon className={"w-[1.5rem] h-[1.5rem]"} />
                </div>
              </Link>
            </div>
          </div>
        ))}
    </div>
  );
};
