import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { cloneElement, useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  AccordionData,
  AdFilter,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
} from "@/components/custom-components";
import { Divider } from "@/components/custom-components/Divider";
import { SidebarButton } from "@/components/templates/Sidebar";
import AdIcon from "@/assets/AdIcon.tsx";
import LanderIcon from "@/assets/LanderIcon.tsx";
import {
  LandingFeedAdCard,
  LandingFeedProps,
} from "@/components/templates/LandingAdCard";
import { z } from "zod";
import { landerFeedOrderFilter } from "../../../shared/airtableGet.ts";

type SearchParams = {
  categories?: string;
  sideBarOpen?: boolean;
};

type FilterOption = {
  title: string;
  counter: number;
  optionItems: { label: string; value: boolean }[];
};

export type SelectedFilters = {
  categories?: string[];
};

export const Route = createFileRoute("/feeds/inspiration/landing-pages/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const categories = search?.categories as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      categories,
      sideBarOpen,
    };
  },
});

function All() {
  const navigate = useNavigate();

  const {
    categories: queryCategories,
    sideBarOpen,
    orderFilter,
  } = Route.useSearch();

  const [allData, setAllData] = useState<LandingFeedProps[] | undefined>(
    undefined,
  );
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [cursor, setCursor] = useState(1);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    categories: queryCategories ? queryCategories.split(",") : undefined,
  });

  // Get the page categories to be passed to the filter
  const { data: pageCategories } = trpc.getPageCategories.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (pageCategories) {
      setFilterOptions([
        {
          title: "Categories",
          counter: 0,
          optionItems: pageCategories.map((i) => ({
            label: i.name,
            value: false,
          })),
        },
      ]);
    }
  }, [pageCategories]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => {
      const updatedOptionItems = option.optionItems.map((item) => ({
        ...item,
        value:
          option.title === "Categories" &&
          selectedFilters.categories?.includes(item.label),
      }));

      return {
        ...option,
        optionItems: updatedOptionItems,
      };
    });

    setFilterOptions(updatedOptions as FilterOption[]);
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: { categories?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.categories) searchParams.set("categories", params.categories);

      navigate({
        to: "/feeds/inspiration/landing-pages",
        replace: true,
        search: (old) => {
          return { ...old, ...params };
        },
      });
    },
    [navigate],
  );

  const handleOptionsChange = useCallback(
    (options: AccordionData[]) => {
      const selectedCategories: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Categories")
              selectedCategories.push(item.label);
          }
        });
      });

      setSelectedFilters({
        categories:
          selectedCategories.length > 0 ? selectedCategories : undefined,
      });

      updateQueryString({
        categories:
          selectedCategories.length > 0
            ? selectedCategories.join(",")
            : undefined,
      });
    },
    [updateQueryString],
  );

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.categories)
      params.categories = selectedFilters.categories.join(",");
  }, [selectedFilters]);

  // update cursor whenever selectedFilters change
  useEffect(() => {
    setCursor(1); // Reset cursor to 1 whenever filters change
  }, [selectedFilters]);

  const { data, fetchNextPage, isLoading, isError, isRefetching } =
    trpc.getAllLandingPages.useInfiniteQuery(
      {
        limit: 20,
        filters: {
          categories: selectedFilters.categories,
          sort: orderFilter as z.infer<typeof landerFeedOrderFilter>,
        },
      },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        initialCursor: cursor,
      },
    );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (!inView || !data || !allData || !allData.length) return;
    if (data.pages[data.pages.length - 1].results.length === 0) {
      return;
    }
    fetchNextPage();
  }, [inView, data, allData, fetchNextPage]);

  useEffect(() => {
    if (!data) return;

    setAllData(() => undefined);
    const records = [] as LandingFeedProps[];
    for (const page of data.pages) {
      records.push(...page.results);
    }
    setAllData(() => records);
  }, [data]);

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }
  return (
    <>
      <div className={""}>
        <div className={"bg-white sticky px-5 md:px-5 lg:pb-2 top-0 z-10"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Inspiration Library
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />

            <div className={"flex gap-5 items-center justify-between"}>
              <div className={"flex gap-3 lg:gap-5 items-center"}>
                <span className={"hidden lg:flex"}>Show:</span>
                <div
                  className={
                    "flex gap-2 lg:gap-5 items-center overflow-x-auto w-[90vw] lg:w-auto"
                  }
                >
                  {[
                    {
                      name: "Ads",
                      link: "/feeds/inspiration",
                      icon: <AdIcon />,
                    },
                    {
                      name: "Landers",
                      link: "/feeds/inspiration/landing-pages",
                      icon: <LanderIcon />,
                    },
                  ].map((item) => {
                    const isActive = location.pathname === item.link;
                    const iconColor = isActive ? "white" : "black";
                    return (
                      <Link
                        to={item.link}
                        search={(d) => ({
                          ...d,
                          cacheBuster: Math.random(),
                          orderFilter: "Random",
                          sideBarOpen: false,
                        })}
                        className={
                          "flex gap-2.5 justify-start items-center h-12 lg:h-10 px-3 lg:px-5 py-4 border border-black rounded-md text-[0.875rem] font-normal"
                        }
                        activeProps={{
                          className:
                            "border-0 border-transparent rounded-lg bg-gradient-to-b from-[#A259FF] to-[#613599] text-white",
                        }}
                        activeOptions={{
                          exact: true,
                          includeSearch: false,
                        }}
                        key={item.name}
                      >
                        <span>
                          <span>
                            {cloneElement(item.icon, { color: iconColor })}
                          </span>
                        </span>
                        <Text
                          className={"w-auto text-nowrap text-sm md:text-base"}
                          weight={"medium"}
                          data-testid={item.name}
                        >
                          {item.name}
                        </Text>
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className={"hidden lg:flex"}>
                <Select
                  onValueChange={(value) => {
                    navigate({
                      search: (old) => {
                        return {
                          ...old,
                          cacheBuster:
                            value === "Random" ? Math.random() : undefined,
                          orderFilter: value as z.infer<
                            typeof landerFeedOrderFilter
                          >,
                        };
                      },
                      params: (old) => {
                        return {
                          ...old,
                        };
                      },
                    });
                  }}
                >
                  <SelectTrigger className="w-32 h-10">
                    <SelectValue placeholder={orderFilter || "Random"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Random">Random</SelectItem>
                    <SelectItem value="Newest">Newest</SelectItem>
                    <SelectItem value="Popular">Popular</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className={"flex justify-between gap-3 mt-2.5"}>
              {filterOptions && filterOptions.length > 0 && (
                <div className="">
                  <AdFilter
                    initialOptions={filterOptions}
                    onOptionsChange={handleOptionsChange}
                    placeholder={"Filter"}
                  />
                </div>
              )}
              <div className={"flex lg:hidden"}>
                <Select
                  onValueChange={(value) => {
                    navigate({
                      search: (old) => {
                        return {
                          ...old,
                          cacheBuster:
                            value === "Random" ? Math.random() : undefined,
                          orderFilter: value as z.infer<
                            typeof landerFeedOrderFilter
                          >,
                        };
                      },
                      params: (old) => {
                        return {
                          ...old,
                        };
                      },
                    });
                  }}
                >
                  <SelectTrigger className="w-32 h-12">
                    <SelectValue placeholder={orderFilter || "Random"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Random">Random</SelectItem>
                    <SelectItem value="Newest">Newest</SelectItem>
                    <SelectItem value="Popular">Popular</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="px-5">
          {isLoading || isRefetching ? (
            <div className="flex justify-center items-center w-full h-screen">
              <Loader />
            </div>
          ) : (
            <div>
              {data && allData && allData.length === 0 ? (
                <div className="flex flex-col justify-center items-center">
                  <p className="text-center w-4/5 lg:w-1/2 mb-5">
                    Looks like you've gone down a path with no
                    inspiration...this is your fault! All we do is win...but
                    really, maybe try a different configuration of filters - we
                    got you!
                  </p>
                  <img src="/giphy.webp" width="480" height="270" alt="" />
                </div>
              ) : (
                <div
                  className={
                    "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2.5"
                  }
                >
                  {data &&
                    allData &&
                    allData.length > 0 &&
                    allData.map((card) => (
                      <LandingFeedAdCard key={card.landerId} adData={card} />
                    ))}
                  <div className={"relative"}>
                    <div
                      className={
                        "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                      }
                      ref={scrollRef}
                    ></div>
                  </div>{" "}
                  <div className={"relative"}>
                    <div
                      className={
                        "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                      }
                      ref={scrollRef}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default All;
