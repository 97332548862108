import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button, Text } from "@/components/custom-components";
import { LandingFeedProps } from "@/components/templates/LandingAdCard";
import { useState } from "react";
import { trpc } from "@/utils/trpc.ts";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { toast } from "sonner";
import { ShareIcon } from "lucide-react";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { useAuth } from "@memberstack/react";

export const ExpandedLandingPage = ({
  data,
  open,
  onOpenChange,
}: {
  data: LandingFeedProps;
  open: boolean;
  onOpenChange: () => void;
}) => {
  const [adRequested, setAdRequested] = useState<boolean>(false);

  const { isLoggedIn } = useAuth();

  const { mutateAsync: requestAdTemplate } =
    trpc.requestLanderTemplate.useMutation();

  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-[90vw] md:min-w-[80vw] p-0 rounded-lg bg-transparent lg:bg-white border-0">
        <div className={"lg:px-5 pt-5 pb-0"}>
          <DialogHeader
            className={"text-white lg:text-black text-left mb-2.5 lg:mb-5"}
          >
            <DialogTitle className={""}>
              <Text
                className={"text-base lg:text-lg"}
                weight={"semibold"}
                size={"lg"}
              >
                {data.brandName}
              </Text>
            </DialogTitle>
          </DialogHeader>
          <div>
            <div className={"border-b border-[#B4B4B4]"}>
              <ScrollArea
                className={"w-full h-[71svh] lg:h-[75svh] rounded-t-md"}
              >
                {data.desktopScreenshot && (
                  <img
                    alt={"screenshot of a landing page"}
                    loading={"lazy"}
                    className={"w-full rounded-t-md hidden lg:flex"}
                    src={data.desktopScreenshot}
                  />
                )}
                {data.mobileScreenshot && (
                  <img
                    alt={"screenshot of a landing page"}
                    loading={"lazy"}
                    className={"w-full rounded-t-md flex lg:hidden"}
                    src={data.mobileScreenshot}
                  />
                )}
              </ScrollArea>
            </div>
          </div>
          <div
            className={
              "w-full flex items-center justify-end gap-2.5 bg-white rounded-b-md"
            }
          >
            <div>
              {isLoggedIn && (
                <Button
                  className={"px-2.5 py-[0.8438rem] flex-1 text-nowrap"}
                  variant={"primary"}
                  onClick={() => {
                    setAdRequested(true);
                    requestAdTemplate({
                      landerID: data.landerId,
                    }).then(() => {
                      // utils.homeFeed.invalidate();
                      toast(`Landing Template has been requested!`);
                      setTimeout(() => {
                        setAdRequested(false);
                      }, 5000);
                    });
                  }}
                >
                  {adRequested ? "Requested!" : "Request Template"}
                </Button>
              )}
            </div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div
                    className={
                      "flex justify-end gap-[0.9375rem] px-2 py-[0.625rem]"
                    }
                  >
                    <span
                      className={
                        "border rounded-md border-black p-2.5 cursor-pointer"
                      }
                      onClick={() => {
                        copyToClipboard(
                          `${window.location.origin}/share/inspiration/landing-pages/${data.landerId}`,
                        );
                        toast("Copied!");
                      }}
                    >
                      <ShareIcon className={"w-[20px] h-[20px]"} />
                    </span>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Share a public link</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
