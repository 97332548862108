export default function CursorIcon({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_403_5625)">
        <path
          d="M1.99867 6.66223C2.01691 4.08669 4.11961 2.01357 6.69518 2.03184C8.97235 2.04798 10.9049 3.70635 11.2665 5.9547L13.3245 6.70221C13.3245 6.68887 13.3245 6.67557 13.3245 6.66223C13.3245 2.9828 10.3417 0 6.66223 0C2.98277 0 0 2.9828 0 6.66223C0 10.3417 2.9828 13.3245 6.66223 13.3245C6.67622 13.3245 6.68887 13.3245 6.70286 13.3245L5.95536 11.2665C3.68134 10.9178 2.00136 8.96286 1.99867 6.66223Z"
          fill={color}
        />
        <path
          d="M9.03859 5.37966C9.15252 5.37966 9.2651 5.39899 9.37837 5.40964C8.68992 3.90663 6.91337 3.24629 5.41037 3.93474C3.90737 4.62319 3.24702 6.39973 3.93547 7.90273C4.23281 8.55183 4.75234 9.0733 5.40035 9.373C5.21856 7.3575 6.70511 5.57621 8.72065 5.39446C8.82636 5.3849 8.93244 5.37997 9.03859 5.37966Z"
          fill={color}
        />
        <path
          d="M15.6949 14.2817L13.9328 12.5196L15.0427 11.4097C15.7299 10.7227 15.7302 9.6086 15.0432 8.92133C14.8613 8.73933 14.6416 8.59945 14.3998 8.51157L9.84412 6.85533C8.63383 6.41531 7.29597 7.03971 6.85592 8.25003C6.66873 8.76488 6.66879 9.32922 6.85611 9.84403L8.51235 14.3997C8.71653 14.9658 9.19702 15.3874 9.78485 15.5163C9.9113 15.5443 10.0404 15.5586 10.1699 15.5589C10.6354 15.5585 11.0816 15.3727 11.4098 15.0426L12.5197 13.9327L14.2819 15.6948C14.6653 16.0918 15.2979 16.1028 15.6949 15.7194C16.0919 15.3359 16.1029 14.7033 15.7195 14.3063C15.7114 14.298 15.7032 14.2898 15.6949 14.2817ZM10.2598 13.3644L8.7342 9.16114C8.68602 9.03982 8.71693 8.90135 8.81215 8.81203C8.90066 8.71541 9.04006 8.6843 9.16126 8.73408L13.3645 10.2597L10.2598 13.3644Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_403_5625">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
