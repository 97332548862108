import { createFileRoute, Link } from "@tanstack/react-router";

import { Loader } from "@/components/custom-components/Loader";
import { Button, PlanTag, Text } from "@/components/custom-components";
import { Divider } from "@/components/custom-components/Divider";
import { ShareIcon } from "lucide-react";
import { useElementSize } from "@/hooks/useElementSize.tsx";
import { toast } from "sonner";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { SidebarButton } from "@/components/templates/Sidebar";
import { z } from "zod";
import { Expert } from "../../../shared/airtableGet.ts";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import AdIcon from "@/assets/AdIcon.tsx";
import { cloneElement, useEffect, useState } from "react";
import { LockClosedIcon } from "@radix-ui/react-icons";
import FullAccessPromptDialog from "@/components/FullAccessPromptDialog.tsx";
import ExpertIcon from "@/assets/ExpertIcon.tsx";

type BoardCollectionDataProps = {
  collections: ExpertProductType[];
};

type SearchParams = {
  sideBarOpen?: boolean;
};

type ExpertProductType = {
  title: string;
  description: string;
  url: string;
  ctaButton: string;
  priceID: string;
  planID: string[];
  imageLink: string;
};

export const ExpertProducts: ExpertProductType[] = [
  {
    title: "Fraser Cottrell",
    url: "/feeds/collections/experts/Fraser%20Cottrell",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-fraser.png",
  },
  {
    title: "Tom Brown",
    url: "/feeds/collections/experts/Tom%20Brown",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-tom.png",
  },
  {
    title: "Rahul Issar",
    url: "/feeds/collections/experts/Rahul%20Issar",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-rahul.png",
  },
  {
    title: "Zach Duncan",
    url: "/feeds/collections/experts/Zach%20Duncan",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-zach.png",
  },
  {
    title: "Austin Rabin",
    url: "/feeds/collections/experts/Austin%20Rabin",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expoert-austin.png",
  },
  {
    title: "Rabah Rahil",
    url: "/feeds/collections/experts/Rabah%20Rahil",
    description:
      "Spent millions of dollars at an agency, ran his own agency and most recently became CMO at Triple Whale.",
    ctaButton: "Get Rabah's Creatives",
    priceID: "prc_rabah-s-pack-mr2r0nth",
    planID: [
      "pln_rabah-s-pack-cp2q0nb6",
      "pln_rabah-s-creative-pack-obh70opq",
      "pln_rabah-legacy-pack-15fb0o8e",
    ],
    imageLink: "/expert-rabah-r.png",
  },
  {
    title: "Nick Shackelford",
    url: "/feeds/collections/experts/Nick%20Shackelford",
    description:
      "A pioneer in Facebook Ads for DTC brands. Co-founder of: Structured, Konstant Kreative & Brez.",
    ctaButton: "Get Nick's Creatives",
    priceID: "prc_nick-s-pack-s31n0nzl",
    planID: [
      "pln_nick-s-pack-lu1m0npx",
      "pln_nick-s-creative-pack-s6vn0n68",
      "pln_nick-s-pack-legacy-8ztv0nhz",
    ],
    imageLink: "/expert-nick-s.png",
  },
  {
    title: "Ash Melwani",
    url: "/feeds/collections/experts/Ash%20Melwani",
    description:
      "Co-Founder & CMO of Obvi, one of the world’s fastest growing health & nutrition brands.",
    ctaButton: "Get Ash's Creatives",
    priceID: "prc_ash-s-pack-sk2f0nrf",
    planID: [
      "pln_ash-s-pack-i41d01o8",
      "pln_ash-legacy-pack-gef30o1t",
      "pln_ash-s-creative-pack-gsh50oot",
      "",
    ],
    imageLink: "/expert-ash-m.png",
  },
  {
    title: "Barry Hott",
    url: "/feeds/collections/experts/Barry%20Hott",
    description:
      "Barry is a Growth Marketing Consultant & Advisor know for his expertise in growth and creative strategies.",
    ctaButton: "Get Barry's Creatives",
    priceID: "prc_barry-s-creative-pack-lkx40153",
    planID: [
      "pln_barry-s-pack-rd2w0ni3",
      "pln_barry-hot-expert-pack-u26u0hn0",
      "pln_barry-legacy-pack-yyf20our",
    ],
    imageLink: "/expert-barry-h.png",
  },
  {
    title: "Sarah Levinger",
    url: "/feeds/collections/experts/Sarah%20Levinger",
    description:
      "Sarah is a Forbes-featured consumer behavior analyst, creative strategist, and performance creative consultant.",
    ctaButton: "Get Sarah's Creatives",
    priceID: "prc_sara-s-creative-pack-hyvl0ns4",
    planID: [
      "pln_sarah-s-creative-pack-puvk0nz1",
      "pln_sarah-legacy-pack-ntu60nuz",
      "pln_sarah-s-pack-mv1601hd",
    ],
    imageLink: "/expert-sarah-l.png",
  },
  {
    title: "Dara Denney",
    url: "/feeds/collections/experts/Dara%20Denney",
    description:
      "Dara is a performance creative consultant and creator. She’s led creative and growth teams for the last 5 years.",
    ctaButton: "Get Dara's Creatives",
    priceID: "prc_dara-s-pack-kp1l01dv",
    planID: [
      "pln_dara-s-pack-hd1k015b",
      "pln_dara-legacy-pack-8gf40oh3",
      "pln_dara-s-creative-pack-4wvp0ncz",
    ],
    imageLink: "/expert-dara-d.png",
  },
];

// const ExpertPackPlanID = "pln_expert-pack-cvuf01gf";

export const Route = createFileRoute("/feeds/collections/experts/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const { sideBarOpen } = Route.useSearch();

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  useEffect(() => {
    if (!isLoadingPermission && !permissionData?.userCanAccessEverything) {
      setUpgradeDialogOpen(true);
    }
  }, [
    permissionData,
    permissionData?.userCanAccessEverything,
    isLoadingPermission,
  ]);

  return (
    <>
      {upgradeDialogOpen && (
        <FullAccessPromptDialog
          upgradeDialogOpen={upgradeDialogOpen}
          setUpgradeDialogOpen={() => setUpgradeDialogOpen(false)}
        />
      )}
      <div className={"px-5 md:px-5 md:py-0"}>
        <div className={"bg-white sticky top-0 z-10"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Experts Collection
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
            <div>
              <div className={"flex gap-3 lg:gap-5 items-center mb-5"}>
                <span className={"hidden lg:flex"}>Show:</span>
                <div
                  className={
                    "flex gap-2 lg:gap-5 items-center overflow-x-auto w-[90vw]"
                  }
                >
                  {[
                    {
                      name: "Templates",
                      link: "/feeds/collections/",
                      icon: <AdIcon />,
                    },
                    {
                      name: "Experts",
                      link: "/feeds/collections/experts",
                      icon: <ExpertIcon />,
                    },
                  ].map((item) => {
                    const isActive = location.pathname === item.link;
                    const iconColor = isActive ? "white" : "black";
                    return (
                      <Link
                        onClick={(e) => {
                          // If a user navigates before loading is complete, choose to just navigate
                          // This optimizes for users who ARE allowed access and doesn't force them to
                          // wait until we have the data back from the server if they have access

                          if (
                            item.name === "Experts" &&
                            !isLoadingPermission &&
                            !permissionData?.userCanAccessEverything
                          ) {
                            setUpgradeDialogOpen(true);
                            e.preventDefault();
                          }
                        }}
                        to={item.link}
                        search={(d) => ({
                          ...d,
                          cacheBuster: Math.random(),
                          orderFilter: "Random",
                          sideBarOpen: false,
                        })}
                        className="flex gap-2.5 justify-start items-center h-12 lg:h-10 px-3 lg:px-5 py-4 border border-black rounded-md text-[0.875rem] font-normal"
                        activeProps={{
                          className:
                            "border-0 border-transparent rounded-lg bg-gradient-to-b from-[#A259FF] to-[#613599] text-white",
                        }}
                        activeOptions={{
                          exact: true,
                          includeSearch: false,
                        }}
                        key={item.name}
                      >
                        <span>
                          <span>
                            {cloneElement(item.icon, { color: iconColor })}
                          </span>
                        </span>
                        <Text
                          className={"w-auto text-nowrap"}
                          weight={"medium"}
                          data-testid={item.name}
                        >
                          {item.name}
                        </Text>
                        {item.name === "Experts" && (
                          <>
                            {!isLoadingPermission &&
                              !permissionData?.userCanAccessEverything && (
                                <PlanTag label={<LockClosedIcon />} />
                              )}
                          </>
                        )}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
          </div>
        </div>

        {!isLoadingPermission &&
          permissionData?.userCanAccessEverything &&
          ExpertProducts && <ExpertsGridView collections={ExpertProducts} />}
      </div>
    </>
  );
}

const ExpertsGridView = (props: BoardCollectionDataProps) => {
  return (
    <div className={"grid lg:grid-cols-2 gap-5 mb-10"}>
      {props.collections && props.collections.length > 0
        ? props.collections.map((item) => (
            <CollectionItem key={item.title} data={item} />
          ))
        : props.collections.length === 0 && (
            <div
              className={
                "h-full w-full lg:col-span-2 flex justify-center items-center"
              }
            >
              <p>No collections created</p>
            </div>
          )}
    </div>
  );
};

const CollectionItem = ({ data }: { data: ExpertProductType }) => {
  const [, copyToClipboard] = useCopyToClipboard();

  const {
    data: adTemplates,
    isLoading,
    error,
  } = trpc.posts.useQuery(
    {
      Expert: data.title as z.infer<typeof Expert>,
      Tags: undefined,
      limit: 6,
      cursor: undefined,
      Ready: true,
      sortingOptions: undefined,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  // Columns needs to be controlled by the width of the container.
  // So we set the width of each masonry column to a fraction of the container width
  const columns = 3;

  const [squareRef] = useElementSize();

  const gutterWidth = 18;

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading) {
    return (
      <div className={"flex justify-center items-center w-full h-full m-auto"}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={
        "border-[0.031rem] rounded-[0.5rem] border-[#B4B4B4] bg-[#F5F5F5]"
      }
    >
      <div
        className={
          "py-[0.75rem] px-[0.563rem] flex justify-between items-center "
        }
      >
        <div className={`flex gap-[0.625rem] items-center`}>
          <div className={"flex items-center gap-2.5"}>
            <img
              alt={data.title}
              src={data.imageLink}
              className={
                "w-[1.875rem] h-[1.875rem] rounded-full border-2 border-white object-cover"
              }
            />
            <div className={"grid lg:flex items-center lg:gap-2.5"}>
              <h4
                className={`truncate font-[500] text-[0.938rem] leading-[1.134rem] text-black`}
              >
                {data.title}'s Collection
              </h4>
              <span className={"text-[#7F7F7F] text-sm lg:text-base"}>
                {adTemplates?.TotalRecords} Ads by {data.title}
              </span>
            </div>
          </div>
        </div>
        <div>
          <Link to={data.url}>
            <Button className={"font-medium text-nowrap px-2.5"}>
              View Collection
            </Button>
          </Link>
        </div>
      </div>
      <div
        className={
          "p-5 bg-white border-y border-[#B4B4B4] h-[23.75rem] overflow-hidden"
        }
      >
        <div ref={squareRef} className={"relative w-full"}>
          {adTemplates?.ATRecords && adTemplates?.ATRecords.length > 0 && (
            <div>
              <ResponsiveMasonry
                columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
              >
                <Masonry gutter={gutterWidth + "px"}>
                  {adTemplates?.ATRecords.map((ad) => (
                    <div
                      key={ad.CreativeID}
                      className={"bg-gray-200 rounded-lg"}
                    >
                      <img
                        src={ad["Example Image"][0].thumbnails.large.url}
                        alt={""}
                        className={"w-full object-contain rounded-lg"}
                      />
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          )}
        </div>
      </div>
      <div className={"flex justify-end gap-[0.9375rem] px-2 py-[0.625rem]"}>
        <span
          onClick={() => {
            copyToClipboard(`${window.location.origin}/${data.url}`);
            toast("Copied!");
          }}
          className={"border rounded-md border-black p-2.5 cursor-pointer"}
        >
          <ShareIcon className={"w-[24px] h-[24px]"} />
        </span>
      </div>
    </div>
  );
};
