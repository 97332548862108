"use client";

import { useTheme } from "next-themes";
import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();

  return (
    <Sonner
      position={"bottom-center"}
      theme={theme as ToasterProps["theme"]}
      className="toaster group text-center"
      toastOptions={{
        classNames: {
          toast:
            "flex justify-center items-center group-[.toaster]:bg-toastgrad group-[.toaster]:bg-cover group-[.toaster]:bg-no-repeat group-[.toaster]:text-center group-[.toaster]:text-white group-[.toaster]:text-[0.938rem] group-[.toaster]:text-[0.938rem] group-[.toaster]:shadow-lg group-[.toaster]:rounded-2xl group-[.toaster]:border-none",
          description:
            "group-[.toast]:text-muted-foreground group-[.toaster]:text-center",
          actionButton:
            "group-[.toast]:bg-toastgrad group-[.toast]:text-primary-foreground",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
