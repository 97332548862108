import { createFileRoute } from "@tanstack/react-router";
import { AdsView } from "@/components/AdsView.tsx";
import { TopBar } from "@/components/topBar.tsx";
import { z } from "zod";
import { Expert } from "../../../shared/airtableGet.ts";

export const Route = createFileRoute("/library/experts/$expert")({
  component: ExpertAds,
});

function ExpertAds() {
  const { expert } = Route.useParams();
  return (
    <>
      <div className={"sticky top-0 z-10 px-2"}>
        <div className={"bg-white px-8 pt-6 pb-2"}>
          <TopBar Title={expert} />
        </div>
        <div className={"h-5 bg-gradient-to-b from-white/100 to-white/0"}></div>
      </div>

      <AdsView
        Filter={{
          Expert: expert as z.infer<typeof Expert>,
          Tags: undefined,
          limit: 40,
          cursor: undefined,
          Ready: true,
          sortingOptions: undefined,
        }}
      />
    </>
  );
}
