import { forwardRef, ReactNode } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils.ts";

const iconButtonStyles = cva(["flex", "justify-center", "items-center"], {
  variants: {
    variant: {
      primary: "",
      secondary: "bg-black text-white",
      gradient: "bg-radiantgradient text-white border-none",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

interface IconButtonProps
  extends React.ComponentPropsWithoutRef<"button">,
    VariantProps<typeof iconButtonStyles> {
  icon: ReactNode; // Accepts any valid React node as icon
  onClick?: () => void;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, onClick, className, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(iconButtonStyles({ className }))}
        {...props}
        onClick={onClick}
      >
        {icon}
      </button>
    );
  },
);
