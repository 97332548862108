import {
  Button,
  Checkbox,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@/components/custom-components";
import { AdHeader } from "@/components/custom-components/AdHeader";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { cva, VariantProps } from "class-variance-authority";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { cn } from "@/lib/utils.ts";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { copyToClipboard } from "@/components/copyToClipboard.tsx";
import { FigmaLogo } from "@/assets/template-logo.tsx";
import { GenericCardProps } from "@/components/templates/LandingAdGridView";
import { trpc } from "@/utils/trpc.ts";
import { DotsLoader } from "@/components/custom-components/Loader";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { toast } from "sonner";
import { ShareIcon } from "lucide-react";
import { BoardDialog } from "@/components/templates/Sidebar";
import { IconButton } from "@/components/custom-components/IconButton";
import { PlusIcon } from "@radix-ui/react-icons";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { ExpandedLandingPage } from "@/components/ExpandedLandingPage.tsx";

const adCardStyles = cva("w-full  rounded-[0.5rem] bg-[#F5F5F5]");

export interface AdDataProps {
  Screenshot: string;
  ID: string;
}

export type LandingFeedProps = {
  landerId: number;
  landerUrl: string;
  brandName: string | null;
  brandImage: string | null;
  desktopScreenshot: string | null;
  mobileScreenshot: string | null;
  brandId: string | null;
  isSaved?: boolean;
};

export interface AdCardProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adCardStyles> {
  adData: AdDataProps;
  type: "email" | "landing-page";
}

export interface LandingAdCardProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adCardStyles> {
  adData: LandingFeedProps;
  IsBlurred?: boolean;
  IsPublic?: boolean;
}

export const LandingAdCard = forwardRef<HTMLDivElement, AdCardProps>(
  ({ className, adData, type, ...props }, ref) => {
    return (
      <div ref={ref} className={cn(adCardStyles({ className }))} {...props}>
        <div
          className={
            "py-[0.75rem] px-[0.563rem] flex justify-between items-center"
          }
        >
          <AdHeader
            brandName={undefined}
            avatar={undefined}
            className={"w-full"}
          />
          <div className={"text-nowrap"}></div>
        </div>
        <div className={"px-2.5"}>
          <ScrollArea className={"w-full h-[35.4375rem]"}>
            <img
              alt={
                type === "email"
                  ? "screenshot of a email template"
                  : "screenshot of a landing page template"
              }
              loading={"lazy"}
              className={"w-full rounded-md"}
              src={adData.Screenshot}
            />
          </ScrollArea>
        </div>
        <div
          className={
            "flex justify-between gap-[1.25rem] px-[1.25rem] pb-[0.625rem] pt-[0.563rem]"
          }
        >
          <GetLandingAdTemplate type={type} Ad={adData} />
        </div>
      </div>
    );
  },
);

type templateProps = {
  Ad: GenericCardProps;
  IsPublic?: boolean;
  type: "email" | "landing-page";
};

const GetLandingAdTemplate = (props: templateProps) => {
  const [copied, setCopied] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { isLoading, refetch } = trpc.getFigmaCopyPasteCode.useQuery(
    {
      id: props.Ad.ID.toString(),
      type: props.type,
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleCopyClick = async () => {
    if (props.IsPublic) {
      setDialogOpen(true);
      return;
    }

    // Attempt to copy immediately after fetching
    const success = await copyToClipboard(refetch);
    if (success) {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Want the copy-and-paste template for this ad?
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Sign up for a free trial and get access to this ad and hundreds
              more
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Get Free Access</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className={"py-[0.625rem] text-nowrap"}>
        <div className={"flex items-center gap-3"}>
          <Text weight={"medium"}>Get this template:</Text>
          <div className={"flex gap-3 items-center"}>
            {isLoading ? (
              <div>
                <DotsLoader />
              </div>
            ) : copied ? (
              <div className="flex items-center bg-white rounded-full px-3 py-3">
                <p className="text-black text-sm leading-none font-semibold">
                  CMD+V in Figma
                </p>
              </div>
            ) : (
              <div onClick={handleCopyClick}>
                <span className={"cursor-pointer"}>
                  <FigmaLogo />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const LandingFeedAdCard = forwardRef<HTMLDivElement, LandingAdCardProps>(
  ({ className, adData, IsPublic, IsBlurred = false, ...props }, ref) => {
    const [saveOpen, setSaveOpen] = useState<boolean>(false);
    const [expandOpen, setExpandOpen] = useState<boolean>(false);

    const [, copyToClipboard] = useCopyToClipboard();

    return (
      <div
        ref={ref}
        className={cn(
          IsBlurred && "filter blur-md pointer-events-none",
          adCardStyles({ className }),
        )}
        {...props}
      >
        {expandOpen && (
          <ExpandedLandingPage
            open={expandOpen}
            onOpenChange={() => setExpandOpen(false)}
            data={adData}
          />
        )}
        <div
          className={
            "py-[0.75rem] px-[0.563rem] flex justify-between items-center "
          }
        >
          {adData.brandId && (
            <AdHeader
              brandName={adData.brandName}
              brandId={adData.brandId}
              Created={undefined}
              avatar={adData.brandImage}
              isPromo={false}
              adRunningDays={undefined}
              status={undefined}
            />
          )}
          <div className={"text-nowrap"}>
            {!IsPublic && adData && (
              <ManuallySaveFeedAdToBoard
                open={saveOpen}
                onOpenChange={setSaveOpen}
                data={adData}
              />
            )}
          </div>
        </div>
        <div className={"p-2.5 border-y border-[#B4B4B4]"}>
          <ScrollArea className={"w-full h-[29.125rem]"}>
            {adData.mobileScreenshot && (
              <img
                alt={"screenshot of a landing page"}
                loading={"lazy"}
                className={"w-full rounded-md hidden lg:flex"}
                src={adData.mobileScreenshot}
              />
            )}
            {adData.mobileScreenshot && (
              <img
                alt={"screenshot of a landing page"}
                loading={"lazy"}
                className={"w-full rounded-md flex lg:hidden"}
                src={adData.mobileScreenshot}
              />
            )}
          </ScrollArea>
        </div>
        <div className={"flex items-center justify-end gap-2.5 p-2.5"}>
          <a target={"_blank"} href={adData.landerUrl}>
            <Button
              className={"px-2.5 py-[0.8438rem] flex-1"}
              variant={"primary"}
            >
              {"View Landing Page"}
            </Button>
          </a>
          <div>
            <Button
              className={"px-2.5 py-[0.8438rem] flex-1 text-nowrap"}
              variant={"primary"}
              onClick={() => setExpandOpen(true)}
            >
              {"Expand"}
            </Button>
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className={"flex justify-end gap-[0.9375rem]"}>
                  <span
                    className={
                      "border rounded-md border-black p-2.5 cursor-pointer"
                    }
                    onClick={() => {
                      copyToClipboard(
                        `${window.location.origin}/share/inspiration/landing-pages/${adData.landerId}`,
                      );
                      toast("Copied!");
                    }}
                  >
                    <ShareIcon className={"w-[20px] h-[20px]"} />
                  </span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Share a public link</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    );
  },
);

function ManuallySaveFeedAdToBoard({
  open,
  onOpenChange,
  data,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: LandingFeedProps;
}) {
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);
  const { data: boards, isLoading } = trpc.getBoards.useQuery(
    {
      checkAdId: data.landerId,
      isLander: true,
    },
    {
      enabled: open,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const utils = trpc.useUtils();

  const { mutateAsync: removeLike } = trpc.unSaveLander.useMutation();
  const { mutateAsync: addLike } = trpc.saveLander.useMutation();
  const { mutateAsync: addAdToBoard } = trpc.addLanderToBoard.useMutation();
  const { mutateAsync: removeAdFromBoard } =
    trpc.removeLanderFromBoard.useMutation();

  const [manuallySetLike, setManuallySetLike] = useState<undefined | boolean>(
    undefined,
  );

  // Basic optimistic update of the like button
  useEffect(() => {
    if (data.isSaved || data.isSaved === undefined) {
      setManuallySetLike(true);
    } else {
      setManuallySetLike(false);
    }
  }, [data.isSaved]);

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
          adId={data.landerId}
        />
      )}
      <PopoverTrigger>
        <>
          {manuallySetLike !== undefined && (
            <>
              {manuallySetLike ? (
                <Button
                  className={"px-2.5 text-[0.8125rem]"}
                  variant={"secondary"}
                  onClick={() => {
                    setManuallySetLike(false);
                    removeLike({
                      landerId: data.landerId,
                    }).then(() => {
                      utils.getAllSavedAds.refetch();
                    });
                  }}
                >
                  {"Saved"}
                </Button>
              ) : (
                <Button
                  className={"px-2.5 text-[0.8125rem]"}
                  onClick={() => {
                    setManuallySetLike(true);
                    addLike({
                      landerId: data.landerId,
                    }).then(() => {
                      utils.getAllSavedAds.refetch();
                    });
                  }}
                >
                  {"Save"}
                </Button>
              )}
            </>
          )}
        </>
      </PopoverTrigger>
      <PopoverContent className={"max-h-[25.75rem]"} align={"end"}>
        <div className={"flex justify-between gap-3"}>
          <Text weight={"semibold"}>Add to board</Text>
          <IconButton
            onClick={() => setCreateBoardDialogOpen(true)}
            icon={<PlusIcon className={"h-[1.5rem] w-[1.5rem] "} />}
          />
        </div>
        <Stack className={"gap-2 my-5"}>
          {/*List of boards and the ad's existing status here*/}
          {isLoading ? (
            <div>
              <p>Loading boards...</p>
            </div>
          ) : (
            <div className={"max-h-[16rem] overflow-y-auto"}>
              {boards &&
                boards.map((board, idx) => (
                  <div
                    key={idx}
                    className={"flex gap-2 justify-between w-full mb-2"}
                  >
                    <label htmlFor={board.name}>{board.name}</label>
                    <Checkbox
                      id={board.name}
                      checked={board.isInBoard}
                      onCheckedChange={(isChecked) => {
                        if (isChecked) {
                          // Add the ad to the board
                          addAdToBoard({
                            boardId: board.id,
                            landerId: data.landerId,
                          }).then(() => {
                            utils.getBoards.invalidate();
                            toast(`Added to ${board.name} board!`);
                          });
                        } else {
                          // Remove the ad from the board
                          removeAdFromBoard({
                            boardId: board.id,
                            landerId: data.landerId,
                          }).then(() => {
                            utils.getBoards.invalidate();
                            toast(`Ad removed from ${board.name} board!`);
                          });
                        }
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        </Stack>
        <Button
          onClick={() => onOpenChange(false)}
          className={"w-full"}
          variant={"secondary"}
        >
          Done
        </Button>
      </PopoverContent>
    </Popover>
  );
}
