import { ComponentProps, forwardRef } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils.ts";

const buttonStyles = cva(
  [
    "text-center",
    "flex",
    "justify-center",
    "items-center",
    "border",
    "border-black",
    "rounded-[0.469rem]",
    "px-5",
    "py-[0.8438rem]",
    "text-[0.813rem]",
    "font-[500]",
  ],
  {
    variants: {
      variant: {
        primary: "",
        ["no-outline"]: "border-none bg-white text-black",
        secondary: "bg-black text-white",
        gradient: "bg-radiantgradient text-white border-none",
        brandgradient: "bg-brandgradmin bg-center text-white border-none",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

type ButtonProps = ComponentProps<"button"> &
  VariantProps<typeof buttonStyles> & {
    leftIcon?: React.ReactNode;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, leftIcon, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(buttonStyles({ variant, className }))}
        {...props}
      >
        {leftIcon && <span className="mr-2">{leftIcon}</span>}
        {props.children}
      </button>
    );
  },
);
