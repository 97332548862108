import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/custom-components";
import { PRO_PLAN, STANDARD_PLAN } from "@/utils/data/plans.ts";
import { useEffect, useState } from "react";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";

export type ChargebeePlanType = {
  name: string;
  price: string;
  plan_id: string;
  description: string;
  features: string[];
};

export default function FullAccessPromptDialog({
  upgradeDialogOpen,
  setUpgradeDialogOpen,
}: {
  upgradeDialogOpen: boolean;
  setUpgradeDialogOpen: () => void;
}) {
  const [selected, setSelected] = useState<ChargebeePlanType | null>(null);

  useEffect(() => {
    setSelected(() => PRO_PLAN);
  }, []);

  // get the checkout url based on the selected plan
  const { data, isLoading, isRefetching, refetch } =
    trpc.getNewCheckoutUrl.useQuery(
      { plans: selected ? [selected.plan_id] : [] },
      { enabled: false, refetchOnWindowFocus: false },
    );

  const { mutate: openPortalSessionUrl, isPending: isLoadingPortalSessionUrl } =
    trpc.getPortalSessionUrl.useMutation({
      onSuccess: async (data) => {
        if (data) {
          console.log(data);
          window.location.href = data;
        }
      },
    });

  const { data: chargebeeSyncStatus, isLoading: isLoadingChargebeeSynced } =
    trpc.getIsUserSynced.useQuery(undefined, {
      refetchOnWindowFocus: false,
    });

  useEffect(() => {
    if (data && !isLoading && !isRefetching) {
      window.location.href = data;
    }
  }, [data, isLoading, isRefetching]);

  const initiatePayment = () => {
    if (chargebeeSyncStatus && !isLoadingChargebeeSynced)
      openPortalSessionUrl();
    // window.location.href = portalSessionUrl;
    else refetch();
  };

  return (
    <Dialog open={upgradeDialogOpen} onOpenChange={setUpgradeDialogOpen}>
      <DialogContent className="w-[90vw] border-0 rounded-lg md:max-w-[28.125rem] p-[2px] outline-0 bg-gradient-to-b from-[#A259FF] to-[#613599]">
        <div className={"bg-white p-5 rounded-md"}>
          <DialogHeader>
            <DialogTitle className="text-left text-2xl bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text">
              Upgrade to unlock
            </DialogTitle>
            <DialogDescription
              className={"text-left text-sm mt-5 text-black font-medium"}
            >
              Level up your ad workflow with our paid plans
            </DialogDescription>
          </DialogHeader>
          {isLoading || isRefetching || isLoadingPortalSessionUrl ? (
            <div className="flex justify-center items-center w-full h-[40vh]">
              <Loader />
            </div>
          ) : (
            <div className={"mt-5"}>
              <div>
                {selected?.plan_id === STANDARD_PLAN.plan_id ? (
                  <div className={"grid grid-cols-2 gap-2.5"}>
                    {STANDARD_PLAN.features.map((item) => (
                      <span key={item} className={"flex items-start gap-2.5"}>
                        <img
                          src={"/icons/gradient-check.svg"}
                          alt={""}
                          className={""}
                        />
                        <span className={"text-black text-[0.875rem]"}>
                          {item}
                        </span>
                      </span>
                    ))}
                  </div>
                ) : (
                  <div className={"grid grid-cols-2 gap-2.5"}>
                    {PRO_PLAN.features.map((item) => (
                      <span key={item} className={"flex items-start gap-5"}>
                        <img
                          src={"/icons/gradient-check.svg"}
                          alt={""}
                          className={""}
                        />
                        <span className={"text-black text-[0.875rem]"}>
                          {item}
                        </span>
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <hr className={"my-5"} />
              <div>
                <p className="text-base font-semibold bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text">
                  Select your plan
                </p>
                <div className={"mt-2.5"}>
                  <p
                    className={"text-[0.6875rem] text-[#7F7F7F] font-semibold"}
                  >
                    Upgrade
                  </p>
                  <div className={"grid gap-2.5 mt-[0.3125rem]"}>
                    {STANDARD_PLAN && (
                      <div
                        className={`rounded-lg  p-[1px] cursor-pointer ${selected?.plan_id == STANDARD_PLAN.plan_id ? "bg-gradient-to-b from-[#A259FF] to-[#613599]" : "bg-black"}`}
                        onClick={() => setSelected(STANDARD_PLAN)}
                      >
                        <div className={"rounded-md px-5 py-2.5 bg-white"}>
                          <div className={"flex gap-5 justify-between"}>
                            <div
                              className={
                                "flex items-center gap-2 text-[0.875rem] font-medium"
                              }
                            >
                              <span
                                className={`peer h-[1.25rem] w-[1.25rem] shrink-0 rounded-full border-2 border-[#7324A2] ring-offset-background ${selected?.plan_id == STANDARD_PLAN.plan_id ? "bg-checkgrad" : "focus-visible:outline-checkgrad"}`}
                              ></span>
                              <div className={"flex flex-col gap-2"}>
                                <span className={"font-medium text-[0.875rem]"}>
                                  {STANDARD_PLAN.name}
                                </span>
                                <p
                                  className={
                                    "text-[#7F7F7F] text-[0.6875rem] font-medium"
                                  }
                                >
                                  {STANDARD_PLAN.description}
                                </p>
                              </div>
                            </div>
                            <div>
                              <span
                                className={
                                  "font-medium text-[0.875rem] bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                                }
                              >
                                {STANDARD_PLAN.price}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {PRO_PLAN && (
                      <div
                        className={`rounded-lg  p-[1px] cursor-pointer ${selected?.plan_id == PRO_PLAN.plan_id ? "bg-gradient-to-b from-[#A259FF] to-[#613599]" : "bg-black"}`}
                        onClick={() => setSelected(PRO_PLAN)}
                      >
                        <div className={"rounded-md px-5 py-2.5 bg-white"}>
                          <div className={"flex gap-5 justify-between"}>
                            <div
                              className={
                                "flex items-center gap-2 text-[0.875rem] font-medium"
                              }
                            >
                              <span
                                className={`peer h-[1.25rem] w-[1.25rem] shrink-0 rounded-full border-2 border-[#7324A2] ring-offset-background ${selected?.plan_id == PRO_PLAN.plan_id ? "bg-checkgrad" : "focus-visible:outline-checkgrad"}`}
                              ></span>
                              <div className={"flex flex-col gap-2"}>
                                <span className={"font-medium text-[0.875rem]"}>
                                  {PRO_PLAN.name}
                                </span>
                                <p
                                  className={
                                    "text-[#7F7F7F] text-[0.6875rem] font-medium"
                                  }
                                >
                                  {PRO_PLAN.description}
                                </p>
                              </div>
                            </div>
                            <div>
                              <span
                                className={
                                  "font-medium text-[0.875rem] bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                                }
                              >
                                {PRO_PLAN.price}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr className={"my-5"} />
              <div className={"flex gap-5 justify-between items-center w-full"}>
                <div>
                  <p className={"text-base font-medium"}>{selected?.price}</p>
                </div>
                <div>
                  <Button
                    className={
                      "bg-gradient-to-b from-[#A259FF] to-[#613599] text-white outline-none border-0"
                    }
                    onClick={() => selected && initiatePayment()}
                    disabled={isLoading || isRefetching}
                  >
                    Upgrade
                  </Button>
                </div>
              </div>
              <div className={"flex justify-center"}>
                <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
                  <span
                    className={
                      "text-[#7F7F7F] underline font-medium text-[0.6875rem] text-center"
                    }
                  >
                    View full plan comparison
                  </span>
                </a>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
