import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { trpc } from "@/utils/trpc.ts";
import { router } from "../App.tsx";
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const UseInvalidateRouter = () => {
  const utils = trpc.useUtils();
  const invalidateRouter = () => {
    router.invalidate();
    utils.invalidate();
  };

  return { invalidateRouter };
};
