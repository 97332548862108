import { createFileRoute } from "@tanstack/react-router";
import {
  orderFilter as OrderFilterTypes,
  Tags,
} from "../../../shared/airtableGet.ts";
import { z } from "zod";
import { AdGridView } from "@/components/templates/AdGridView";
import { Text } from "@/components/custom-components";
import { SidebarButton } from "@/components/templates/Sidebar";
import { Divider } from "@/components/custom-components/Divider";

type SearchParams = {
  Tags?: z.infer<typeof Tags>;
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/templates/$adID")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as z.infer<typeof Tags> | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    return {
      Tags: tag,
      sideBarOpen,
    };
  },
});

function All() {
  const { Tags, orderFilter, sideBarOpen } = Route.useSearch();
  const { adID } = Route.useParams();
  return (
    <>
      <div className={""}>
        <div className={"bg-white px-5 md:px-5 lg:pb-2 sticky top-0 z-10"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Ad Preview
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
          </div>
        </div>
        <AdGridView
          Filter={{
            Expert: undefined,
            Tags: Tags ? [Tags] : undefined,
            Ready: true,
            cursor: undefined,
            limit: 20,
            sortingOptions: orderFilter as z.infer<typeof OrderFilterTypes>,
            adID: adID,
          }}
        />
      </div>
    </>
  );
}
