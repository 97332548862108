import { createFileRoute } from "@tanstack/react-router";
import { AdsView } from "@/components/AdsView.tsx";
import { Tags as TagType } from "../../../shared/airtableGet.ts";
import { z } from "zod";
import { TopBar } from "@/components/topBar.tsx";
import { TagFilters } from "@/components/tagFilters.tsx";

type SearchParams = {
  Tags?: z.infer<typeof TagType>;
  CacheBuster?: number | undefined;
};

export const Route = createFileRoute("/library/saved")({
  component: Liked,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as z.infer<typeof TagType> | undefined;
    return {
      Tags: tag,
    };
  },
});

function Liked() {
  const { Tags } = Route.useSearch();
  return (
    <>
      <div className={"sticky top-0 z-10 px-2"}>
        <div className={"bg-white px-8 pt-6 pb-2"}>
          <TopBar Title={"Saved Ads"} />
          <div
            className={
              "flex flex-col md:flex-row justify-between items-end md:items-start -ml-4 py-2"
            }
          >
            <div className={"pt-1"}>
              <TagFilters DestinationURL={"/library/saved"} Tags={Tags} />
            </div>
          </div>
        </div>
        <div className={"h-5 bg-gradient-to-b from-white/100 to-white/0"}></div>
      </div>

      <AdsView
        Filter={{
          Expert: undefined,
          Tags: Tags ? [Tags] : undefined,
          Ready: true,
          cursor: undefined,
          limit: 160,
          OnlyLiked: true,
        }}
      />
    </>
  );
}
