import { createFileRoute, Link, useRouterState } from "@tanstack/react-router";
import { Text } from "@/components/custom-components";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { PencilIcon } from "lucide-react";
import {
  BoardDialog,
  DeleteBoardDialog,
  SidebarButton,
} from "@/components/templates/Sidebar";
import { useState } from "react";
import { Ad, DiscoverGridView } from "@/components/templates/DiscoverGridView";
import { Loader } from "@/components/custom-components/Loader";
import { Divider } from "@/components/custom-components/Divider";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/boards/$boardID/ads")({
  component: BoardAds,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function BoardAds() {
  const { sideBarOpen } = Route.useSearch();

  const { boardID } = Route.useParams();
  const [editBoardDialogOpen, setEditBoardDialogOpen] =
    useState<boolean>(false);
  const [deleteBoardDialogOpen, setDeleteBoardDialogOpen] =
    useState<boolean>(false);

  const { data, isLoading, error } = trpc.getBoardDetails.useQuery(
    {
      boardId: boardID,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const router = useRouterState();

  return (
    <>
      <div className={"px-5 md:px-5"}>
        {editBoardDialogOpen && data && (
          <BoardDialog
            data={{
              name: data.name,
              id: data.id,
              description: data.description || "",
            }}
            open={editBoardDialogOpen}
            onOpenChange={() => setEditBoardDialogOpen(false)}
          />
        )}

        {deleteBoardDialogOpen && data && (
          <DeleteBoardDialog
            data={{
              name: data.name,
              id: data.id,
              description: data.description || "",
            }}
            open={deleteBoardDialogOpen}
            onOpenChange={() => setDeleteBoardDialogOpen(false)}
          />
        )}
        <div className={"sticky top-0 z-10 md:px-2"}>
          <div className={"bg-white pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              ></Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
            <div
              className={
                "flex flex-col lg:flex-row justify-between gap-5 w-full mb-5"
              }
            >
              <div className={"flex justify-start"}>
                <div className={"flex justify-start gap-5 items-center"}>
                  <Text
                    size={"xl"}
                    weight={"semibold"}
                    className={
                      "bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                    }
                  >
                    {data?.name}
                  </Text>
                  <button
                    onClick={() => setEditBoardDialogOpen(true)}
                    className={
                      "w-auto text-nowrap hover:text-black flex gap-3 text-[#7F7F7F]"
                    }
                  >
                    <span>Edit</span>
                    <PencilIcon size={"0.8rem"} />
                  </button>
                </div>
              </div>
              <div
                className={
                  "flex justify-start lg:justify-center overflow-x-auto"
                }
              >
                <div
                  className={"flex lg:grid grid-cols-3 bg-[#D9D9D9] rounded-lg"}
                >
                  {[
                    {
                      name: "Saved Ads",
                      id: "ads",
                      path: `/feeds/boards/${boardID}/ads`,
                    },
                    {
                      name: "Saved Ad Landers",
                      id: "ad-landers",
                      path: `/feeds/boards/${boardID}/ad-landers`,
                    },
                    {
                      name: "Templates",
                      id: "templates",
                      path: `/feeds/boards/${boardID}/templates`,
                    },
                  ].map((item) => (
                    <Link
                      to={item.path}
                      key={item.id}
                      className={`${item.path === router.location.pathname ? "bg-black text-white" : "bg-transparent text-black"} text-sm lg:text-base text-nowrap block text-center rounded-lg lg:min-w-[8.938rem] px-3 lg:px-5 py-2 lg:py-[0.875rem]`}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className={"flex justify-end"}>
                <button
                  onClick={() => setDeleteBoardDialogOpen(true)}
                  className={"text-[#7F7F7F]"}
                >
                  Delete board
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div
              className={
                "flex justify-center items-center w-full h-screen m-auto"
              }
            >
              <Loader />
            </div>
          ) : error ? (
            <ErrorDisplay />
          ) : (
            <div className={""}>
              {data && (
                <>
                  {data.ads.length === 0 ? (
                    <div className={"lg:h-96 flex justify-center items-center"}>
                      <p>No ad added to this board</p>
                    </div>
                  ) : (
                    data.ads.length > 0 && (
                      <DiscoverGridView data={data.ads as Ad[]} />
                    )
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
