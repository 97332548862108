import { Link } from "@tanstack/react-router";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useState } from "react";
import { trpc } from "../utils/trpc.ts";

type sidebarProps = {
  isPublicView: boolean;
};

export const Sidebar = (props: sidebarProps) => {
  const [publicDialogOpen, setPublicDialogOpen] = useState(false);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
  const { data, isLoading } = trpc.permissions.useQuery(null, {});

  return (
    <>
      <Dialog open={publicDialogOpen} onOpenChange={setPublicDialogOpen}>
        <DialogContent className="sm:max-w-[500px] py-6">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Want the copy-and-paste templates for high-performing ads, emails
              and landing pages?
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Sign up for a free trial and get access
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Get Free Access</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={upgradeDialogOpen} onOpenChange={setUpgradeDialogOpen}>
        <DialogContent className="sm:max-w-[500px] py-6">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Upgrade for full access
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Get access to landing page and email templates on top of the ad
              templates
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Get Full Access</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div
        className={
          "flex flex-col justify-between bg-bluegray shadow-sidebar min-h-screen"
        }
      >
        <div>
          <div className={"flex items-center justify-between pl-6 py-3 mb-3.5"}>
            <img alt={"logo"} className={"w-18 h-18"} src={"/logo.svg"} />
            <Link
              search={(old) => ({
                ...old,
                sideBarOpen: false,
              })}
              params={(old) => ({
                ...old,
              })}
              className={
                "group flex md:hidden items-center justify-center w-14 h-12 bg-lightgray rounded-l-full active:bg-slate-600"
              }
            >
              <svg
                className={"fill-slate-700 group-active:fill-slate-100"}
                xmlns="http://www.w3.org/2000/svg"
                height="28"
                viewBox="0 -960 960 960"
                width="28"
              >
                <path d="M640-80 240-480l400-400 71 71-329 329 329 329-71 71Z" />
              </svg>
            </Link>
          </div>

          <p className={"text-black font-medium px-6 mt-4.5 mb-3"}>Libraries</p>

          <Link
            onClick={(e) => {
              if (props.isPublicView) {
                setPublicDialogOpen(true);
                e.preventDefault();
              }
            }}
            to={"/library/static"}
            search={(d) => ({
              ...d,
              cacheBuster: Math.random(),
              orderFilter: "Random",
              sideBarOpen: false,
              loadAdsCreatedAfter: undefined,
            })}
            className="sidebar-link"
            activeProps={{ className: "sidebar-link-active" }}
            activeOptions={{
              includeSearch: false,
            }}
          >
            Static Ads
          </Link>
          <Link
            onClick={(e) => {
              if (props.isPublicView) {
                setPublicDialogOpen(true);
                e.preventDefault();
                return;
              }
              // If a user navigates before loading is complete, choose to just navigate
              // This optimizes for users who ARE allowed access and doesn't force them to
              // wait until we have the data back from the server if they have access
              if (!isLoading && !data?.userCanAccessEverything) {
                setUpgradeDialogOpen(true);
                e.preventDefault();
              }
            }}
            to={"/library/landing-pages"}
            search={(d) => ({
              ...d,
              cacheBuster: Math.random(),
              orderFilter: "Random",
              sideBarOpen: false,
            })}
            className="sidebar-link"
            activeProps={{ className: "sidebar-link-active" }}
            activeOptions={{
              includeSearch: false,
            }}
          >
            <span>
              Landers
              {!isLoading && !data?.userCanAccessEverything && " 👑"}
            </span>
          </Link>
          <Link
            onClick={(e) => {
              if (props.isPublicView) {
                setPublicDialogOpen(true);
                e.preventDefault();
                return;
              }
              // If a user navigates before loading is complete, choose to just navigate
              // This optimizes for users who ARE allowed access and doesn't force them to
              // wait until we have the data back from the server if they have access
              if (!isLoading && !data?.userCanAccessEverything) {
                setUpgradeDialogOpen(true);
                e.preventDefault();
              }
            }}
            to={"/library/emails"}
            search={(d) => ({
              ...d,
              cacheBuster: Math.random(),
              orderFilter: "Random",
              sideBarOpen: false,
            })}
            className="sidebar-link"
            activeProps={{ className: "sidebar-link-active" }}
            activeOptions={{
              includeSearch: false,
            }}
          >
            Emails
            {!isLoading && !data?.userCanAccessEverything && " 👑"}
          </Link>

          <p className={"text-black font-medium px-6 mb-3 mt-6"}>
            Quick Access
          </p>

          <Link
            onClick={(e) => {
              if (props.isPublicView) {
                setPublicDialogOpen(true);
                e.preventDefault();
              }
            }}
            to={"/library/saved"}
            className="sidebar-link"
            activeProps={{ className: "sidebar-link-active" }}
          >
            My Saved Ads
          </Link>

          <Link
            onClick={(e) => {
              if (props.isPublicView) {
                setPublicDialogOpen(true);
                e.preventDefault();
              }
            }}
            to={"/library/experts"}
            className="sidebar-link"
            activeProps={{ className: "sidebar-link-active" }}
          >
            Expert Volumes
          </Link>

          <div className={"w-full px-2 mt-4"}>
            <div
              className={
                "w-full min-h-46 flex flex-col items-center justify-center text-white text-center bg-brandgrad bg-cover bg-center rounded-lg space-y-6 pt-3"
              }
            >
              <p className={"font-medium leading-tight text-smoke px-6"}>
                Join The Community Today & Chat Creative Strategy
              </p>
              <a
                href="https://join.slack.com/t/creativeos/shared_invite/zt-24n0h6fh0-JslQdJaA6HNVdeyG~0lJHw"
                target="_blank"
                className={
                  "flex w-fit items-center bg-white text-linkgray text-sm rounded-xl space-x-2 px-2 pr-4 hover:shadow-lg active:shadow-inner transition-shadow"
                }
              >
                <img className={"w-10 h-10"} src={"/slack-icon.svg"} />
                Join The Slack
              </a>
            </div>
          </div>
        </div>
        <div className={"border-t border-subtlegray py-3"}>
          <a
            href="https://www.creativeos.io/#faq"
            className={"sidebar-link flex items-center space-x-3 py-2"}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.978 19.5V17.0789C16.7339 17.0789 17.4729 16.8567 18.1014 16.4403C18.7299 16.0239 19.2198 15.4321 19.5091 14.7396C19.7984 14.0472 19.8741 13.2853 19.7266 12.5502C19.5791 11.8151 19.2151 11.1399 18.6806 10.6099C18.1461 10.0799 17.465 9.71903 16.7236 9.57282C15.9822 9.4266 15.2137 9.50164 14.5153 9.78846C13.8169 10.0753 13.22 10.561 12.8 11.1842"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 24C16.5523 24 17 23.5523 17 23C17 22.4477 16.5523 22 16 22C15.4477 22 15 22.4477 15 23C15 23.5523 15.4477 24 16 24Z"
                fill="black"
                stroke="black"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p>Help & Feedback</p>
          </a>

          <a
            href="https://www.creativeos.io/tutorials"
            className={"sidebar-link flex items-center space-x-3 py-2"}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.728 9.68602L14.314 8.27202L5 17.586V19H6.414L15.728 9.68602ZM17.142 8.27202L18.556 6.85802L17.142 5.44402L15.728 6.85802L17.142 8.27202ZM7.242 21H3V16.757L16.435 3.32202C16.6225 3.13455 16.8768 3.02924 17.142 3.02924C17.4072 3.02924 17.6615 3.13455 17.849 3.32202L20.678 6.15102C20.8655 6.33855 20.9708 6.59286 20.9708 6.85802C20.9708 7.12319 20.8655 7.37749 20.678 7.56502L7.243 21H7.242Z"></path>
            </svg>
            <p>Watch Tutorials</p>
          </a>
        </div>
      </div>
    </>
  );
};
