import { createFileRoute } from "@tanstack/react-router";
import { Tags } from "../../../shared/airtableGet.ts";
import { z } from "zod";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { DiscoverGridView } from "@/components/templates/DiscoverGridView";
import { Text } from "@/components/custom-components";
import { Divider } from "@/components/custom-components/Divider";
import { SidebarButton } from "@/components/templates/Sidebar";

type SearchParams = {
  Tags?: z.infer<typeof Tags>;
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/inspiration/$adID")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as z.infer<typeof Tags> | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      Tags: tag,
      sideBarOpen,
    };
  },
});

function All() {
  const { adID } = Route.useParams();
  const { sideBarOpen } = Route.useSearch();

  const { data, isError, isLoading, isRefetching } = trpc.getAd.useQuery(
    {
      adId: parseInt(adID),
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!data || isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={"px-5 md:px-5"}>
        <div className={"sticky top-0 z-10"}>
          <div className={"bg-white pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Ad Inspiration
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
          </div>
        </div>
        <DiscoverGridView data={[data]} />
      </div>
    </>
  );
}
