import { createFileRoute } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import { Ad, DiscoverGridView } from "@/components/templates/DiscoverGridView";
import { ErrorDisplay } from "@/components/error.tsx";
import { Button, Text } from "@/components/custom-components";
import { Loader } from "@/components/custom-components/Loader";
import { useInView } from "react-intersection-observer";
import { cloneElement, useEffect, useState } from "react";
import { Divider } from "@/components/custom-components/Divider";
import { SidebarButton } from "@/components/templates/Sidebar";
import AdIcon from "@/assets/AdIcon.tsx";
import LanderIcon from "@/assets/LanderIcon.tsx";
import { Link } from "@tanstack/react-router";
import { toast } from "sonner";
import { ShareIcon } from "lucide-react";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/brands/$brandID/")({
  component: BrandAds,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function BrandAds() {
  const { sideBarOpen } = Route.useSearch();
  const [allData, setAllData] = useState<Ad[] | undefined>(undefined);
  const { brandID } = Route.useParams();

  const [, copyToClipboard] = useCopyToClipboard();

  const { data, fetchNextPage, isLoading, isRefetching, error } =
    trpc.getBrandAds.useInfiniteQuery(
      {
        brandId: brandID,
      },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );

  // Ref for trigger to fetch next page
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(
    function fetchNextPageWhenElemInView() {
      if (!inView || !data) {
        return;
      }
      if (!allData) {
        return;
      }
      if (!allData.length) {
        return;
      }
      // We've fetched all the data if these are equal
      if (data.pages[data.pages.length - 1].ads.length === 0) {
        return;
      }
      fetchNextPage();
    },

    [inView, data, allData, fetchNextPage],
  );

  // Syncs react state with trpc state
  useEffect(() => {
    if (!data) return;

    setAllData(() => undefined);
    const records = [] as Ad[];
    for (const page of data.pages) {
      records.push(...page.ads);
    }
    setAllData(() => records);
  }, [data]);

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={"px-5 md:px-5"}>
        <div id={"app-header"} className={"bg-white sticky top-0 z-10 md:px-2"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                {data?.pages[0].brandName}
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
            <div className={"flex gap-5 items-center justify-between"}>
              <div className={"flex gap-5 items-center justify-between"}>
                <Link to={"/feeds/brands"}>
                  <Button className={"px-3 h-12 lg:h-auto"}>Back</Button>
                </Link>
                <div className={"flex gap-3 lg:gap-5 items-center"}>
                  <span className={"hidden lg:flex"}>Show:</span>
                  <div
                    className={
                      "flex gap-2 lg:gap-5 items-center overflow-x-auto w-[90vw] md:w-[70vw] lg:w-auto"
                    }
                  >
                    {[
                      {
                        name: "Ads",
                        link: `/feeds/brands/${brandID}`,
                        icon: <AdIcon />,
                      },
                      {
                        name: "Landers",
                        link: `/feeds/brands/${brandID}/landing-pages`,
                        icon: <LanderIcon />,
                      },
                    ].map((item) => {
                      const isActive = location.pathname === item.link;
                      const iconColor = isActive ? "white" : "black";
                      return (
                        <Link
                          to={item.link}
                          search={(d) => ({
                            ...d,
                            cacheBuster: Math.random(),
                            orderFilter: "Random",
                            sideBarOpen: false,
                          })}
                          className={
                            "flex gap-2.5 justify-start items-center h-12 lg:h-10 px-3 lg:px-5 py-4 border border-black rounded-md text-[0.875rem] font-normal"
                          }
                          activeProps={{
                            className:
                              "border-0 border-transparent rounded-lg bg-gradient-to-b from-[#A259FF] to-[#613599] text-white",
                          }}
                          activeOptions={{
                            exact: true,
                            includeSearch: false,
                          }}
                          key={item.name}
                        >
                          <span>
                            <span>
                              {cloneElement(item.icon, { color: iconColor })}
                            </span>
                          </span>
                          <Text
                            className={
                              "w-auto text-nowrap text-sm md:text-base"
                            }
                            weight={"medium"}
                            data-testid={item.name}
                          >
                            {item.name}
                          </Text>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className={"flex gap-2 items-center"}>
                <Button
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/feeds/brands/${brandID}`,
                    );
                    toast("Copied!");
                  }}
                  className={"hidden md:flex px-2.5 h-12 lg:h-auto"}
                >
                  <span>Share</span>
                </Button>
                <span
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/feeds/brands/${brandID}`,
                    );
                    toast("Copied!");
                  }}
                  className={
                    "flex md:hidden border rounded-md border-black p-2.5 cursor-pointer"
                  }
                >
                  <ShareIcon className={"w-[20px] h-[20px]"} />
                </span>
              </div>
            </div>

            <Divider className={"mt-[1.25rem] hidden md:flex"} />
          </div>
        </div>

        <div className={"px-0 md:px-0"}>
          {data && (
            <>
              {data.pages[0].ads.length === 0 ? (
                <div className={"lg:h-96 flex justify-center items-center"}>
                  <p>No ad from this brand</p>
                </div>
              ) : (
                data.pages[0].ads.length > 0 && (
                  <DiscoverGridView
                    isRefetching={isRefetching || isLoading}
                    data={allData as Ad[]}
                    scrollRef={scrollRef}
                  />
                )
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
