import { ComponentProps, forwardRef } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils.ts";

const dividerStyles = cva(
  "w-full border-b border-[#A259FF40] mt-[0.188rem] mb-[1.25rem]",
);

type CounterProps = ComponentProps<"div"> & VariantProps<typeof dividerStyles>;

export const Divider = forwardRef<HTMLDivElement, CounterProps>(
  ({ className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn(dividerStyles({ className }))} {...props} />
    );
  },
);
