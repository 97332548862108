import { Link, useRouterState } from "@tanstack/react-router";
import { useState } from "react";
import { trpc } from "@/utils/trpc.ts";
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@/components/custom-components";
import { IconButton } from "@/components/custom-components/IconButton";
import { PlusIcon } from "@radix-ui/react-icons";
import DotsExpandIcon from "@/assets/DotsExpandIcon.tsx";
import {
  BoardDialog,
  BoardProp,
  DeleteBoardDialog,
} from "@/components/templates/Sidebar";

export default function SidebarBoardList() {
  const router = useRouterState();

  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);
  const [editBoardDialogOpen, setEditBoardDialogOpen] =
    useState<boolean>(false);
  const [deleteBoardDialogOpen, setDeleteBoardDialogOpen] =
    useState<boolean>(false);
  const [selectedBoard, setSelectedBoard] = useState<BoardProp | null>(null);
  const { data: allBoardsData, isLoading: boardsIsLoading } =
    trpc.getBoards.useQuery(
      {},
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    );

  if (boardsIsLoading) {
    return <p>Loading boards</p>;
  }

  return (
    <>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
        />
      )}

      {editBoardDialogOpen && selectedBoard && (
        <BoardDialog
          data={selectedBoard}
          open={editBoardDialogOpen}
          onOpenChange={() => setEditBoardDialogOpen(false)}
        />
      )}

      {deleteBoardDialogOpen && selectedBoard && (
        <DeleteBoardDialog
          data={selectedBoard}
          open={deleteBoardDialogOpen}
          onOpenChange={() => setDeleteBoardDialogOpen(false)}
        />
      )}
      <Stack className={"mt-[0.875rem] w-full -ml-5"}>
        <Stack
          className={
            "gap-[0.125rem] border-l mb-[0.875rem] border-[#A259FF] pl-2"
          }
        >
          <div className={"flex justify-between gap-2 items-center w-full "}>
            <Link
              to={"/feeds/saved/ads"}
              className={`${location.pathname.startsWith("/feeds/saved/ads") || location.pathname.startsWith("/feeds/saved/templates") ? "bg-black text-white hover:bg-black" : "bg-white hover:bg-[#90909033]"} rounded-md px-5 py-[0.3125rem]`}
            >
              <Text weight={"medium"} className={"text-nowrap"}>
                {"All saved ads"}
              </Text>
            </Link>
            <IconButton
              onClick={() => setCreateBoardDialogOpen(true)}
              icon={<PlusIcon className={"h-[1.5rem] w-[1.5rem] "} />}
            />
          </div>
          <div className={"max-h-24 overflow-y-auto"}>
            {allBoardsData &&
              allBoardsData.map((item) => (
                <div
                  key={item.id}
                  className={`flex justify-between gap-2 items-center w-full ${router.location.pathname.includes(item.id) ? "bg-black text-white hover:bg-black" : "text-black hover:bg-[#90909033]"} rounded-md pl-5 pr-2 py-[0.3125rem]`}
                >
                  <Link
                    to={"/feeds/boards/$boardID/ads"}
                    params={{ boardID: item.id }}
                    className={"w-[7rem] truncate"}
                  >
                    <Text className={"truncate"} weight={"medium"}>
                      {item.name}
                    </Text>
                  </Link>
                  <Popover>
                    <PopoverTrigger className={"px-0.5 py-1"}>
                      <DotsExpandIcon
                        color={
                          router.location.pathname.includes(item.id)
                            ? "#ffffff"
                            : "#000000"
                        }
                      />
                    </PopoverTrigger>
                    <PopoverContent
                      className={"w-[9.9375rem] p-1 rounded-none"}
                    >
                      <div className={"flex flex-col items-start gap-3"}>
                        <button
                          onClick={() => {
                            setSelectedBoard(item as BoardProp);
                            setEditBoardDialogOpen(true);
                          }}
                          className={
                            "text-left hover:bg-[#90909033] px-[0.625rem] py-[0.3125rem] rounded-sm w-full"
                          }
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => {
                            setSelectedBoard(item as BoardProp);
                            setDeleteBoardDialogOpen(true);
                          }}
                          className={
                            "text-left hover:bg-[#90909033] px-[0.625rem] py-[0.3125rem] rounded-sm w-full"
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              ))}
          </div>
        </Stack>
        <Box>
          <Link
            to={"/feeds/boards"}
            activeProps={{ className: "font-bold text-[#A259FF]" }}
          >
            <Text weight={"medium"}>View all boards</Text>
          </Link>
        </Box>
      </Stack>
    </>
  );
}
