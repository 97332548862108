import { createFileRoute } from "@tanstack/react-router";
import { AdsView } from "../components/AdsView.tsx";
import { Tags } from "../../../shared/airtableGet.ts";
import { z } from "zod";
import { TopBar } from "@/components/topBar.tsx";
import { orderFilter as OrderFilterTypes } from "../../../shared/airtableGet.ts";

type SearchParams = {
  Tags?: z.infer<typeof Tags>;
};

export const Route = createFileRoute("/library/static/$adID")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as z.infer<typeof Tags> | undefined;
    return {
      Tags: tag,
    };
  },
});

function All() {
  const { Tags, orderFilter } = Route.useSearch();
  const { adID } = Route.useParams();
  return (
    <>
      <div className={"sticky top-0 z-10 px-2"}>
        <div className={"bg-white px-8 pt-6 pb-2"}>
          <TopBar Title={"Static Ad Library"} />
        </div>
      </div>
      <AdsView
        Filter={{
          Expert: undefined,
          Tags: Tags ? [Tags] : undefined,
          Ready: true,
          cursor: undefined,
          limit: 20,
          sortingOptions: orderFilter as z.infer<typeof OrderFilterTypes>,
          adID: adID,
        }}
      />
    </>
  );
}
