import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Button } from "@/components/ui/button.tsx";
import { useAuth } from "@memberstack/react";
import { TopBar } from "@/components/topBar.tsx";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { trpc } from "../utils/trpc.ts";
import { memberstack } from "@/App.tsx";
import { UseInvalidateRouter } from "@/lib/utils.ts";
import { useState } from "react";

export const Route = createFileRoute("/library/manage-account/profile")({
  component: ManageAccount,
});

function ManageAccount() {
  const { data } = trpc.me.useQuery(null);

  const { signOut } = useAuth();

  const navigate = useNavigate();

  return (
    <div>
      <div className={"mx-4"}>
        <TopBar Title={"Static Ad Library"} />
        <hr />
      </div>
      <div className={"max-w-[1400px] mx-4"}>
        <div
          className={
            "bg-gray-100 rounded-lg py-4 px-2 flex justify-between mt-6"
          }
        >
          <div>
            <Button variant={"outline"}>
              <Link
                to={"/library/manage-account/profile"}
                activeProps={{
                  className: "text-blue-500",
                }}
              >
                Profile
              </Link>
            </Button>
            <Button className={"ml-4"} variant={"outline"}>
              <Link
                to={"/library/manage-account/billing"}
                activeProps={{
                  className: "text-blue-500",
                }}
              >
                Billing
              </Link>
            </Button>
          </div>
          <Button
            variant={"outline"}
            onClick={async () => {
              await signOut();
              await navigate({
                from: "/",
                to: "/login",
              });
            }}
            className="[&.active]:font-bold"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="currentColor"
            >
              <g>
                <path d="M0,0h24v24H0V0z" fill="none"></path>
              </g>
              <g>
                <g>
                  <path d="M5,5h6c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h6c0.55,0,1-0.45,1-1v0 c0-0.55-0.45-1-1-1H5V5z"></path>
                  <path d="M20.65,11.65l-2.79-2.79C17.54,8.54,17,8.76,17,9.21V11h-7c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h7v1.79 c0,0.45,0.54,0.67,0.85,0.35l2.79-2.79C20.84,12.16,20.84,11.84,20.65,11.65z"></path>
                </g>
              </g>
            </svg>
            Log Out
          </Button>
        </div>
        <div className={"flex justify-between mt-4"}>
          <div>
            <h2>Profile Information</h2>
            <p>Please make sure your information is up to date</p>
          </div>
        </div>
        <div className={"flex justify-between mt-4"}>
          <div>
            <p>Email</p>
          </div>
          <div>
            <ChangeEmail existingEmail={data?.data?.auth.email} />
          </div>
        </div>
        <div className={"flex justify-between mt-4"}>
          <div>
            <p>Password</p>
          </div>
          <div>
            <ChangePassword />
          </div>
        </div>
      </div>
    </div>
  );
}

const formSchema = z.object({
  email: z.string().email(),
});

type ChangeEmailProps = {
  existingEmail: string | undefined;
};

const ChangeEmail = (props: ChangeEmailProps) => {
  const [open, setOpen] = useState(false);
  const { invalidateRouter } = UseInvalidateRouter();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });
  // https://developers.memberstack.com/docs/dom-front-end-package#update-current-member-email
  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await memberstack.updateMemberAuth({
        email: values.email,
      });
      invalidateRouter();
      setOpen(false);
    } catch (e) {
      window.alert("Something went wrong");
      console.error(e);
    }
  }

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <div className={"flex flex-col items-end"}>
          <DialogTrigger className={"bg-gray-100 border-gray-200 px-4 py-2"}>
            Change Email
          </DialogTrigger>
          <p className={"text-sm pt-1"}>{props.existingEmail}</p>
        </div>
        <DialogContent>
          <p className={"text-xl pb-2"}>Change your email</p>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New Email</FormLabel>
                    <FormControl>
                      <Input type={"email"} placeholder="" {...field} />
                    </FormControl>
                    <FormDescription>
                      Any social login connections will be removed.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">Confirm Email</Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const passwordFormSchema = z.object({
  currentPassword: z.string().min(8),
  newPassword: z.string().min(8),
});

const ChangePassword = () => {
  const [open, setOpen] = useState(false);
  const { invalidateRouter } = UseInvalidateRouter();

  const form = useForm<z.infer<typeof passwordFormSchema>>({
    resolver: zodResolver(passwordFormSchema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
  });

  // https://developers.memberstack.com/docs/dom-front-end-package#update-current-member-password
  async function onSubmit(values: z.infer<typeof passwordFormSchema>) {
    try {
      await memberstack.updateMemberAuth({
        newPassword: values.newPassword,
        oldPassword: values.currentPassword,
      });
      invalidateRouter();
      setOpen(false);
    } catch (e) {
      window.alert("Something went wrong");
      console.error(e);
    }
  }

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <div className={"flex flex-col items-end"}>
          <DialogTrigger className={"bg-gray-100 border-gray-200 px-4 py-2"}>
            Change Password
          </DialogTrigger>
        </div>
        <DialogContent>
          <p className={"text-xl pb-2"}>Update Your Password</p>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="currentPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Existing Password</FormLabel>
                    <FormControl>
                      <Input type={"password"} placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="newPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New Password</FormLabel>
                    <FormControl>
                      <Input
                        type={"password"}
                        autoComplete={"new-password"}
                        placeholder=""
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>Must be 8 characters long</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">Update Password</Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
