import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Tags } from "../../../shared/airtableGet.ts";
import { z } from "zod";
import { Button, Text } from "@/components/custom-components";
import { DiscoverGridViewPublic } from "@/components/templates/DiscoverGridView";
import { Divider } from "@/components/custom-components/Divider";

type SearchParams = {
  Tags?: z.infer<typeof Tags>;
};

export const Route = createFileRoute("/share/inspiration/$adID")({
  component: SharedAd,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as z.infer<typeof Tags> | undefined;
    return {
      Tags: tag,
    };
  },
});

function SharedAd() {
  const navigate = useNavigate({ from: "/share/inspiration/$adID" });
  const { adID } = Route.useParams();
  const { cacheBuster } = Route.useSearch();
  if (!cacheBuster) {
    navigate({
      search: (old) => {
        return {
          ...old,
          cacheBuster: Math.random(),
        };
      },
      params: (old) => {
        return {
          ...old,
        };
      },
    });
  }
  return (
    <div className={"relative h-[97vh] px-5 md:px-5 md:py-0"}>
      <div className={"bg-white sticky top-0 z-10 md:px-2"}>
        <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-center"
            }
          >
            <Text
              size={"lg"}
              weight={"semibold"}
              className={
                "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
              }
            >
              Advert Preview
            </Text>
          </div>
          <Divider className={"my-[1.25rem] hidden md:flex"} />
        </div>
      </div>
      <div
        className={
          "fixed z-50 flex justify-center items-end left-0 right-0 bottom-[2.5rem]"
        }
      >
        <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
          <Button
            className={"font-medium py-4 px-[2.5rem]"}
            variant={"gradient"}
          >
            Sign up to see 250+ quality ads daily
          </Button>
        </a>
      </div>
      <DiscoverGridViewPublic adId={parseInt(adID)} />
    </div>
  );
}
