import { createFileRoute, Link, useRouterState } from "@tanstack/react-router";
import { Ad, DiscoverGridView } from "@/components/templates/DiscoverGridView";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { Text } from "@/components/custom-components";
import { Divider } from "@/components/custom-components/Divider";
import { SidebarButton } from "@/components/templates/Sidebar";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/saved/ads")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const { sideBarOpen } = Route.useSearch();
  const { data, isLoading, isError, isRefetching } =
    trpc.getAllSavedAds.useQuery(undefined, {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    });

  const router = useRouterState();

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!data || isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={"px-5 md:px-5 md:py-0"}>
        <div className={"bg-white sticky top-0 z-10 md:px-2"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Saved Ads
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
            <div className={"flex justify-center"}>
              <div
                className={
                  "flex justify-start lg:justify-center overflow-x-auto"
                }
              >
                <div
                  className={"flex lg:grid grid-cols-3 bg-[#D9D9D9] rounded-lg"}
                >
                  {[
                    { name: "Saved Ads", id: "ads", path: "/feeds/saved/ads" },
                    {
                      name: "Saved Ad Landers",
                      id: "ad-landers",
                      path: "/feeds/saved/ad-landers",
                    },
                    {
                      name: "Templates",
                      id: "templates",
                      path: "/feeds/saved/templates",
                    },
                  ].map((item) => (
                    <Link
                      to={item.path}
                      key={item.id}
                      className={`${item.path === router.location.pathname ? "bg-black text-white" : "bg-transparent text-black"} text-sm lg:text-base text-nowrap block text-center rounded-lg lg:min-w-[8.938rem] px-3 lg:px-5 py-2 lg:py-[0.875rem]`}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={""}>
          {data.length === 0 ? (
            <div className={"h-[80vh] flex justify-center items-center"}>
              <p>You do not have any ad inspiration saved</p>
            </div>
          ) : (
            <DiscoverGridView data={data as Ad[]} />
          )}
        </div>
      </div>
    </>
  );
}
