export const ErrorDisplay = () => {
  return (
    <div className={"border-red-100 p-6 border-2 rounded-lg"}>
      <p>Sorry! Something went wrong (Info: `1710193472`)</p>
      <p className={"mt-4"}>
        You can either{" "}
        <button className={"underline text-blue-700"}>refresh the page</button>
      </p>
      <p className={"mt-4"}>Or email support: chase@creativeos.io</p>
    </div>
  );
};
