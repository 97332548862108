import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Ad, DiscoverGridView } from "@/components/templates/DiscoverGridView";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { cloneElement, useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  AccordionData,
  AdFilter,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  // PlanTag,
  Text,
} from "@/components/custom-components";
import {
  FormatFilter,
  PlatformFilter,
  ScoreFilters,
  StatusFilters,
  TimeLiveFilters,
} from "../../../server/types";
import { otherFilterOptions } from "@/utils/data/filters.ts";
import { Divider } from "@/components/custom-components/Divider";
import { SidebarButton } from "@/components/templates/Sidebar";
import AdIcon from "@/assets/AdIcon.tsx";
// import EmailIcon from "@/assets/EmailIcon.tsx";
import LanderIcon from "@/assets/LanderIcon.tsx";
import { z } from "zod";
import { orderFilter as OrderFilterTypes } from "../../../shared/airtableGet.ts";
// import { LockClosedIcon } from "@radix-ui/react-icons";

type SearchParams = {
  categories?: string;
  platforms?: string;
  formats?: string;
  timeLive?: string;
  scores?: string;
  statuses?: string;
  sideBarOpen?: boolean;
};

type FilterOption = {
  title: string;
  counter: number;
  optionItems: { label: string; value: boolean }[];
};

export type SelectedFilters = {
  categories?: string[];
  platforms?: PlatformFilter[];
  formats?: FormatFilter[];
  timeLive?: TimeLiveFilters[];
  scores?: ScoreFilters[];
  statuses?: StatusFilters[];
};

export const Route = createFileRoute("/feeds/inspiration/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const categories = search?.categories as string | undefined;
    const platforms = search?.platforms as string | undefined;
    const formats = search?.formats as string | undefined;
    const timeLive = search?.timeLive as string | undefined;
    const scores = search?.scores as string | undefined;
    const statuses = search?.statuses as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      categories,
      platforms,
      formats,
      timeLive,
      scores,
      statuses,
      sideBarOpen,
    };
  },
});

function All() {
  const navigate = useNavigate();

  const {
    categories: queryCategories,
    platforms: queryPlatforms,
    formats: queryFormats,
    timeLive: queryTimeLive,
    scores: queryScore,
    statuses: queryStatus,
    sideBarOpen,
    orderFilter,
  } = Route.useSearch();

  const [allData, setAllData] = useState<Ad[] | undefined>(undefined);
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [cursor, setCursor] = useState(1);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    categories: queryCategories ? queryCategories.split(",") : undefined,
    platforms: queryPlatforms
      ? (queryPlatforms.split(",") as PlatformFilter[])
      : undefined,
    formats: queryFormats
      ? (queryFormats.split(",") as FormatFilter[])
      : undefined,
    timeLive: queryTimeLive
      ? (queryTimeLive.split(",") as TimeLiveFilters[])
      : undefined,
    scores: queryScore ? (queryScore.split(",") as ScoreFilters[]) : undefined,
    statuses: queryStatus
      ? (queryStatus.split(",") as StatusFilters[])
      : undefined,
  });

  // Get the page categories to be passed to the filter
  const { data: pageCategories } = trpc.getPageCategories.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (pageCategories) {
      setFilterOptions([
        {
          title: "Categories",
          counter: 0,
          optionItems: pageCategories.map((i) => ({
            label: i.name,
            value: false,
          })),
        },
        ...otherFilterOptions,
      ]);
    }
  }, [pageCategories]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => {
      const updatedOptionItems = option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === "Categories" &&
            selectedFilters.categories?.includes(item.label)) ||
          (option.title === "Platform" &&
            selectedFilters.platforms?.includes(
              item.label as PlatformFilter,
            )) ||
          (option.title === "Format" &&
            selectedFilters.formats?.includes(item.label as FormatFilter)) ||
          (option.title === "Time Live" &&
            selectedFilters.timeLive?.includes(
              item.label as TimeLiveFilters,
            )) ||
          (option.title === "Score" &&
            selectedFilters.scores?.includes(item.label as ScoreFilters)) ||
          (option.title === "Status" &&
            selectedFilters.statuses?.includes(item.label as StatusFilters)),
      }));

      return {
        ...option,
        optionItems: updatedOptionItems,
      };
    });

    setFilterOptions(updatedOptions as FilterOption[]);
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: {
      categories?: string;
      platforms?: string;
      formats?: string;
      timeLive?: string;
      scores?: string;
      statuses?: string;
    }) => {
      const searchParams = new URLSearchParams();

      if (params.categories) searchParams.set("categories", params.categories);
      if (params.platforms) searchParams.set("platforms", params.platforms);
      if (params.formats) searchParams.set("formats", params.formats);
      if (params.timeLive) searchParams.set("timeLive", params.timeLive);
      if (params.scores) searchParams.set("scores", params.scores);
      if (params.statuses) searchParams.set("statuses", params.statuses);

      navigate({
        to: "/feeds/inspiration",
        replace: true,
        search: (old) => {
          return { ...old, ...params };
        },
      });
    },
    [navigate],
  );

  const handleOptionsChange = useCallback(
    (options: AccordionData[]) => {
      const selectedCategories: string[] = [];
      const selectedPlatforms: PlatformFilter[] = [];
      const selectedFormats: FormatFilter[] = [];
      const selectedTimeLive: TimeLiveFilters[] = [];
      const selectedScore: ScoreFilters[] = [];
      const selectedStatus: StatusFilters[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Categories")
              selectedCategories.push(item.label);
            if (group.title === "Platform")
              selectedPlatforms.push(item.label as PlatformFilter);
            if (group.title === "Format")
              selectedFormats.push(item.label as FormatFilter);
            if (group.title === "Time Live")
              selectedTimeLive.push(item.label as TimeLiveFilters);
            if (group.title === "Score")
              selectedScore.push(item.label as ScoreFilters);
            if (group.title === "Status")
              selectedStatus.push(item.label as StatusFilters);
          }
        });
      });

      setSelectedFilters({
        categories:
          selectedCategories.length > 0 ? selectedCategories : undefined,
        platforms: selectedPlatforms.length > 0 ? selectedPlatforms : undefined,
        formats: selectedFormats.length > 0 ? selectedFormats : undefined,
        timeLive: selectedTimeLive.length > 0 ? selectedTimeLive : undefined,
        scores: selectedScore.length > 0 ? selectedScore : undefined,
        statuses: selectedStatus.length > 0 ? selectedStatus : undefined,
      });

      updateQueryString({
        categories:
          selectedCategories.length > 0
            ? selectedCategories.join(",")
            : undefined,
        platforms:
          selectedPlatforms.length > 0
            ? selectedPlatforms.join(",")
            : undefined,
        formats:
          selectedFormats.length > 0 ? selectedFormats.join(",") : undefined,
        timeLive:
          selectedTimeLive.length > 0 ? selectedTimeLive.join(",") : undefined,
        scores: selectedScore.length > 0 ? selectedScore.join(",") : undefined,
        statuses:
          selectedStatus.length > 0 ? selectedStatus.join(",") : undefined,
      });
    },
    [updateQueryString],
  );

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.categories)
      params.categories = selectedFilters.categories.join(",");
    if (selectedFilters.platforms)
      params.platforms = selectedFilters.platforms.join(",");
    if (selectedFilters.formats)
      params.formats = selectedFilters.formats.join(",");
    if (selectedFilters.timeLive)
      params.timeLive = selectedFilters.timeLive.join(",");
    if (selectedFilters.scores)
      params.scores = selectedFilters.scores.join(",");
    if (selectedFilters.statuses)
      params.statuses = selectedFilters.statuses.join(",");
  }, [selectedFilters]);

  // check for if there is at least one option selected
  const isAnyFilterSelected = (filters: SelectedFilters) => {
    return (
      (filters.categories && filters.categories.length > 0) ||
      (filters.platforms && filters.platforms.length > 0) ||
      (filters.formats && filters.formats.length > 0) ||
      (filters.timeLive && filters.timeLive.length > 0) ||
      (filters.scores && filters.scores.length > 0) ||
      (filters.statuses && filters.statuses.length > 0)
    );
  };

  // update cursor whenever selectedFilters change
  useEffect(() => {
    setCursor(1); // Reset cursor to 1 whenever filters change
  }, [selectedFilters]);

  const { data, fetchNextPage, isLoading, isError, isRefetching } =
    isAnyFilterSelected(selectedFilters)
      ? trpc.filterAdFeed.useInfiniteQuery(
          { ...selectedFilters },
          {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            initialCursor: cursor,
          },
        )
      : trpc.homeFeed.useInfiniteQuery(
          { order: orderFilter as z.infer<typeof OrderFilterTypes> },
          {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            initialCursor: cursor,
          },
        );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (!inView || !data || !allData || !allData.length) return;
    if (data.pages[data.pages.length - 1].ads.length === 0) {
      return;
    }
    fetchNextPage();
  }, [inView, data, allData, fetchNextPage]);

  useEffect(() => {
    if (!data) return;

    setAllData(() => undefined);
    const records = [] as Ad[];
    for (const page of data.pages) {
      records.push(...page.ads);
    }
    setAllData(() => records);
  }, [data]);

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }
  return (
    <>
      <div className={""}>
        <div className={"bg-white sticky px-5 md:px-5 lg:pb-2 top-0 z-10"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Inspiration Library
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />

            <div className={"flex gap-5 items-center justify-between"}>
              <div className={"flex gap-3 lg:gap-5 items-center"}>
                <span className={"hidden lg:flex"}>Show:</span>
                <div
                  className={
                    "flex gap-2 lg:gap-5 items-center overflow-x-auto w-[90vw] lg:w-auto"
                  }
                >
                  {[
                    {
                      name: "Ads",
                      link: "/feeds/inspiration",
                      icon: <AdIcon />,
                    },
                    // {
                    //   name: "Emails",
                    //   link: "/feeds/inspiration/emails",
                    //   icon: <EmailIcon />,
                    // },
                    {
                      name: "Landers",
                      link: "/feeds/inspiration/landing-pages",
                      icon: <LanderIcon />,
                    },
                  ].map((item) => {
                    const isActive = location.pathname === item.link;
                    const iconColor = isActive ? "white" : "black";
                    return (
                      <Link
                        to={item.link}
                        search={(d) => ({
                          ...d,
                          cacheBuster: Math.random(),
                          orderFilter: "Random",
                          sideBarOpen: false,
                        })}
                        className="flex gap-2.5 justify-start items-center h-12 lg:h-10 px-3 lg:px-5 py-4 border border-black rounded-md text-[0.875rem] font-normal"
                        activeProps={{
                          className:
                            "border-0 border-transparent rounded-lg bg-gradient-to-b from-[#A259FF] to-[#613599] text-white",
                        }}
                        activeOptions={{
                          exact: true,
                          includeSearch: false,
                        }}
                        key={item.name}
                      >
                        <span>
                          <span>
                            {cloneElement(item.icon, { color: iconColor })}
                          </span>
                        </span>
                        <Text
                          className={"w-auto text-nowrap text-sm md:text-base"}
                          weight={"medium"}
                          data-testid={item.name}
                        >
                          {item.name}
                        </Text>
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className={"hidden lg:flex"}>
                <Select
                  onValueChange={(value) => {
                    navigate({
                      search: (old) => {
                        return {
                          ...old,
                          cacheBuster:
                            value === "Random" ? Math.random() : undefined,
                          orderFilter: value as z.infer<
                            typeof OrderFilterTypes
                          >,
                        };
                      },
                      params: (old) => {
                        return {
                          ...old,
                        };
                      },
                    });
                  }}
                >
                  <SelectTrigger className="w-32 h-10">
                    <SelectValue placeholder={orderFilter || "Random"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Random">Random</SelectItem>
                    <SelectItem value="Recent">Most Recent</SelectItem>
                    <SelectItem value="Popular">Popular</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className={"flex justify-between gap-3 mt-2.5"}>
              {filterOptions && filterOptions.length > 0 && (
                <div className="">
                  <AdFilter
                    initialOptions={filterOptions}
                    onOptionsChange={handleOptionsChange}
                    placeholder={"Filter"}
                  />
                </div>
              )}
              <div className={"flex lg:hidden"}>
                <Select
                  onValueChange={(value) => {
                    navigate({
                      search: (old) => {
                        return {
                          ...old,
                          cacheBuster:
                            value === "Random" ? Math.random() : undefined,
                          orderFilter: value as z.infer<
                            typeof OrderFilterTypes
                          >,
                        };
                      },
                      params: (old) => {
                        return {
                          ...old,
                        };
                      },
                    });
                  }}
                >
                  <SelectTrigger className="w-32 h-12">
                    <SelectValue placeholder={orderFilter || "Random"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Random">Random</SelectItem>
                    <SelectItem value="Recent">Most Recent</SelectItem>
                    <SelectItem value="Popular">Popular</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="px-5">
          {isLoading || isRefetching ? (
            <div className="flex justify-center items-center w-full h-screen">
              <Loader />
            </div>
          ) : (
            <div>
              {data && allData && allData.length === 0 ? (
                <div className="flex flex-col justify-center items-center">
                  <p className="text-center w-4/5 lg:w-1/2 mb-5">
                    Looks like you've gone down a path with no
                    inspiration...this is your fault! All we do is win...but
                    really, maybe try a different configuration of filters - we
                    got you!
                  </p>
                  <img src="/giphy.webp" width="480" height="270" alt="" />
                </div>
              ) : (
                data &&
                allData &&
                allData.length > 0 && (
                  <DiscoverGridView data={allData} scrollRef={scrollRef} />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default All;
