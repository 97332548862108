import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { z } from "zod";
import {
  orderFilter as OrderFilterTypes,
  Tags,
} from "../../../shared/airtableGet.ts";

import { GenericCardProps } from "@/components/GenericGridView.tsx";
import { trpc } from "@/utils/trpc.ts";
import { cloneElement, useEffect, useMemo, useState } from "react";
import { ErrorDisplay } from "@/components/error.tsx";
import { LandingAdGridView } from "@/components/templates/LandingAdGridView";
import { Loader } from "@/components/custom-components/Loader";
import {
  PlanTag,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
} from "@/components/custom-components";
import { Divider } from "@/components/custom-components/Divider";
import { Link } from "@tanstack/react-router";
import AdIcon from "@/assets/AdIcon.tsx";
import EmailIcon from "@/assets/EmailIcon.tsx";
import LanderIcon from "@/assets/LanderIcon.tsx";
import FullAccessPromptDialog from "@/components/FullAccessPromptDialog.tsx";
import { LockClosedIcon } from "@radix-ui/react-icons";
import { SidebarButton } from "@/components/templates/Sidebar";

type SearchParams = {
  Tags?: z.infer<typeof Tags>;
  cacheBuster: number;
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/templates/emails/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const cacheBuster = (search?.cacheBuster as number) ?? Math.random();
    const sideBarOpen = search?.sideBarOpen as boolean;
    return {
      cacheBuster: cacheBuster,
      sideBarOpen,
    };
  },
});

function All() {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const { cacheBuster, sideBarOpen, orderFilter } = Route.useSearch();
  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const { data, isLoading, error } = trpc.emails.useQuery(
    {
      cacheBuster: cacheBuster,
      sortingOptions: orderFilter as z.infer<typeof OrderFilterTypes>,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const formatData = useMemo((): GenericCardProps[] => {
    if (!data) return [];
    const returnData = [] as GenericCardProps[];
    data.Emails?.map((datum) => {
      returnData.push({
        Screenshot: datum["Email Screenshot"],
        ID: datum["Email ID"],
        type: "email",
      });
    });
    return returnData;
  }, [data]);

  useEffect(() => {
    if (!isLoadingPermission && !permissionData?.userCanAccessEverything) {
      setUpgradeDialogOpen(true);
    }
  }, [permissionData?.userCanAccessEverything, isLoadingPermission]);

  return (
    <>
      {upgradeDialogOpen && (
        <FullAccessPromptDialog
          upgradeDialogOpen={upgradeDialogOpen}
          setUpgradeDialogOpen={() => setUpgradeDialogOpen(false)}
        />
      )}
      <div className={""}>
        <div className={"bg-white sticky px-5 md:px-5 pb-2 top-0 z-10"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                Email Templates
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
          </div>
          <div className={"flex gap-5 items-center justify-between"}>
            <div className={"flex gap-5 items-center"}>
              <span className={"hidden lg:flex"}>Show:</span>
              <div
                className={
                  "flex gap-2 lg:gap-5 items-center overflow-x-auto w-[90vw] lg:w-auto"
                }
              >
                {[
                  {
                    name: "Ad Templates",
                    link: "/feeds/templates/",
                    icon: <AdIcon />,
                  },
                  {
                    name: "Emails",
                    link: "/feeds/templates/emails",
                    icon: <EmailIcon />,
                  },
                  {
                    name: "Landers",
                    link: "/feeds/templates/landing-pages",
                    icon: <LanderIcon />,
                  },
                ].map((item) => {
                  const isActive = location.pathname === item.link;
                  const iconColor = isActive ? "white" : "black";
                  return (
                    <Link
                      onClick={(e) => {
                        // If a user navigates before loading is complete, choose to just navigate
                        // This optimizes for users who ARE allowed access and doesn't force them to
                        // wait until we have the data back from the server if they have access

                        if (
                          (item.name === "Emails" || item.name === "Landers") &&
                          !isLoadingPermission &&
                          !permissionData?.userCanAccessEverything
                        ) {
                          setUpgradeDialogOpen(true);
                          e.preventDefault();
                        }
                      }}
                      to={item.link}
                      search={(d) => ({
                        ...d,
                        cacheBuster: Math.random(),
                        orderFilter: "Random",
                        sideBarOpen: false,
                      })}
                      className="flex gap-2.5 justify-start items-center h-12 lg:h-10 px-3 lg:px-5 py-4 border border-black rounded-md text-[0.875rem] font-normal"
                      activeProps={{
                        className:
                          "border-0 border-transparent rounded-lg bg-gradient-to-b from-[#A259FF] to-[#613599] text-white",
                      }}
                      activeOptions={{
                        exact: true,
                        includeSearch: false,
                      }}
                      key={item.name}
                    >
                      <span>
                        <span>
                          {cloneElement(item.icon, { color: iconColor })}
                        </span>
                      </span>
                      <Text
                        className={"w-auto text-nowrap text-sm md:text-base"}
                        weight={"medium"}
                        data-testid={item.name}
                      >
                        {item.name}
                      </Text>
                      {(item.name === "Emails" || item.name === "Landers") && (
                        <>
                          {!isLoadingPermission &&
                            !permissionData?.userCanAccessEverything && (
                              <PlanTag label={<LockClosedIcon />} />
                            )}
                        </>
                      )}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className={"hidden lg:flex"}>
              <Select
                onValueChange={(value) => {
                  navigate({
                    search: (old) => {
                      return {
                        ...old,
                        cacheBuster:
                          value === "Random" ? Math.random() : undefined,
                        orderFilter: value as z.infer<typeof OrderFilterTypes>,
                      };
                    },
                    params: (old) => {
                      return {
                        ...old,
                      };
                    },
                  });
                }}
              >
                <SelectTrigger className="w-32 h-10">
                  <SelectValue placeholder={orderFilter || "Recent"} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Recent">Most Recent</SelectItem>
                  <SelectItem value="Popular">Popular</SelectItem>
                  <SelectItem value="Oldest">Oldest</SelectItem>
                  <SelectItem value="Random">Random</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className={"flex justify-end gap-3 mt-2.5"}>
            <div className={"flex lg:hidden"}>
              <Select
                onValueChange={(value) => {
                  navigate({
                    search: (old) => {
                      return {
                        ...old,
                        cacheBuster:
                          value === "Random" ? Math.random() : undefined,
                        orderFilter: value as z.infer<typeof OrderFilterTypes>,
                      };
                    },
                    params: (old) => {
                      return {
                        ...old,
                      };
                    },
                  });
                }}
              >
                <SelectTrigger className="w-32 h-12">
                  <SelectValue placeholder={orderFilter || "Recent"} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Recent">Most Recent</SelectItem>
                  <SelectItem value="Popular">Popular</SelectItem>
                  <SelectItem value="Oldest">Oldest</SelectItem>
                  <SelectItem value="Random">Random</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div
            className={
              "flex justify-center items-center w-full h-screen m-auto"
            }
          >
            <Loader />
          </div>
        ) : error ? (
          <ErrorDisplay />
        ) : (
          <LandingAdGridView
            Cards={formatData}
            HasAccess={data ? data.HasAccess : false}
            type={"email"}
          />
        )}
      </div>
    </>
  );
}
