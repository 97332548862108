import { Link } from "@tanstack/react-router";
import { z } from "zod";
import { Tags as TagType } from "../../../shared/airtableGet.ts";

type props = {
  Tags: z.infer<typeof TagType> | undefined;
  DestinationURL: string;
};

export const TagFilters = (props: props) => {
  const Options = [
    "New",
    "Testimonial",
    "Collage",
    "Social",
    "Benefits",
    "Media & PR",
    "Comparison",
    "Holiday",
  ] as z.infer<typeof TagType>[];
  return (
    <>
      <Link
        className={`
                  ${props.Tags === undefined ? "bg-gray-100 text-black" : "text-darkgray"}
                  inline-block hover:text-black transition-colors duration-200 px-4 py-2 rounded-full`}
        activeOptions={{
          exact: true,
          includeSearch: true,
        }}
        to={props.DestinationURL}
        replace={false}
      >
        All
      </Link>
      {Options.map((x) => {
        return (
          <Link
            key={x}
            className={`
                      ${props.Tags == x ? "bg-gray-100 text-black" : "text-darkgray"}
                      inline-block hover:text-black transition-colors duration-200 px-4 py-2 rounded-full`}
            activeOptions={{
              exact: false,
              includeSearch: true,
            }}
            to={props.DestinationURL}
            params={(old) => {
              return {
                ...old,
              };
            }}
            search={(old) => ({
              ...old,
              Tags: x,
            })}
          >
            {x}
          </Link>
        );
      })}
    </>
  );
};
